<template>
    <ConnectToViewMore :message="t('profile.section.portfolio.hidden')">
        <MainComponent v-if="isVisible && isPortfolioSectionVisible" data-testid="portfolio-item-porfolio">
            <template #title>
                {{ t('profile.section.book.title') }}
            </template>

            <template #title-action>
                <div v-if="isEditModeEnabled">
                    <VJoyButton
                        v-if="isEditModeEnabled"
                        href="/profile/portfolio"
                        data-testid="portfolio-item-porfolio-edition-link"
                        variant="ghost"
                        circle
                        icon="pen"
                        size="small"
                    />
                </div>
            </template>

            <template #content>
                <div class="portfolio__galleria-wrapper" :class="{'portfolio__galleria-wrapper-nothing': !hasItemsToDisplay}">
                    <template v-if="isAllowedToSeeContent()">
                        <PortfolioGalleria :portfolio-items="portfolio?.items || []" :is-edit-mode-enabled="isEditModeEnabled" />
                    </template>
                    <template v-else>
                        <div v-if="getFirstPortfolioItem" class="portfolio_fallback">
                            <MaltImg
                                :src="getFirstPortfolioItem.portfolioImageMaxImageUrl"
                                :title="getFirstPortfolioItem.title"
                                width="650"
                                height="650"
                                fit="inside"
                                class="portfolio__item image"
                                densities="1x"
                                loading="lazy"
                                alt=""
                            />
                        </div>
                    </template>
                </div>
            </template>
        </MainComponent>
    </ConnectToViewMore>
</template>
<script setup lang="ts">
    import {MaltImg} from '#components';
    import {computed, useTranslation} from '#imports';

    import {storeToRefs} from 'pinia';
    import ConnectToViewMore from '~/components/_common/ConnectToViewMore.vue';
    import PortfolioGalleria from './portfolio/PortfolioGalleria.vue';
    import {useSectionsVisibility} from '~/composables/useSectionsVisibility';
    import {useProfileStore} from '~/store/profileStore';
    import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';
    import {useConnectToViewMoreStore} from '~~/src/store/connectToViewMoreStore';
    import MainComponent from './common/MainComponent.vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import {useFeatureContext} from '~/store/featureStore';

    const {t} = useTranslation();
    const {features} = storeToRefs(useFeatureContext());
    const {isAllowedToSeeContent} = useConnectToViewMoreStore();
    const profileStore = useProfileStore();
    const {portfolio, profile} = storeToRefs(profileStore);

    const isPortfolioSectionVisible = computed(() => useSectionsVisibility(SectionsVisibilityType.PORTFOLIO));

    const hasItemsToDisplay = computed(() => portfolio?.value?.items && portfolio.value.items.length > 0);
    const getFirstPortfolioItem = computed(() => (hasItemsToDisplay.value ? portfolio.value!.items[0] : undefined));
    const isEditModeEnabled = computed(() => profile?.value?.editMode || false);
    const isVisible = computed(() => hasItemsToDisplay.value || isEditModeEnabled);
</script>
<style scoped lang="scss">
    .portfolio {
        &__galleria-wrapper,
        &__fallback {
            height: 375px;
            width: 100%;

            &-nothing {
                height: unset;
            }
        }

        &__item {
            max-height: 100%;
            max-width: 100%;

            &-selected {
                object-fit: contain;
                max-width: 100%;
                max-height: 100%;
            }

            &.image {
                object-fit: contain;
                height: auto;
            }

            &.thumbnail {
                height: 100px;
                object-fit: contain;
            }
            &.thumbnail--fullscreen {
                height: 150px;
            }

            &.video-player {
                height: 80%;
                width: 100%;
            }
        }

        &__fallback {
            display: flex;
            justify-content: center;
            align-items: center;

            .image {
                width: calc(100% - 170px);
            }
        }
    }
</style>
