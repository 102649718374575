<template>
    <ul class="profile-ratings">
        <li class="profile-ratings-item" :class="{'profile-ratings-sample': isSample}">
            <VJoyIcon class="profile-ratings-item-icon" name="mood-good" color="secondary" />
            <div class="profile-ratings-item-wrapper">
                <p>{{ t('profile.recommandations.quality.rating.label') }}</p>
                <VJoyRatingStars :rating-value="ratings.quality" size="medium" />
            </div>
        </li>
        <li class="profile-ratings-item" :class="{'profile-ratings-sample': isSample}">
            <VJoyIcon class="profile-ratings-item-icon" name="clock" color="secondary" />
            <div class="profile-ratings-item-wrapper">
                <p>{{ t('profile.recommandations.delay.rating.label') }}</p>
                <VJoyRatingStars :rating-value="ratings.delay" size="medium" />
            </div>
        </li>
        <li class="profile-ratings-item" :class="{'profile-ratings-sample': isSample}">
            <VJoyIcon class="profile-ratings-item-icon" name="megaphone" color="secondary" />
            <div class="profile-ratings-item-wrapper">
                <p>{{ t('profile.recommandations.communication.rating.label') }}</p>
                <VJoyRatingStars :rating-value="ratings.communication" size="medium" />
            </div>
        </li>
    </ul>
</template>

<script setup lang="ts">
    import {toRef, useTranslation} from '#imports';
    import type {AppraisalAverageRating} from '~/types/domain/Appraisals';
    import {VJoyIcon, VJoyRatingStars} from '@maltjoy/core-vue';

    type Props = {
        modelValue: AppraisalAverageRating;
        isSample: boolean;
    };
    const props = defineProps<Props>();
    const {t} = useTranslation();
    const ratings = toRef(props, 'modelValue');
</script>

<style lang="scss" scoped>
    .profile-ratings {
        margin: 0 0 30px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        width: 100%;

        &-sample {
            opacity: 0.3;
        }

        &-item {
            display: flex;
            align-items: flex-start;

            &-icon {
                margin-right: var(--joy-core-spacing-1);
            }
        }
    }
</style>
