<template>
    <VJoyPanel class="left-side-component" :class="{'first-element': firstElement}">
        <template #panel-title>
            <div class="left-side-component__title">
                <slot name="title" />
            </div>
        </template>

        <template #panel-title-action>
            <div v-if="hasSlotTitleActions" class="joy-panel__title left-side-component__title__actions">
                <slot name="title-action" />
            </div>
        </template>

        <template #panel-body>
            <div class="left-side-component__content">
                <slot name="content" />
            </div>
            <div class="left-side-component__actions">
                <slot name="actions" />
            </div>
        </template>
    </VJoyPanel>
</template>

<script setup lang="ts">
    import {useSlots} from 'vue';
    import {VJoyPanel} from '@maltjoy/core-vue';

    defineProps<{
        firstElement?: boolean;
    }>();

    const slots = useSlots();
    const hasSlotTitleActions = Object.prototype.hasOwnProperty.call(slots, 'title-action');
</script>
<style scoped lang="scss">
    .left-side-component.first-element {
        .left-side-component__title {
            padding: var(--joy-core-spacing-6);
        }

        &::before {
            background-color: var(--joy-color-secondary-30);
            border-radius: 6px 6px 0 0;
            content: '';
            display: block;
            height: 8px;
        }
    }

    .left-side-component {
        &.first-element {
            padding: 0;

            .left-side-component:before {
                background-color: var(--joy-color-secondary-30);
                border-radius: 6px 6px 0 0;
                content: '';
                display: block;
                height: 8px;
            }

            .left-side-component__title {
                width: 100%;
                display: block;
                background-color: var(--joy-color-secondary-10);
            }
        }

        &__title {
            display: flex;
            justify-content: space-between;
            &__actions {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            }
        }
        &__content {
            padding: var(--joy-core-spacing-4) 0;
        }
    }
</style>
