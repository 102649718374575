<template>
    <div v-if="missionPreferencesHelper.hasMissionPreferences()" class="mission-preferences-project">
        <div class="mission-preferences-project__title">
            <VJoyIcon name="clock" />
            <h3>{{ t(missionPreferencesHelper.getConfig().i18n.title) }}</h3>
        </div>
        <div class="mission-preferences-project__content">
            <template v-if="missionPreferencesHelper.hasNegativeMissionPreferences()">
                <div
                    class="mission-preferences-project__content__negative"
                    v-html="t(missionPreferencesHelper.getConfig().i18n.negative, missionPreferencesHelper.getNegativeMissionPreferences())"
                ></div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {useTranslation} from '#imports';
    import {useProfileStore} from '~/store/profileStore';
    import {ActivityAreaPreferences} from '../helper/MissionPreferencesHelper';
    import {VJoyIcon} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {missionPreferences} = useProfileStore();

    const missionPreferencesHelper = new ActivityAreaPreferences(missionPreferences!.activityArea, t);
</script>
