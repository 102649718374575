import {useAuth} from '#imports';
import {defineStore} from 'pinia';

export const useConnectToViewMoreStore = defineStore('connect-to-view-more', () => {
    const {loggedIn} = useAuth();

    function isAllowedToSeeContent(): boolean {
        return loggedIn === true;
    }

    return {
        isAllowedToSeeContent,
    };
});
