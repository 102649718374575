<template>
    <VJoyTooltip>
        <VJoyWrapper class="profile-availability" direction="column" no-margin>
            <VJoyAvailability :label="availabilityLabel" :status="availabilityStatus" :is-partial="availabilityIsPartial" />
            <span class="profile-availability__subtitle">{{ availabilitySubtitle }}</span>
        </VJoyWrapper>
        <template #tooltip-content>
            {{ tooltipContent }}
        </template>
    </VJoyTooltip>
</template>

<script setup lang="ts">
    import {computed, useTranslation} from '#imports';
    import {useProfileStore} from '~/store/profileStore';
    import {storeToRefs} from 'pinia';
    import {VJoyAvailability, VJoyTooltip, VJoyWrapper} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {profile} = storeToRefs(useProfileStore());

    const availabilityLabel = computed(() => {
        return profile.value?.availability.details
            ? t('profile.availability.indicator.explicit.label.v2', {availability: profile.value.availability.details.availability})
            : t('profile.availability.indicator.label.default');
    });
    const availabilityIsPartial = computed(() => profile.value?.availability.details.label === 'PARTIAL');
    const frequencyText = computed(() => {
        return profile.value?.availability.details.frequency ? t(`profile.frequency.text.${profile.value.availability.details.frequency}`) : null;
    });
    const availabilitySubtitle = computed(() => {
        if (profile.value?.availability.details.nextAvailabilityDate) {
            return t(`profile.availability.indicator.next.availability.${profile.value.availability.details.availability}`, {
                0: new Date(profile.value.availability.details.nextAvailabilityDate).toLocaleDateString(),
            });
        }

        if (availabilityIsPartial.value) {
            return `${t('profile.availability.partial.label')}, ${frequencyText.value}`;
        }

        return '';
    });

    type AvailabilityStatus = 'AVAILABLE' | 'AVAILABLE_AND_VERIFIED' | 'NOT_AVAILABLE_WITH_DATE' | 'AVAILABLE_SOON' | 'NOT_AVAILABLE';
    const availabilityStatus = computed(() => profile.value!.availability.details.availability as AvailabilityStatus);

    const tooltipContent = computed(() => {
        const availability = profile.value!.availability.details.availability;
        const hasNextAvailabilityDate = profile.value?.availability.details.nextAvailabilityDate;

        const workAvailability = profile.value!.availability.details.frequency === 'FULL_TIME' ? 'FULL_TIME' : 'PARTIAL';

        if (hasNextAvailabilityDate) {
            return t('profile.availability.indicator.newtooltip', {
                availability,
                workAvailability,
                nextAvailabilityDate: new Date(profile.value!.availability.details.nextAvailabilityDate).toLocaleDateString(),
                frequency: frequencyText.value,
            });
        }

        if (availability !== 'NOT_AVAILABLE_WITH_DATE') {
            return t('profile.availability.indicator.newtooltip', {
                availability,
                workAvailability,
                frequency: frequencyText.value,
                nextAvailabilityDate: undefined,
            });
        }

        return '';
    });
</script>

<style lang="scss" scoped>
    .profile-availability {
        margin-top: var(--joy-core-spacing-4);
        gap: var(--joy-core-spacing-1);

        &__subtitle {
            font-size: var(--joy-font-size-primary-200);
        }
    }
</style>
