<template>
    <VJoyDialog
        id="removalCertificationConfirmDialog"
        ref="removeDialog"
        pattern-confirm
        :confirm-text="t('general.continue')"
        :cancel-text="t('general.cancel')"
        @dialog:confirm="removeCertification"
    >
        <template #dialog-header>
            <p>{{ $t('generic.form.confirm.text') }}</p>
        </template>
    </VJoyDialog>

    <VJoyPanel>
        <template #panel-title>
            {{ t('profile.section.certifications.title') }}
        </template>
        <template #panel-title-action>
            <VJoyButton v-if="profile?.editMode" variant="ghost" circle icon="add" size="small" @click="openCertificationsAddDialog" />
        </template>

        <template #panel-body>
            <ul class="profile-certifications__list">
                <ReadMore
                    v-if="certificationRequests.state.length > 0"
                    id="certifications-section"
                    :view-more-label="viewMoreLabel"
                    :max-height="viewMoreMaxHeight"
                    component-name="profile-certifications-section"
                >
                    <Draggable
                        v-model="certificationRequests.state"
                        class="profile-certifications__list"
                        :group="{name: 'certifications', put: true, pull: true}"
                        draggable=".draggable-certification"
                        @start="dragging = true"
                        @end="dragging = false"
                        @update="dragged"
                    >
                        <CertificationFragment
                            v-for="element in certificationRequests.state"
                            :key="element.id"
                            :model-value="element"
                            :is-sample="false"
                            :is-edit-mode="profile!.editMode"
                            :remove-dialog-ref="removeDialog"
                            @remove="(id) => (certificationToRemoveId = id)"
                            @edit="(index) => openCertificationsEditDialog(index)"
                        />
                    </Draggable>
                </ReadMore>
                <div v-else-if="profile?.editMode">
                    <CertificationFragment :model-value="sampleCertification" :is-edit-mode="profile.editMode" :is-sample="true" />
                </div>
            </ul>
        </template>
    </VJoyPanel>
</template>

<script setup lang="ts">
    import {ref, useAuth, useTranslation, watch} from '#imports';
    import {storeToRefs} from 'pinia';
    import {VueDraggable as Draggable} from 'vue-draggable-plus';
    import ReadMore from '~/components/_common/read-more/ReadMore.vue';
    import CertificationFragment from '~/components/main/certifications/CertificationFragment.vue';
    import type {CertificationRequest} from '~/types/infra/CertificationRequest';
    import {certificationMapper} from '~/types/infra/CertificationMapper';
    import {Certification} from '~/types/domain/Certifications';
    import {eventBus} from '~/config/events';
    import {useProfileStore} from '~/store/profileStore';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';
    import {VJoyButton, VJoyDialog, VJoyPanel} from '@maltjoy/core-vue';
    import type {SortableEvent} from 'vue-draggable-plus';

    const {t} = useTranslation();
    const {updateCertifications} = useProfileStore();
    const {certifications, profile} = storeToRefs(useProfileStore());
    const {loggedIn} = useAuth();
    const removeDialog = ref<InstanceType<typeof VJoyDialog> | null>(null);
    const certificationToRemoveId = ref('');
    const dragging = ref<boolean>(false);
    const certificationRequests = ref<{state: CertificationRequest[]}>({state: []});
    const viewMoreLabel = loggedIn ? t('jstext.readmore.label.readmore') : t('profile.section.certifications.private', [profile.value?.firstname]);
    const viewMoreMaxHeight = loggedIn ? 180 : 80;

    const sampleCertification = certificationMapper.toRequest(
        new Certification({
            title: 'Java Expert',
            graduationYear: 2012,
            id: '',
            organization: '',
            skills: [],
            link: '',
        }),
        0,
    );

    initDraggableList();

    watch(
        () => certifications.value,
        () => {
            initDraggableList();
        },
    );

    function initDraggableList() {
        certificationRequests.value.state =
            certifications.value?.items.map((certification, index) => certificationMapper.toRequest(certification, index)) || [];
    }

    function removeCertification() {
        const currentCertifications = certifications.value?.items.filter((certification) => certification.id !== certificationToRemoveId.value) || [];

        const certificationRequests = currentCertifications.map((certification, index) => {
            return certificationMapper.toRequest(certification, index);
        });
        if (certificationRequests) {
            updateCertifications({certifications: certificationRequests}).then(() => {
                initDraggableList();
                removeDialog.value?.hide();
            });
        }
    }

    async function dragged() {
        if (certificationRequests.value.state.length > 0) {
            await updateCertifications({certifications: certificationRequests.value.state});
        }
    }

    function openCertificationsEditDialog(certificationId: number) {
        eventBus.emit('certifications:edit-mode', {certificationId, entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
    }
    function openCertificationsAddDialog() {
        eventBus.emit('certifications:edit-mode', {certificationId: undefined, entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
    }
</script>

<style lang="scss" scoped>
    .profile-certifications__list {
        list-style-type: none;
    }
</style>
