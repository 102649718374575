import {ref, type Ref} from '#imports';
import {defineStore, storeToRefs} from 'pinia';
import {useProfileStore} from '~/store/profileStore';
import type {ExternalReputationResource} from '~/types/api-raw-types';
import {reputationRepository} from '~/types/infra/repository/reputation/reputationRepository';

export const useReputationStore = defineStore('reputation', () => {
    const reputations = ref<ExternalReputationResource[]>([]);
    const {profile} = storeToRefs(useProfileStore());

    async function getReputations(): Promise<Ref<ExternalReputationResource[]>> {
        reputations.value = await reputationRepository.getReputation(profile.value!);

        return reputations;
    }

    async function getAllWidgetsAndReputation(): Promise<ExternalReputationResource[]> {
        return await reputationRepository.getAllWidgetsAndReputation(profile.value!);
    }

    async function saveReputation(reputation: ExternalReputationResource, userId: string): Promise<void> {
        await reputationRepository.saveReputation(reputation, userId);
        await getReputations();
    }

    async function deleteReputation(reputation: ExternalReputationResource): Promise<void> {
        await reputationRepository.deleteReputation(reputation);
        await getReputations();
    }

    return {
        reputations,
        deleteReputation,
        getReputations,
        getAllWidgetsAndReputation,
        saveReputation,
    };
});
