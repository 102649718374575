import type {Experience} from '~/types/domain/Experiences';
import type {ExperienceRequest} from './ExperienceRequest';

class ExperienceMapper {
    toRequest(experience: Experience, index: number): ExperienceRequest {
        return {
            index,
            area: experience.area == null ? undefined : experience.area,
            asFreelance: experience.asFreelance,
            commentCount: experience.commentCount,
            company: experience.company,
            companyId: experience.companyId,
            currentJob: experience.currentJob,
            description: experience.description,
            endDate: experience.endDate ? new Date(experience.endDate) : undefined,
            id: experience.id,
            location: experience.location,
            logo: experience.logo,
            organizationName: experience.organizationName,
            recommendationCount: experience.recommendationCount,
            recos: experience.recos,
            startDate: experience.startDate ? new Date(experience.startDate) : undefined,
            tags: experience.tags,
            title: experience.title,
        };
    }

    sortByDate(a: ExperienceRequest, b: ExperienceRequest) {
        const endDateA = a.endDate && !a.currentJob ? new Date(a.endDate) : null;
        const endDateB = b.endDate && !b.currentJob ? new Date(b.endDate) : null;

        if (endDateA === null && endDateB === null && a.startDate && b.startDate) {
            return b.startDate.getTime() - a.startDate.getTime();
        } else if (endDateA === null) {
            return -1;
        } else if (endDateB === null) {
            return 1;
        }

        if ((endDateA.getMonth(), endDateA.getFullYear()) === (endDateB.getMonth(), endDateB.getFullYear())) {
            if (a.startDate && b.startDate) return b.startDate?.getTime() - a.startDate?.getTime();
        }

        return endDateB.getTime() - endDateA.getTime();
    }
}

export const experienceMapper = new ExperienceMapper();
