<template>
    <div v-if="!hasNoMissionPreference || shouldActivateEditMode">
        <LeftSideComponent>
            <template #title>
                {{ t('profile.section.mission.preferences.short.title') }}
            </template>

            <template #title-action>
                <div v-if="shouldActivateEditMode">
                    <VJoyButton href="/profile/mission-preferences" icon="pen" circle variant="ghost" size="small" />
                </div>
            </template>

            <template #content>
                <MissionPreferencesProjectDuration />
                <MissionPreferencesActivityArea />
                <MissionPreferencesCompanySize />

                <div v-if="hasNoMissionPreference" v-html="t('profile.section.mission.preferences.placeholder.content')"></div>
            </template>
        </LeftSideComponent>
    </div>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import LeftSideComponent from '~/components/left-side/common/LeftSideComponent.vue';
    import {useTranslation} from '#imports';
    import {useProfileStore} from '~/store/profileStore';
    import MissionPreferencesActivityArea from './ui/MissionPreferencesActivityArea.vue';
    import MissionPreferencesCompanySize from './ui/MissionPreferencesCompanySize.vue';
    import MissionPreferencesProjectDuration from './ui/MissionPreferencesProjectDuration.vue';
    import {VJoyButton} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {profile, hasNoMissionPreference} = useProfileStore();

    const shouldActivateEditMode = computed(() => profile?.editMode);
</script>

<style lang="scss">
    .mission-preferences-project {
        margin-bottom: var(--joy-core-spacing-6);

        &__title {
            display: flex;
            align-items: center;
            margin-bottom: var(--joy-core-spacing-2);

            .joy-icon {
                margin-right: var(--joy-core-spacing-2);
            }
        }

        &__content {
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style: none;
                }
            }

            &__negative {
                margin-bottom: var(--joy-core-spacing-4);
            }
        }
    }
</style>
