<template>
    <div class="profile-reputation-widgets__widget-stats" :data-provider="reputation.providerName">
        <div v-for="(stat, index) in reputationStats(reputation)" :key="`${reputation.providerName}-${index}`">
            <div v-if="hasValue(stat)" :data-stat="stat.property">
                <div stat-slot>
                    <strong>{{ stat.value }}</strong>
                    <span stat-name>
                        {{ t(`profile.reputation.widgets.stat.label.${reputation.providerName}.${stat.property}`) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import type {ExternalReputationResource, ExternalReputationStatResource} from '~/types/api-raw-types';
    import {useTranslation} from '#imports';

    const {t} = useTranslation();

    type Props = {
        reputation: ExternalReputationResource;
    };
    const props = defineProps<Props>();

    function reputationStats(reputation: ExternalReputationResource) {
        if (reputation.providerName === 'kaggle') {
            return reputation.stats.filter((stat) => stat.property !== 'tier');
        }

        return reputation.stats;
    }

    function hasValue(stat: ExternalReputationStatResource): boolean {
        return stat.value !== '';
    }
</script>

<style lang="scss" scoped>
    /*=================================================================
        =            Stackoverflow widget variables and mixins            =
        =================================================================*/

    $gold: (
        color: #ffcc01,
        background-color: #faf4e3,
        border-color: #ece3c8,
    );

    $bronze: (
        color: #d1a684,
    );
    $silver: (
        color: #b4b8bc,
    );

    @mixin stackOverflowMedal($grade) {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--joy-core-radius-2);
        margin: 3px;
        padding: 3px 10px;
        border: 1px solid transparent;

        /* search for bg color variable, if null take lighten medal color */
        @if map-get($grade, background-color) {
            background-color: map-get($grade, background-color);
        } @else {
            background-color: lighten(map-get($grade, color), 27%);
        }

        /* search for border color variable if null take lighten medal color */
        @if map-get($grade, border-color) {
            border-color: map-get($grade, border-color);
        } @else {
            border-color: lighten(map-get($grade, color), 22%);
        }

        [stat-name] {
            display: none;
        }

        &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            margin-right: 5px;
            border-radius: var(--joy-core-radius-2);
            background-color: map-get($grade, color);
        }
    }

    /*=====  End of Stackoverflow widget variables and mixins  ======*/

    /*=================================================================
    =            Kaggle widget variables and mixins            =
    =================================================================*/

    $goldK: (
        color: rgb(255, 212, 72),
        dominant-color: rgb(173, 118, 21),
    );

    $bronzeK: (
        color: rgb(240, 186, 124),
        dominant-color: rgb(142, 91, 61),
    );

    $silverK: (
        color: rgb(233, 233, 233),
        dominant-color: rgb(131, 130, 128),
        border-color: #eff0f1,
    );

    @mixin kaggleMedal($grade) {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--joy-core-radius-2);
        margin: 3px;
        padding: 3px 10px;
        border: 1px solid transparent;

        background-color: lighten(map-get($grade, color), 27%);

        /* search for border color variable if null take lighten medal color */
        @if map-get($grade, border-color) {
            border-color: map-get($grade, border-color);
        } @else {
            border-color: lighten(map-get($grade, color), 22%);
        }

        [stat-name] {
            display: none;
        }

        &::before {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            margin-right: 5px;
            border-radius: var(--joy-core-radius-3);
            background: linear-gradient(
                -45deg,
                map-get($grade, dominant-color),
                map-get($grade, dominant-color) 49%,
                map-get($grade, dominant-color) 49%,
                map-get($grade, dominant-color) 51%,
                map-get($grade, color) 51%
            );
            border-color: map-get($grade, border-color);
        }
    }

    /*=====  End of Kaggle widget variables and mixins  ======*/

    .profile-reputation-widgets__widget-stats {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        color: var(--joy-color-secondary-50);
        padding: 3px 15px;

        & > :is(:first-child) {
            width: 100%;
        }

        [stat-name] {
            padding-left: 3px;
            text-transform: uppercase;
            font-size: var(--joy-font-size-primary-200);
        }
    }

    [data-provider='kaggle'] {
        [data-stat='bronzeBadges'] {
            @include kaggleMedal($bronzeK);
        }

        [data-stat='silverBadges'] {
            @include kaggleMedal($silverK);
        }

        [data-stat='goldBadges'] {
            @include kaggleMedal($goldK);
        }
    }

    [data-provider='stackoverflow'] {
        [data-stat='bronzeBadges'] {
            @include stackOverflowMedal($bronze);
        }

        [data-stat='silverBadges'] {
            @include stackOverflowMedal($silver);
        }

        [data-stat='goldBadges'] {
            @include stackOverflowMedal($gold);
        }
    }

    [data-provider='github'],
    [data-provider='behance'],
    [data-provider='twitter'] {
        & > :is(:first-child) {
            width: unset;
        }

        [stat-slot] {
            padding: 3px 15px;
        }
    }
</style>
