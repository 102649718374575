<template>
    <div class="gauge-wrapper">
        <div class="gauge-content">
            <svg width="300" height="180">
                <path class="gauge-mask" d="M 16 140 A 129 130 0 0 1 274 140" />
                <path class="gauge-value" :d="valueToSVGPath" />
                <text class="gauge-value" x="98" y="104">{{ currentValue }} %</text>
                <text class="gauge-legend" x="4" y="170">0%</text>
                <text class="gauge-legend" x="258" y="170">100%</text>
            </svg>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {computed, ref} from '#imports';
    const props = withDefaults(defineProps<{currentValue: number}>(), {currentValue: 0});

    const interval = ref(0);
    const displayedCurrentValue = ref(0);

    function closestSupportedValue(): number {
        for (let i = 0; i < 4; i++) {
            if ((displayedCurrentValue.value + i) % 5 === 0) {
                return displayedCurrentValue.value + i;
            }
        }
        return displayedCurrentValue.value + 4;
    }

    const valueToSVGPath = computed(() => {
        clearInterval(interval.value);

        const pathPrefix = 'M 16 140 A 129 130 0 0 1';

        const svgPaths: Record<number, string> = {
            0: '',
            5: `${pathPrefix} 18 120`,
            10: `${pathPrefix} 22 100`,
            15: `${pathPrefix} 30 81`,
            20: `${pathPrefix} 41 64`,
            25: `${pathPrefix} 54 48`,
            30: `${pathPrefix} 69 35`,
            35: `${pathPrefix} 86 24`,
            40: `${pathPrefix} 105 16`,
            45: `${pathPrefix} 125 12`,
            50: `${pathPrefix} 145 10`,
            55: `${pathPrefix} 165 12`,
            60: `${pathPrefix} 185 16`,
            65: `${pathPrefix} 204 24`,
            70: `${pathPrefix} 221 35`,
            75: `${pathPrefix} 236 48`,
            80: `${pathPrefix} 249 64`,
            85: `${pathPrefix} 260 81`,
            90: `${pathPrefix} 268 100`,
            95: `${pathPrefix} 272 120`,
            100: `${pathPrefix} 274 140`,
        };

        if (displayedCurrentValue.value === props.currentValue) {
            return svgPaths[closestSupportedValue()];
        }

        interval.value = window.setInterval(() => {
            displayedCurrentValue.value += 5;
        }, 10);

        return svgPaths[closestSupportedValue()];
    });
</script>

<style lang="scss" scoped>
    .gauge-content {
        text-align: center;
        min-height: 180px;

        svg {
            fill: none;
        }

        path.gauge-mask {
            stroke-linecap: round;
            stroke: var(--joy-color-secondary-10);
            stroke-width: 14;
        }

        path.gauge-value {
            stroke-linecap: round;
            stroke: var(--joy-color-secondary-30);
            stroke-width: 15;
        }

        text.gauge-value {
            font-family: var(--joy-font-family-title);
            font-size: var(--joy-font-size-secondary-600);
            fill: var(--joy-color-neutral-60);
            font-weight: 500;
            stroke-width: 0;
        }

        text.gauge-legend {
            font-size: var(--joy-font-size-primary-400);
            fill: var(--joy-color-neutral-40);
            font-weight: 400;
            stroke-width: 0;
        }
    }
</style>
