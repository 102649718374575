import {useLogger} from '#imports';
import {Configuration, ClientOnboardingApi} from '@api';

export function useClientOnboarding() {
    const apiConfiguration = new Configuration({basePath: ''});
    const clientOnboardingApi = new ClientOnboardingApi(apiConfiguration);
    const logger = useLogger();

    async function getProposeProjectOnboardingTour(): Promise<boolean> {
        try {
            return await clientOnboardingApi.getProposeProjectOnboarding();
        } catch (e) {
            logger.error("Couldn't get Propose Project Onboarding");
            return false;
        }
    }

    async function acknowledgeProposeProjectOnboarding(): Promise<void> {
        try {
            await clientOnboardingApi.acknowledgeProposeProjectOnboarding();
        } catch (e) {
            logger.error("Couldn't acknowledge Propose Project Onboarding");
        }
    }

    return {
        getProposeProjectOnboardingTour,
        acknowledgeProposeProjectOnboarding,
    };
}
