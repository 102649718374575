import {computed, ref, useAuth, useCookie, useNuxtApp, useRequestHeaders} from '#imports';
import {useRoute} from '@typed-router';

export function useExternalUserBanner() {
    const route = useRoute();
    const headers = useRequestHeaders();
    const {$pinia} = useNuxtApp();
    const auth = useAuth($pinia);
    const externalUserBannerCookie = useCookie('external-user-banner');

    const comingNotFromSearch = route.query && Object.keys(route.query).length === 0;
    const isExternalReferer = headers.referrer === undefined || headers.referrer?.includes('malt') === false;
    const notConnected = auth.loggedIn === false;
    const discarded = externalUserBannerCookie.value !== undefined;
    const clickedOnBanner = ref<boolean>(false);

    const displayExternalUserBanner = computed(
        () => isExternalReferer && notConnected && !discarded && comingNotFromSearch && !clickedOnBanner.value,
    );

    return {
        displayExternalUserBanner,
        clickedOnBanner,
        externalUserBannerCookie,
    };
}
