<template>
    <VJoyDialog id="shortlistModeSelection" ref="dialog" size="large" :cancel-text="t('general.cancel')">
        <template #dialog-header>
            <div>
                {{ t('shortlist.popup.contact.more') }}
            </div>
        </template>

        <template #dialog-subheader>
            <div>
                {{ t('shortlist.popup.info.one') }}
                <br />
                <span v-html="t('shortlist.popup.info.two')"></span>
            </div>
        </template>

        <template #dialog-body>
            <div class="modal-shortlist-action__body">
                <VJoyWrapper justify="center" align="stretch">
                    <VJoyAvatarsList>
                        <VJoyAvatar :photo-url="photoUrl" />
                        <VJoyAvatar v-for="index in 5" :key="index" />
                    </VJoyAvatarsList>
                </VJoyWrapper>

                <div class="modal-shortlist-action__actions">
                    <VJoyButton
                        ref="dialogConfirm"
                        size="small"
                        data-testid="shortlist-add-more-freelancer-cta"
                        :loading="loading"
                        variant="main"
                        @click="handleCreateShortlist"
                    >
                        {{ t('shortlist.popup.accept') }}
                    </VJoyButton>
                    <VJoyButton variant="ghost" size="small" data-testid="shortlist-submit-cta" @click="contactThisFreelancer">
                        {{ t('shortlist.popup.deny') }}
                    </VJoyButton>
                </div>
            </div>
        </template>
    </VJoyDialog>
</template>

<script setup lang="ts">
    import {computed, guestHost, ref, useDataTracking, useTranslation} from '#imports';
    import {eventBus} from '@/config/events';
    import {useProfileStore} from '@/store/profileStore';
    import {useShortlistStore} from '@/store/shortlist/ShortlistStore';
    import {Photo} from '@malt/asset-utils';
    import {navigateTo, useRoute} from '@typed-router';
    import {VJoyAvatar, VJoyAvatarsList, VJoyButton, VJoyDialog, VJoyWrapper} from '@maltjoy/core-vue';
    import {storeToRefs} from 'pinia';
    import {onMounted, onUnmounted} from 'vue';
    import {extractLink} from '~/helpers/api/ExtractLinkHelper';

    const {t} = useTranslation();
    const dialog = ref<InstanceType<typeof VJoyDialog> | null>(null);
    const loading = ref(false);
    const profileStore = useProfileStore();
    const {profile} = storeToRefs(profileStore);
    const shortlistStore = useShortlistStore();

    const photoUrl = computed(() => {
        if (profile.value?.photo) {
            return new Photo(profile.value.photo).getFaceThumbnailUrl({width: 125, height: 125, forcedFormat: 'webp'});
        }
        return '';
    });

    async function handleCreateShortlist() {
        loading.value = true;
        const {track} = useDataTracking();
        const route = useRoute();
        const searchId = route?.query?.searchid as string;

        const redirectUrl = await shortlistStore.add();

        if (redirectUrl) {
            track('freelancer_shortlisted', {
                search_event_id: searchId,
                freelancer_account_id: profile.value!.accountId,
            });
            location.href = redirectUrl;
        }
        loading.value = false;
    }

    function contactThisFreelancer() {
        const proposeProjectUrl = extractLink(profile.value!.links, 'proposeProject');
        const host = guestHost();
        const {track} = useDataTracking();
        const route = useRoute();
        const searchId = route?.query?.searchid as string;
        track('freelancer_shortlisted', {
            search_event_id: searchId,
            freelancer_account_id: profile.value!.accountId,
        });

        return navigateTo(`${host}${proposeProjectUrl}`, {external: true});
    }

    function openDialog() {
        dialog.value?.show();
    }

    onMounted(() => {
        eventBus.on('shortlist:display-choice', openDialog);
    });

    onUnmounted(() => {
        eventBus.off('shortlist:display-choice', openDialog);
    });
</script>

<style lang="scss" scoped>
    .joy-avatars-list {
        .joy-avatar__wrapper {
            --avatar-placeholder-background: #ffffff !important;
            border: 1px solid var(--joy-color-secondary-50) !important;
            border-radius: 50%;

            :deep(.joy-avatar) {
                --avatar-size: 85px !important;
            }
        }
    }

    .modal-shortlist-action {
        &__actions {
            display: flex;
            row-gap: var(--joy-core-spacing-3);
            flex-direction: column;
            align-items: center;
            padding: var(--joy-core-spacing-1);
        }
        &__body {
            overflow: hidden;
        }
    }
</style>
