<template>
    <VJoyHighlight accent display-icon icon="check" level="info">
        <template #highlight-title>
            {{ t('profile.edition.completion.panel.fully.complete.tip.title') }}
        </template>
        <p>{{ t('profile.edition.completion.panel.fully.complete.tip.subtitle') }}</p>
    </VJoyHighlight>
</template>

<script setup lang="ts">
    import {useTranslation} from '#imports';
    import {VJoyHighlight} from '@maltjoy/core-vue';

    const {t} = useTranslation();
</script>
