export class Photo {
    baseUrl;
    name;

    constructor(photoOrBaseUrl, nameIfFirstArgIsBaseUrl) {
        if (typeof photoOrBaseUrl === 'object') {
            if (nameIfFirstArgIsBaseUrl) {
                throw new Error('Only one argument expected here');
            }
            this.baseUrl = photoOrBaseUrl.baseUrl;
            this.name = photoOrBaseUrl.name;
        } else {
            if (!nameIfFirstArgIsBaseUrl) {
                throw new Error('Two arguments expected here');
            }
            this.baseUrl = photoOrBaseUrl;
            this.name = nameIfFirstArgIsBaseUrl;
        }
    }

    get absoluteUrl() {
        return `${this.baseUrl}/${this.name}`;
    }

    getAutoThumbnailUrl({width, height, zoomOut, forcedFormat, quality}) {
        return this._fullUrl({
            width,
            height,
            cropResizeOption: 'thumb',
            gravity: 'auto',
            zoomOut,
            autoFormat: !forcedFormat,
            forcedFormat: forcedFormat,
            quality,
        });
    }

    getSimpleThumbnailUrl({width, height, forcedFormat, quality}) {
        return this._fullUrl({
            width,
            height,
            cropResizeOption: 'thumb',
            autoFormat: !forcedFormat,
            forcedFormat: forcedFormat,
            quality,
        });
    }

    getFaceCircleUrl({width, height, zoomOut, forcedFormat, quality}) {
        return this._fullUrl({
            width,
            height,
            cropResizeOption: 'thumb',
            gravity: 'face',
            zoomOut,
            radius: 'max',
            autoFormat: !forcedFormat,
            forcedFormat,
            quality,
        });
    }

    getFaceFilledUrl({width, height, forcedFormat, quality}) {
        return this._fullUrl({
            width,
            height,
            cropResizeOption: 'fill',
            gravity: 'face',
            autoFormat: !forcedFormat,
            forcedFormat: forcedFormat,
            quality,
        });
    }

    getFaceThumbnailUrl({width, height, zoomOut, forcedFormat, quality}) {
        return this._fullUrl({
            width,
            height,
            cropResizeOption: 'thumb',
            gravity: 'face',
            zoomOut,
            autoFormat: !forcedFormat,
            forcedFormat: forcedFormat,
            quality,
        });
    }

    getFilledUrl({width, height, forcedFormat, quality}) {
        return this._fullUrl({
            width,
            height,
            cropResizeOption: 'fill',
            autoFormat: !forcedFormat,
            forcedFormat: forcedFormat,
            quality,
        });
    }

    getScaledUrl({width, height, forcedFormat, quality}) {
        return this._fullUrl({
            width,
            height,
            cropResizeOption: 'scale',
            autoFormat: !forcedFormat,
            forcedFormat: forcedFormat,
            quality,
        });
    }

    _fullUrl(tfs) {
        if (tfs.cropResizeOption == null) {
            // By default, when no crop/resize operation is specified:
            // - Cloudinary scale images when both dimensions are provided
            // - Cloudimage crop images when both dimensions are provided
            // so let's be specific!
            console.error(`A crop/resize parameter should have been provided here: ${JSON.stringify(tfs)}`);
        }

        if (tfs.forcedFormat) {
            tfs.autoFormat = false;
        }
        if (this.baseUrl.includes('dam.malt.com')) {
            return this._fullUrlForCloudimage(tfs);
        }
        return this._fullUrlForCloudinary(tfs);
    }

    _fullUrlForCloudimage(tfs) {
        const params = [];
        if (tfs.gravity) {
            // While "g_auto" doesn't mean the same than "g_face" to Cloudinary, in practice we always use it for
            // pictures containing a face, and we must handle them the same way with Cloudimage
            // Also, Cloudimage's "gravity=face" is equivalent to both "g_face" and "g_faces"
            if (['auto', 'face', 'faces'].includes(tfs.gravity)) {
                tfs.gravity = 'face';
            } else {
                console.error(`Unsupported gravity: ${tfs.gravity}`);
                tfs.gravity = null;
            }
        }

        if ('face' === tfs.gravity) {
            params.push('gravity=face');
        }
        if ('fill' === tfs.cropResizeOption) {
            // note: "crop" is the default operation on Cloudimage, but let's specify it
            params.push('func=crop');
        } else if ('limit' === tfs.cropResizeOption) {
            params.push('func=bound');
        } else if ('scale' === tfs.cropResizeOption) {
            params.push('func=cover');
        } else if ('thumb' === tfs.cropResizeOption) {
            if ('face' === tfs.gravity) {
                params.push('func=face');

                if (tfs.zoomOut) {
                    const faceMargin = Math.round((1 - tfs.zoomOut) * 100);
                    params.push(`face_margin=${faceMargin}`);
                }
            } else {
                // equivalent to cropResizeOption == "fill"
                params.push('func=crop');
            }
        } else {
            console.error(`Unsupported crop/resize transformation: ${tfs.cropResizeOption}`);
            tfs.cropResizeOption = null;
        }

        if (tfs.width) {
            params.push(`w=${tfs.width}`);
        }
        if (tfs.height) {
            params.push(`h=${tfs.height}`);
        }

        if (tfs.radius) {
            if (tfs.radius === 'max') {
                params.push('radius=9999');
            } else {
                params.push(`radius=${tfs.radius}`);
            }
        }

        if (tfs.quality) {
            params.push(`q=${tfs.quality}`);
        }

        if (tfs.forcedFormat) {
            params.push(`force_format=${tfs.forcedFormat}`);
        }

        return `${this.baseUrl}/${this.name}?${params.join('&')}`;
    }

    _fullUrlForCloudinary(tfs = {}) {
        // note: when no c_* paremeter is provided, "scale" is the default operation on Cloudinary if width and height are provided
        return (
            this.baseUrl +
            '/' +
            (tfs.width ? 'w_' + tfs.width : '') +
            (tfs.height ? ',h_' + tfs.height : '') +
            (tfs.cropResizeOption ? ',c_' + tfs.cropResizeOption : '') +
            (tfs.gravity ? ',g_' + tfs.gravity : '') +
            (tfs.zoomOut ? ',z_' + tfs.zoomOut : '') +
            (tfs.radius ? ',r_' + tfs.radius : '') +
            (tfs.quality ? ',q_' + tfs.quality : '') +
            (tfs.autoFormat ? ',f_auto' : '') +
            '/' +
            this.name +
            (tfs.forcedFormat ? '.' + tfs.forcedFormat : '')
        );
    }
}
