<template>
    <div class="recommendation" :class="{sample: isSample}">
        <div class="recommendation__header">
            <VJoyAvatar
                :photo-url="recommendation.authorImgUrl || undefined"
                :full-name="`${recommendation.authorFirstName} ${recommendation.authorLastName}`"
            />
            <div>
                <div class="recommendation__header__author">
                    <div v-if="recommendation.authorFirstName" class="recommendation__header__author__name">
                        <VJoyLink v-if="recommendation.authorProfileUrl" :href="recommendation.authorProfileUrl">
                            <VJoyIcon name="user-lighter" size="xsmall" class="recommendation__header__author__name-icon" />
                            {{ recommendation.authorFirstName }} {{ recommendation.authorLastName }}
                        </VJoyLink>

                        <template v-else>
                            <VJoyIcon name="user-lighter" size="xsmall" class="recommendation__header__author__name-icon" />
                            {{ recommendation.authorFirstName }} {{ recommendation.authorLastName }}
                        </template>
                    </div>
                    <div v-if="recommendation.authorCompanyName" class="recommendation__header__author_company">
                        <VJoyIcon name="company-placeholder" size="xsmall" />
                        {{ recommendation.authorCompanyName }}
                    </div>
                </div>
                <div class="recommendation__header__date">
                    {{ dateToLocale(recommendation.lastUpdateDate) }}
                </div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div class="recommendation__content" v-html="textContent"></div>
            </div>
            <div v-if="editMode" class="action-edit">
                <div class="toolbar">
                    <VJoyDialogTrigger :dialog="removeDialogRef" @click="emitRemoval(recommendation.id)">
                        <VJoyButton class="recommendation-remove" variant="ghost" circle size="small" color="red" icon="trash" />
                    </VJoyDialogTrigger>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {computed, useFormat} from '#imports';
    import {nl2br} from '@malt/common';
    import {sanitize} from '@malt/sanitize';
    import type {VJoyDialog} from '@maltjoy/core-vue';
    import {VJoyAvatar, VJoyButton, VJoyDialogTrigger, VJoyIcon, VJoyLink} from '@maltjoy/core-vue';
    import {toRef} from 'vue';
    import type {Recommendation} from '~/types/domain/Recommendations';

    type Props = {
        modelValue: Recommendation;
        isEditMode: boolean;
        isSample: boolean;
        removeDialogRef?: InstanceType<typeof VJoyDialog> | null;
    };

    const props = defineProps<Props>();
    type Emits = {
        (e: 'remove', id: string): void;
    };
    const emit = defineEmits<Emits>();

    // Composables
    const {formatDateToShort} = useFormat();

    const recommendation = toRef(props, 'modelValue');
    const editMode = toRef(props, 'isEditMode');
    const textContent = computed(() => nl2br(sanitize(recommendation.value.textContent)));

    function dateToLocale(date: Date) {
        return formatDateToShort(date);
    }

    function emitRemoval(id: string) {
        emit('remove', id);
    }
</script>
<style scoped lang="scss">
    .action-edit {
        margin-left: auto;
    }

    .sample {
        opacity: 0.3;
    }

    .recommendation {
        padding: 0 0 20px;

        &:last-child {
            padding: 0;
        }

        &-remove {
            :deep(.joy-icon),
            &:hover :deep(.joy-icon) {
                color: var(--joy-color-error-50) !important; // I know
            }
        }

        &__header {
            display: flex;
            flex-direction: row;
            gap: var(--joy-core-spacing-4);

            &__author {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: var(--joy-core-spacing-4);

                &__name {
                    &-icon {
                        margin-right: var(--joy-core-spacing-1);
                    }
                }
            }

            &__date {
                font-size: var(--joy-font-size-primary-300);
            }
        }

        &__content {
            margin-top: var(--joy-core-spacing-2);
        }
    }
</style>
