<template>
    <div class="profile-rating-general">
        <VJoyRatingStars :rating-value="rating" size="medium">
            <template #rating-stars-content>
                {{
                    t('profile.missions.number.of.appraisals.with.rating', {
                        numberOfAppraisalsWithRating: appraisalCounter,
                    })
                }}
            </template>
        </VJoyRatingStars>
    </div>
</template>

<script setup lang="ts">
    import {useTranslation} from '#imports';
    import {VJoyRatingStars} from '@maltjoy/core-vue';

    type Props = {
        rating: number;
        appraisalCounter: number;
    };
    defineProps<Props>();
    const {t} = useTranslation();
</script>

<style lang="scss" scoped>
    .profile-rating-general {
        margin-top: -8px;
        margin-bottom: var(--joy-core-spacing-2);
    }
</style>
