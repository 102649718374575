<template>
    <VJoyDialog
        id="removalConfirmDialog"
        ref="removeDialog"
        pattern-confirm
        :confirm-text="t('general.continue')"
        :cancel-text="t('general.cancel')"
        @dialog:confirm="removeEducation"
    >
        <template #dialog-header>
            <p slot="dialog-header">{{ $t('generic.form.confirm.text') }}</p>
        </template>
    </VJoyDialog>

    <VJoyPanel>
        <template #panel-title>
            {{ t('profile.section.educations.title.v2') }}
        </template>

        <template #panel-title-action>
            <TranslateButton v-if="isTranslationActivated && educationRequests.state.length > 0" section="education" />
            <VJoyButton v-if="profile?.editMode" variant="ghost" circle icon="add" size="small" @click="openEducationsAddDialog" />
        </template>

        <template #panel-body>
            <ul class="profile-educations__list">
                <ReadMore
                    v-if="educationRequests.state.length > 0"
                    id="education-section"
                    :view-more-label="viewMoreLabel"
                    :max-height="viewMoreMaxHeight"
                    component-name="profile-education-section"
                >
                    <Draggable
                        v-model="educationRequests.state"
                        class="profile-educations__list"
                        :group="{name: 'educations', put: true, pull: true}"
                        draggable=".draggable-education"
                        @start="dragging = true"
                        @end="dragging = false"
                        @update="dragged"
                    >
                        <EducationFragment
                            v-for="element in educationRequests.state"
                            :key="element.id"
                            :model-value="element"
                            :is-edit-mode="!!profile && profile.editMode"
                            :is-sample="false"
                            :remove-dialog-ref="removeDialog"
                            @remove="(id) => (idx = id)"
                            @edit="(id) => openEducationsEditDialog(id)"
                        />
                    </Draggable>
                </ReadMore>
                <div v-else-if="profile?.editMode">
                    <EducationFragment :model-value="sampleEducation" :is-edit-mode="profile.editMode" :is-sample="true" />
                </div>
            </ul>
        </template>
    </VJoyPanel>
</template>

<script setup lang="ts">
    import {computed, onMounted, ref, useAuth, useTranslation, watch} from '#imports';
    import {useProfileStore} from '~/store/profileStore';
    import {eventBus} from '~/config/events';
    import ReadMore from '~/components/_common/read-more/ReadMore.vue';
    import {VueDraggable as Draggable} from 'vue-draggable-plus';
    import {storeToRefs} from 'pinia';
    import {educationMapper} from '~/types/infra/EducationMapper';
    import type {EducationRequest} from '~/types/infra/EducationRequest';
    import EducationFragment from '~/components/main/educations/EducationFragment.vue';
    import {Education} from '~/types/domain/Educations';
    import {TranslationDirection, useTranslationStore} from '~/store/translation/translationStore';
    import {onUnmounted} from 'vue';
    import TranslateButton from '~/components/main/TranslateButton.vue';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';
    import {VJoyButton, VJoyDialog, VJoyPanel} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {updateEducations} = useProfileStore();
    const {educations, profile} = storeToRefs(useProfileStore());
    const {loggedIn} = useAuth();
    const removeDialog = ref<InstanceType<typeof VJoyDialog> | null>(null);
    const idx = ref(0);
    const dragging = ref<boolean>(false);
    const educationRequests = ref<{state: EducationRequest[]}>({state: []});

    const {isTranslationActivated} = storeToRefs(useTranslationStore());
    const {translateAll, replaceTranslationContent} = useTranslationStore();
    const translationSection = 'educations';
    const translationField = 'content';

    const viewMoreLabel = loggedIn ? t('jstext.readmore.label.readmore') : t('profile.section.educations.private', [profile.value?.firstname]);
    const viewMoreMaxHeight = loggedIn ? 180 : 60;

    initDraggableList();

    const sampleEducation = computed(() =>
        educationMapper.toRequest(
            new Education({
                title: t('profile.section.educations.eg.title'),
                description: t('profile.section.educations.description.placeholder'),
                graduationYear: 2010,
                content: t('profile.section.educations.title.placeholder'),
            }),
            0,
        ),
    );

    async function translateEducations() {
        const educationContentRequests = educationRequests.value.state.map((education) => {
            return {
                section: translationSection,
                field: translationField,
                index: education.id,
                originalContent: education.content ?? ' ',
            };
        });

        await translateAll(educationContentRequests);
        replaceTranslationContent(translationSection, translationField, TranslationDirection.TRANSLATE, switchTranslation);
    }

    function restoreEducations() {
        replaceTranslationContent(translationSection, translationField, TranslationDirection.RESTORE, switchTranslation);
    }

    function switchTranslation(content: string, index: number, field: string) {
        educationRequests.value.state[index][field] = content;
    }

    onMounted(() => {
        eventBus.on('translation:request', translateEducations);
        eventBus.on('translation:restore', restoreEducations);
    });

    onUnmounted(() => {
        eventBus.off('translation:request', translateEducations);
        eventBus.off('translation:restore', restoreEducations);
    });

    watch(
        () => educations.value?.items,
        () => {
            initDraggableList();
        },
    );

    function initDraggableList() {
        educationRequests.value.state = educations.value?.items.map((education, index) => educationMapper.toRequest(education, index)) || [];
    }

    function removeEducation() {
        const currentEducations = educations.value;
        currentEducations?.items.splice(idx.value, 1);

        const educationRequests = currentEducations?.items.map((education, index) => {
            return educationMapper.toRequest(education, index);
        });
        if (educationRequests) {
            updateEducations({educations: educationRequests}).then(() => {
                initDraggableList();
                removeDialog.value?.hide();
                eventBus.emit('educations:deleted');
            });
        }
    }

    function openEducationsEditDialog(educationId: number) {
        if (profile.value?.editMode) {
            eventBus.emit('educations:edit-mode', {educationId, entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
        }
    }
    function openEducationsAddDialog() {
        if (profile.value?.editMode) {
            eventBus.emit('educations:add-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
        }
    }

    async function dragged() {
        if (educationRequests.value.state.length > 0) {
            await updateEducations({educations: educationRequests.value.state});
        }
    }
</script>

<style lang="scss" scoped>
    .profile-educations__list {
        list-style-type: none;
    }
</style>
