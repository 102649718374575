<template>
    <ul class="profile-indicators">
        <PriceIndicator
            :price="profile.price.price"
            :price-hidden="profile.price.priceHidden"
            :currency-code="profile.price.currencyCode"
            :edit-mode="profile.editMode"
            :is-strategy-profile="isStrategyProfile"
            @edit="emits('edit-price')"
        />
        <ExperienceLevelIndicator :edit-mode="profile.editMode" :experience-level="profile.experienceLevel" @edit="emits('edit-experience')" />
        <template v-if="!profile.editMode">
            <AnswerRateIndicator :answer-rate="profile.conversationStatistics.answerRate" />
            <AnswerTimeIndicator :average-answer-time-code="profile.conversationStatistics.averageAnswerTimeCode" />
        </template>
    </ul>
</template>

<script setup lang="ts">
    import type {Profile} from '~/types/domain/Profile';
    import PriceIndicator from './PriceIndicator.vue';
    import ExperienceLevelIndicator from './ExperienceLevelIndicator.vue';
    import AnswerRateIndicator from './AnswerRateIndicator.vue';
    import AnswerTimeIndicator from './AnswerTimeIndicator.vue';
    import {useStrategyProfile} from '@/composables/useStrategyProfile';
    import {computed} from '#imports';

    defineProps<{
        profile: Profile;
    }>();

    const emits = defineEmits<{
        (e: 'edit-price'): void;
        (e: 'edit-experience'): void;
    }>();

    const isStrategyProfile = computed(() => useStrategyProfile() || false);
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .profile-indicators {
        display: flex;
        padding: 0;
        margin: 0;
        margin-right: auto;
        min-height: 75px;
        list-style-type: none;
        color: var(--joy-color-text-body);
        overflow: hidden;
        border-radius: var(--joy-core-radius-2);
        border: 1px solid var(--joy-color-neutral-30);

        @include mq.screen_sm() {
            min-height: 110px;
            max-height: 110px;
        }

        &-title {
            height: 34px;
            font-size: var(--joy-font-size-primary-200);
        }

        &-content {
            font-weight: var(--joy-font-weight-bold);
            font-family: var(--joy-font-family);
            font-size: var(--joy-font-size-primary-600);
        }

        &-hidden-indication {
            font-size: var(--joy-font-size-primary-300);
        }

        &-item {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            padding: 10px;
            align-items: flex-start;
            background-color: var(--joy-color-neutral-10);
            flex: 1;

            border-left: 1px solid var(--joy-color-neutral-30);

            &-admin {
                cursor: pointer;
            }
        }

        &-main {
            max-width: 60%;
            padding: 10px 15px;
            color: var(--joy-color-neutral-10);
            background-color: var(--joy-color-secondary-30);
            border-color: var(--joy-color-neutral-30);
            border-left: 0;
            cursor: pointer;
        }
    }
</style>
