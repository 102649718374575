<template>
    <div class="profile-wrapper__profile-sidemenu">
        <CompletionList v-if="profile?.editMode" />
        <LeftSideLocationPreferences />
        <MissionPreferences v-if="profile?.editMode" />
        <ChecklistVerifications />
        <ReputationWidgetSection v-if="isReputationWidgetAccessible" />
        <Languages />
        <Categories />
        <SimilarProfiles v-if="!profile?.editMode" />
    </div>
</template>
<script setup lang="ts">
    import {storeToRefs} from 'pinia';
    import {computed} from 'vue';
    import Categories from '~/components/left-side/categories/Categories.vue';
    import ChecklistVerifications from '~/components/left-side/checklist/ChecklistVerifications.vue';
    import CompletionList from '~/components/left-side/completion-list/CompletionList.vue';
    import Languages from '~/components/left-side/languages/Languages.vue';
    import MissionPreferences from '~/components/left-side/mission-preferences/MissionPreferences.vue';
    import ReputationWidgetSection from '~/components/left-side/reputation-widgets/ReputationWidgetSection.vue';
    import SimilarProfiles from '~/components/left-side/similar-profiles/SimilarProfiles.vue';
    import {useSectionsVisibility} from '~/composables/useSectionsVisibility';
    import {useFeatureContext} from '~/store/featureStore';
    import {useProfileStore} from '~/store/profileStore';
    import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';
    import LeftSideLocationPreferences from '~~/src/components/left-side/location-preferences/LeftSideLocationPreferences.vue';

    const {profile} = storeToRefs(useProfileStore());
    const {features} = useFeatureContext();

    const isReputationWidgetAccessible = computed(() => useSectionsVisibility(SectionsVisibilityType.EXTERNAL_REPUTATION));
</script>
