import {onMounted, onUnmounted} from 'vue';
import {useDataTracking, useLogger, useMaltVisitorId, useNuxtApp} from '#imports';
import type {SessionReplay} from '../types';

/**
 * Starts Amplitude Session Replay.
 *
 * @param startIf The function is evaluated on mount and should returns true to start the recording.
 * If no function is provided, the recording starts automatically.
 */
export function useAmplitudeSessionReplay(startIf?: () => boolean | Promise<boolean>) {
    const logger = useLogger();
    const maltVisitorId = useMaltVisitorId();
    const {track, getSessionTimestamp} = useDataTracking();

    let intervalId: number | undefined;

    onMounted(async () => {
        const trackingSessionReplay = useNuxtApp().$trackingSessionReplay as SessionReplay | undefined;

        if (!trackingSessionReplay) {
            logger.error('Session Replay not available. Make sure you set "enableSessionReplay" to true in your module configuration.');
            return;
        }

        if (!startIf || (await startIf())) {
            if (!maltVisitorId.value) {
                logger.error('Unable to start session replay without maltVisitorId');
                return;
            }

            try {
                let sessionTimestamp = await getSessionTimestamp();

                await trackingSessionReplay.init(sessionTimestamp.amplitudeApiKey, {
                    serverZone: 'EU',
                    deviceId: maltVisitorId.value || undefined,
                    sessionId: sessionTimestamp.timestamp,
                    logLevel: import.meta.dev ? 2 : 0,
                }).promise;

                track('session_replay_started');

                window.clearInterval(intervalId);

                intervalId = window.setInterval(async () => {
                    try {
                        sessionTimestamp = await getSessionTimestamp();
                        trackingSessionReplay.setSessionId(sessionTimestamp.timestamp);
                    } catch (e) {
                        logger.error('An error occured while updating session replay sessionId', e);
                    }
                }, sessionTimestamp.timeout - 60000); // Update sessionId 1 minute before the timeout to ensure we don't miss any events
            } catch (e) {
                logger.error('An error occured while starting session replay', e);
            }
        }
    });

    onUnmounted(() => {
        const trackingSessionReplay = useNuxtApp().$trackingSessionReplay as SessionReplay | undefined;

        if (!trackingSessionReplay) {
            return;
        }

        trackingSessionReplay.shutdown();
        window.clearInterval(intervalId);
        intervalId = undefined;
    });
}
