<template>
    <CompletionItem :completed="completedItem" translation-key="profile.edition.completion.panel.checklist.item.EXPERIENCE" @click="handleClick" />
</template>

<script setup lang="ts">
    import {eventBus} from '@/config/events';
    import CompletionItem from './CompletionItem.vue';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';

    withDefaults(defineProps<{completedItem: boolean}>(), {completedItem: false});

    function handleClick() {
        eventBus.emit('experiences:add-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_GAUGE});
    }
</script>
