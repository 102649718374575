<template>
    <template v-if="isAllowedToSeeContent()">
        <slot />
    </template>
    <template v-else>
        <div class="connect-to-view-more">
            <div class="connect-to-view-more__content">
                <slot />
            </div>
            <div class="connect-to-view-more__mask">
                <div class="connect-to-view-more__mask-content">
                    <div>
                        <VJoyIcon name="lock" size="large" />
                    </div>
                    <div class="connect-to-view-more__mask-content__message">
                        {{ props.message }}
                    </div>
                    <div>
                        <VJoyLink href="/signup">
                            {{ t('profile.section.group.hidden.signup') }}
                        </VJoyLink>
                        |
                        <VJoyLink href="/signin">
                            {{ t('profile.section.group.hidden.signin') }}
                        </VJoyLink>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>
<script setup lang="ts">
    import {useTranslation} from '#imports';
    import {useConnectToViewMoreStore} from '~/store/connectToViewMoreStore';
    import {VJoyLink, VJoyIcon} from '@maltjoy/core-vue';

    const props = defineProps<{
        message: string;
    }>();

    const {isAllowedToSeeContent} = useConnectToViewMoreStore();
    const {t} = useTranslation();
</script>
<style scoped lang="scss">
    .connect-to-view-more {
        position: relative;

        &__mask {
            position: absolute;
            top: 1px;
            left: 1px;
            height: calc(100% - 2px);
            width: calc(100% - 2px);
            background-color: hsla(0, 0%, 100%, 0.95);
            border-radius: var(--joy-core-radius-3);

            &-content {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: var(--joy-core-spacing-4);

                height: 100%;
                width: 100%;

                &__message {
                    font-size: var(--joy-font-size-primary-600);
                }
            }
        }
    }
</style>
