<template>
    <VJoyHighlight display-icon :level="levelType">
        <VJoyWrapper wrap="nowrap" justify="space-between" align="flex-start" class="checklist-verification-compliance">
            <div>
                <p>{{ title }}</p>
                <VJoyLink :href="href" target="_blank">{{ messageLink }}</VJoyLink>
            </div>
            <VJoyTooltip variant="secondary" position="bottom-end" event="mouseenter">
                <VJoyIcon role="button" name="info-circle" color="neutral" />
                <template #tooltip-content>{{ infoContent }}</template>
            </VJoyTooltip>
        </VJoyWrapper>
    </VJoyHighlight>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {VJoyHighlight, VJoyIcon, VJoyLink, VJoyTooltip, VJoyWrapper} from '@maltjoy/core-vue';

    type Props = {
        upToDate?: Boolean | null;
        title: string;
        messageLink: string;
        href: string;
        infoContent: string;
    };
    const props = withDefaults(defineProps<Props>(), {
        upToDate: null,
    });

    const levelType = computed(() => {
        if (props.upToDate === null || props.upToDate) {
            return 'success';
        }

        return 'error';
    });
</script>

<style scoped lang="scss">
    .checklist-verification-compliance {
        margin-bottom: 0;
        p {
            margin-bottom: 0;
        }
    }
</style>
