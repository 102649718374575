<template>
    <div>
        <ClientOnly v-if="currentIdentityIsClient && !comesFromAdvancedSearch">
            <ProjectProposalProductTour />
        </ClientOnly>
        <div v-if="displayProfileCTA" class="profile-cta">
            <VJoyButton
                v-if="!comesFromSearch && !shortlist"
                class="profile-cta-project"
                variant="main"
                data-testid="contact-button-project-proposal"
                data-client-onboarding-propose-a-project
                @click="redirectToProjectProposal"
            >
                {{ t('show.contactme.button.label') }}
            </VJoyButton>
            <VJoyButton
                v-else-if="hasShortlistItems && !profileInShortlist"
                class="profile-cta-project"
                variant="main"
                :loading="loading"
                data-testid="contact-button-in-shortlist"
                @click="handleAddToShortlist"
            >
                {{ t('profile.shortlist.add.button') }}
            </VJoyButton>
            <VJoyButton
                v-else-if="hasShortlistItems && profileInShortlist"
                class="profile-cta-project"
                variant="main"
                :loading="loading"
                data-testid="contact-button-remove-shortlist"
                @click="handleRemoveFromShortlist"
            >
                {{ t('profile.shortlist.remove.button') }}
            </VJoyButton>
            <VJoyButton
                v-else
                class="profile-cta-project"
                variant="main"
                data-testid="contact-button-shortlist"
                data-client-onboarding-propose-a-project
                @click="displayChoice"
            >
                {{ t('show.contactme.button.label') }}
            </VJoyButton>
            <small class="profile-cta-subtitle">{{ t('profile.project.submission.title', [profile!.firstname]) }}</small>
        </div>
        <div v-else class="profile-cta">
            <div class="profile-cta-project"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {computed, guestHost, ref, useAuth, useDataTracking, useTranslation} from '#imports';
    import {useShortlistStore} from '@/store/shortlist/ShortlistStore';
    import {navigateTo, useRoute} from '@typed-router';
    import {storeToRefs} from 'pinia';
    import {eventBus} from '~/config/events';
    import {extractLink} from '~/helpers/api/ExtractLinkHelper';
    import {useProfileStore} from '~/store/profileStore';
    import ProjectProposalProductTour from './ProjectProposalProductTour.vue';
    import {VJoyButton} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {currentIdentityIsClient} = storeToRefs(useAuth());
    const shortlistStore = useShortlistStore();

    const {profile} = storeToRefs(useProfileStore());
    const {shortlist, hasShortlistItems, profileInShortlist, comesFromSearch} = storeToRefs(shortlistStore);
    const loading = ref(false);
    const route = useRoute();
    const isInOverviewMode = computed<boolean>(() => route?.fullPath?.includes('overview') || false);
    const comesFromAdvancedSearch = computed<boolean>(() => !!route?.query?.advancedSearchId);
    const displayProfileCTA = computed(() => !profile.value?.editMode && !isInOverviewMode.value && !comesFromAdvancedSearch.value);

    if (shortlist === null) {
        shortlistStore.reload();
    }

    async function handleAddToShortlist() {
        loading.value = true;
        const {track} = useDataTracking();
        const route = useRoute();
        const searchId = route?.query?.searchid as string;

        const redirectUrl = await shortlistStore.add();

        if (redirectUrl) {
            track('freelancer_shortlisted', {
                search_event_id: searchId,
                freelancer_account_id: profile.value!.accountId,
            });
            location.href = redirectUrl;
        }
        loading.value = false;
    }

    async function handleRemoveFromShortlist() {
        loading.value = true;
        await shortlistStore.remove();
        loading.value = false;
    }

    function displayChoice() {
        eventBus.emit('shortlist:display-choice');
    }

    function redirectToProjectProposal() {
        const proposeProjectUrl = extractLink(profile.value!.links, 'proposeProject');
        const host = guestHost();

        return navigateTo(`${host}${proposeProjectUrl}`, {external: true});
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .profile-cta {
        width: 200px;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include mq.screen_md {
            width: auto;
            flex-direction: row;
            gap: var(--joy-core-spacing-8);
        }
        @include mq.screen_xs {
            position: fixed;
            width: 100%;
            height: 40px;
            z-index: calc(var(--header-z-index) - 1);
            bottom: 20px;
            width: 100%;
            padding: 0 20px;
            right: 0;
            .profile-cta-subtitle {
                display: none;
            }
        }

        &-project {
            margin-bottom: var(--joy-core-spacing-2);
            width: 100%;
        }

        &-subtitle {
            margin-right: auto;
            margin-left: auto;
        }
    }
</style>
