import {useUniversalFetch} from '#imports';
import {urlService} from 'vue3-starter/dist/http/UrlService';
import type {Profile} from '~/types/domain/Profile';
import {Siblings} from '~/types/domain/Siblings';
import type {SiblingsRepository} from '~/types/domain/repository/SiblingsRepository';
import {extractLink} from '~/helpers/api/ExtractLinkHelper';
import type {ProfileSiblingResource} from '~/types/api-raw-types';

async function fetchSiblings(searchId: string, query: string, profile: Profile): Promise<Siblings> {
    try {
        const url = extractLink(profile.links, 'siblings');
        const computedUrl = `${urlService.buildUrl(url, searchId)}&q=${query}`;
        const siblingsResource = await useUniversalFetch<ProfileSiblingResource>(computedUrl);
        return new Siblings(siblingsResource);
    } catch (e) {
        return Siblings.default();
    }
}

export const siblingsRepository: SiblingsRepository = {
    fetchSiblings,
};
