<template>
    <LeftSideComponent v-if="isSimilarProfilesSectionVisible" data-testid="similar-profiles">
        <template #title>
            {{ t('profile.section.suggestion.title') }}
        </template>
        <template #content>
            <FakeSimilarProfilesList v-if="blurSimilarProfiles" data-testid="similar-profiles-fake-list" />
            <SimilarProfilesList v-if="!blurSimilarProfiles && hasSimilarProfiles" data-testid="similar-profiles-list" :similars="similars ?? []" />
        </template>
    </LeftSideComponent>
</template>

<script setup lang="ts">
    import {useAsyncData, useAuth, useTranslation} from '#imports';
    import {useSectionsVisibility} from '@/composables/useSectionsVisibility';
    import {useProfileStore} from '@/store/profileStore';
    import {storeToRefs} from 'pinia';
    import {computed} from 'vue';
    import LeftSideComponent from '~/components/left-side/common/LeftSideComponent.vue';
    import FakeSimilarProfilesList from '~/components/left-side/similar-profiles/ui/FakeSimilarProfilesList.vue';
    import SimilarProfilesList from '~/components/left-side/similar-profiles/ui/SimilarProfilesList.vue';
    import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';

    const {t} = useTranslation();
    const {loggedIn} = storeToRefs(useAuth());
    const {getSimilars} = useProfileStore();

    await useAsyncData('similar-profiles', async () => {
        const similars = !loggedIn.value ? [] : await getSimilars();

        return {
            similars,
        };
    });
    const {similars} = storeToRefs(useProfileStore());

    const isSimilarProfilesSectionVisible = computed(
        () => useSectionsVisibility(SectionsVisibilityType.SIMILAR_PROFILES) && (hasSimilarProfiles.value || blurSimilarProfiles.value),
    );
    const hasSimilarProfiles = computed(() => similars.value && similars.value.length > 0);
    const blurSimilarProfiles = computed(() => !loggedIn.value);
</script>

<style scoped lang="scss">
    ul {
        padding: 0;
        margin-bottom: 10px;
        li {
            margin-top: var(--joy-core-spacing-5);
            padding: 0;
            list-style-type: none;
        }
    }
</style>
