<template>
    <AsyncProfileViewBlocker v-if="isUnloggedUser" :open="showProfileViewBlocker" />
    <div ref="scrollWrapper" class="profile-id-or-alias profile-wrapper joy-wrapper">
        <div class="profile-wrapper__profile">
            <ShortlistHeader v-if="!profile?.editMode && !comesFromAdvancedSearch" :account-id="profile?.accountId" />

            <BreadcrumbSection />

            <div class="profile-wrapper__layout">
                <div class="profile-wrapper__main">
                    <div class="profile-wrapper__header">
                        <CTAForUnloggedUser class="profile-wrapper__profile-header-alerts" />

                        <div v-if="displayAlertSection" class="profile-wrapper__profile-header-alerts">
                            <AlertsSection />
                        </div>
                        <ProfileHeaderSection />
                    </div>
                    <div class="profile-wrapper__header-shadow"></div>
                    <div class="profile-wrapper__content">
                        <div class="profile-wrapper__side-menu">
                            <SideMenuSection />
                        </div>
                        <div class="profile-wrapper__main-content" style="flex: 1">
                            <MainContentSection />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ClientOnly v-if="displayLoggedSection">
            <AsyncEditionComponentsWrapper v-if="profile?.editMode" />
            <AsyncExternalUserComponentsWrapper v-else-if="loggedIn" />
            <AsyncAdminComponentsWrapper v-if="isAdmin" :ref-to-scroll-target="scrollWrapper" />
        </ClientOnly>
    </div>
</template>

<script setup lang="ts">
    import {ClientOnly} from '#components';
    import {
        callOnce,
        definePageMeta,
        use3rdParties,
        useAmplitudeSessionReplay,
        useAsyncData,
        useAuth,
        useDataTracking,
        useHasEnoughPriviledge,
        useHead,
        useHostSettings,
        useInfosRequest,
        useLocale,
        useRoute,
        useScripts,
        useSeoMeta,
        useTranslation,
        useHTMLAttrsLang,
    } from '#imports';
    import {safelyNavigateTo} from '#malt/nuxt-utils-module';
    import {useSectionsVisibility} from '@/composables/useSectionsVisibility';
    import {useFeatureContext} from '@/store/featureStore';
    import {useShortlistStore} from '@/store/shortlist/ShortlistStore';
    import {Photo} from '@malt/asset-utils';
    import {storeToRefs} from 'pinia';
    import {computed, defineAsyncComponent, onBeforeMount, onBeforeUnmount, onMounted, ref, watch} from 'vue';
    import AlertsSection from '~/components/AlertsSection.vue';
    import BreadcrumbSection from '~/components/breadcrumb/BreadcrumbSection.vue';
    import CTAForUnloggedUser from '~/components/CTAForUnloggedUser.vue';
    import ProfileHeaderSection from '~/components/header/ProfileHeaderSection.vue';
    import MainContentSection from '~/components/MainContentSection.vue';
    import ShortlistHeader from '~/components/ShortlistHeader.vue';
    import SideMenuSection from '~/components/SideMenuSection.vue';
    import {useBreadcrumbMicroData, useProfileMicroData} from '~/composables/useSeoProfileHelper';
    import {eventBus} from '~/config/events';
    import {useProfileStore} from '~/store/profileStore';
    import {useWishlistStore} from '~/store/wishlist/wishlistStore';
    import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';

    definePageMeta({
        middleware: ['profile-alias-and-other-redirect'],
        layout: 'full',
    });

    // Composables
    const {t} = useTranslation();
    const {isCompanySuspended, refresh} = useAuth();
    const profileStoreRequest = useInfosRequest();
    const route = useRoute();

    // Stores
    const {loggedIn, user} = storeToRefs(useAuth());
    const shortlistStore = useShortlistStore();
    const {getWishlists} = useWishlistStore();
    const featureContextStore = useFeatureContext();
    const profileStore = useProfileStore();
    const {displayName, completionList, webPreferences, profile} = storeToRefs(profileStore);
    const {
        getProfile,
        getAppraisals,
        getMissionPreferences,
        getRecommendations,
        getBreadcrumb,
        getPortfolio,
        getVerificationChecklist,
        getSectionsVisibility,
        getRelations,
        getWebPreferences,
        reloadExperiences,
        reloadProfileExpertises,
        reloadEducations,
        reloadCertifications,
    } = profileStore;

    // Refs
    const hasARecommendationQueryParam = ref(Object.prototype.hasOwnProperty.call(route.query, 'ra'));
    const isReadyToScrollToRecommendations = ref(false);
    const isReadyToOpenDrawerAddRecommendation = ref(false);
    const photoUrl = ref<string>('');
    const scrollWrapper = ref();
    const showProfileViewBlocker = ref<boolean>(false);

    // Computed values
    const isProfileConsultedByAnotherLoggedInUser = computed<boolean>(() => profile.value!.editMode === false && loggedIn.value === true);
    const isVisibilityAlertSectionVisible = computed(() => useSectionsVisibility(SectionsVisibilityType.VISIBILITY_ALERT_BLOCK));
    const comesFromAdvancedSearch = computed<boolean>(() => !!route?.query?.advancedSearchId);
    const displayAlertSection = computed(() => profile.value && profile.value!.editMode && isVisibilityAlertSectionVisible.value);
    const displayLoggedSection = computed((): boolean => !!loggedIn.value || !!profile.value?.editMode || isAdmin);

    const isUnloggedUser = computed(() => !loggedIn.value && featureContextStore.features['profile-view-blocker']);

    const isAdmin = await useHasEnoughPriviledge(['ROLE_ADMIN']);

    const {data: mandatoryData, error: errorFetchingMandatoryData} = await useAsyncData('profile-id-or-alias_mandatory', async () => {
        return await Promise.all([useHostSettings(), featureContextStore.loadFeatureContext(), getProfile(profileStoreRequest)]);
    });

    if (!mandatoryData.value || errorFetchingMandatoryData.value) {
        throw new Error(`Error while fetching mandatory data:\n ${errorFetchingMandatoryData.value}`, {cause: errorFetchingMandatoryData});
    }

    if (loggedIn.value && isCompanySuspended() && profile.value?.accountId !== user.value?.id) {
        safelyNavigateTo('/client-settings/restricted', {externalToThisApp: true});
    }

    const hostSettings = mandatoryData.value[0];
    const script = useScripts(hostSettings);
    useHead({script});

    // All these calls are based on profile ref
    await callOnce('profile-id-or-alias_secondary', async () => {
        await Promise.all([
            getAppraisals(),
            reloadExperiences(),
            reloadProfileExpertises(),
            reloadEducations(),
            reloadCertifications(),
            getMissionPreferences(),
            getRecommendations(),
            getBreadcrumb(),
            getVerificationChecklist(),
            getSectionsVisibility(profileStoreRequest.details.route.queryParameters),
            shortlistStore.reload(),
            profileStoreRequest.details.loggedIn ? getPortfolio() : {},
            profileStoreRequest.details.loggedIn && !profile.value!.editMode ? getWishlists(profile.value!) : {},
            profileStoreRequest.details.loggedIn && !profile.value!.editMode ? getRelations() : {},
            profile.value!.editMode ? getWebPreferences() : {},
        ]);
    });

    // Watchers
    watch([hasARecommendationQueryParam, isReadyToOpenDrawerAddRecommendation, isReadyToScrollToRecommendations], () => {
        if (hasARecommendationQueryParam.value && isReadyToOpenDrawerAddRecommendation.value && isReadyToScrollToRecommendations.value) {
            eventBus.emit('main:recommendations:scroll-to');
            eventBus.emit('external-action:recommendations:add', {experienceId: route.query.ra ? route.query.ra.toString() : ''});
        }
    });
    watch(
        () => profile.value?.photo,
        (newPhotoUrl) => {
            photoUrl.value = newPhotoUrl
                ? new Photo(newPhotoUrl).getFaceThumbnailUrl({
                      width: 330,
                      height: 325,
                      forcedFormat: 'png',
                  })
                : '';
        },
        {immediate: true},
    );

    // Amplitude session replay
    if (featureContextStore.features['activate-profile-session-replay']) {
        const {locale} = useLocale();
        useAmplitudeSessionReplay(() => locale.value !== 'fr-FR');
    }

    // Lifecycle hooks
    onBeforeMount(() => {
        if (isProfileConsultedByAnotherLoggedInUser.value && hasARecommendationQueryParam.value) {
            eventBus.on('main:recommendations:ready', handleMainRecommendationsReady);
            eventBus.on('external-action:recommendations:ready', handleExternalActionRecommendationsReady);
        }
    });
    onMounted(() => {
        if (profile.value?.editMode === true) {
            window.addEventListener('focus', handleWindowFocusInEditMode);
        }

        const searchId = route?.query?.searchid;
        const advancedSearchId = route?.query?.advancedSearchId;
        const sourcingHubCatalogId = route?.query?.catalogId;

        const isRecoProfile = !!route?.query?.reco;
        const {track} = useDataTracking();
        track('profile_viewed', {
            search_event_id: searchId,
            advanced_search_id: advancedSearchId,
            catalog_id: sourcingHubCatalogId,
            freelancer_account_id: profile.value?.accountId,
            is_reco_profile: isRecoProfile,
        });

        if (isUnloggedUser.value) {
            window.addEventListener('scroll', handleProfileViewBlocker);
        }
    });
    onBeforeUnmount(() => {
        eventBus.off('main:recommendations:ready', handleMainRecommendationsReady);
        eventBus.off('external-action:recommendations:ready', handleExternalActionRecommendationsReady);

        window.removeEventListener('focus', handleWindowFocusInEditMode);
        window.removeEventListener('scroll', handleProfileViewBlocker);
    });

    // Handlers
    async function handleWindowFocusInEditMode() {
        await refresh();
        if (loggedIn.value !== true) {
            window.location.reload();
        }
    }

    function handleMainRecommendationsReady() {
        isReadyToScrollToRecommendations.value = true;
    }

    function handleExternalActionRecommendationsReady() {
        isReadyToOpenDrawerAddRecommendation.value = true;
    }

    function handleProfileViewBlocker() {
        if (window.scrollY > window.innerHeight / 2) {
            showProfileViewBlocker.value = true;
        }
    }

    if (import.meta.server) {
        useSeoMeta({
            fbAppId: '2305272732',
            description: t('profile.static.meta', {
                0: profile.value?.firstname,
                1: displayName.value,
                2: profile.value?.headline,
            }),
            profileUsername: displayName.value,
            robots: profile.value?.privacy.indexableOnSearchEngines ? 'noimageindex' : 'noindex',
            ogType: 'profile',
            ogTitle: t('profile.static.ogtitle', {0: displayName.value, 1: profile.value?.headline}),
            ogImage: photoUrl.value || 'https://dam.malt.com/rebranding2020/malt-logo/malt-banner-logo',
            ogDescription: t('meta.property.profile.desc', {0: displayName.value}),
            ogUrl: profile.value?.profileUrl,
            twitterCard: 'summary',
            twitterImage: photoUrl.value || 'https://dam.malt.com/rebranding2020/malt-logo/malt-banner-logo',
            twitterTitle: t('profile.static.ogtitle.twitter', {0: displayName.value, 1: profile.value?.headline}),
            twitterDescription: t('profile.static.meta', {
                0: profile.value?.firstname,
                1: displayName.value,
                2: profile.value?.headline,
            }),
        });

        const linkConfig = [];
        if (profile.value?.privacy.indexableOnSearchEngines) {
            linkConfig.push({
                rel: 'canonical',
                href: `${profile.value?.profileCanonicalUrl}`,
            });
        }
        useHead({
            title: `${displayName.value}, ${profile.value?.headline}`,
            titleTemplate: (titleChunk) => titleChunk || '',
            link: linkConfig,
        });

        useHTMLAttrsLang();
        useBreadcrumbMicroData();
        useProfileMicroData();
    }

    const AsyncProfileViewBlocker = defineAsyncComponent(() => import('~/features/profile-signup-prompt/ui/ProfileViewBlocker.vue'));
    const AsyncAdminComponentsWrapper = defineAsyncComponent(() => import('@/components/wrapper/AdminComponentsWrapper.vue'));
    const AsyncEditionComponentsWrapper = defineAsyncComponent(() => import('@/components/wrapper/EditionComponentsWrapper.vue'));
    const AsyncExternalUserComponentsWrapper = defineAsyncComponent(() => import('@/components/wrapper/ExternalUserComponentsWrapper.vue'));
</script>
<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .profile-wrapper {
        --template-sidebar-width: 360px;
        --template-layout-width: 1200px;

        &__header-shadow {
            height: 10px;
            width: 100%;
            background: linear-gradient(180deg, rgba(185, 185, 185, 0.2) 0, rgba(255, 255, 255, 0) 10px);
            position: absolute;
            left: 0;
            @include mq.screen_sm {
                display: none;
            }
        }

        &__header {
            margin: 0 0 var(--joy-core-spacing-8);
            @include mq.screen_sm {
                margin: 0;
            }
        }

        &__profile-header-alerts {
            margin-bottom: var(--joy-core-spacing-8);
        }

        &__layout {
            margin: 0 auto;
            padding: var(--joy-core-spacing-8);
            display: flex;
            column-gap: var(--joy-core-spacing-8);
            max-width: var(--template-layout-width);
            @include mq.screen_sm {
                padding: var(--joy-core-spacing-4);
            }
        }

        &__side-menu {
            min-width: var(--template-sidebar-width);
            max-width: var(--template-sidebar-width);
            @include mq.screen_sm {
                max-width: unset;
            }
        }

        &__content {
            padding-top: var(--joy-core-spacing-8);
            display: flex;
            column-gap: var(--joy-core-spacing-8);
            @include mq.screen_sm {
                display: block;
            }
        }
    }

    :deep(.joy-panel__footer) {
        margin: 0 !important;
    }
</style>
