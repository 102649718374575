<template>
    <div>
        <div class="profile-headline">
            <div class="profile-headline-read" @mouseup="handleTextSelection">
                <h1 class="profile-headline-read-fullname" data-testid="profile-fullname">{{ displayName }}</h1>
                <div class="profile-headline-read-headline" data-testid="profile-headline">{{ headline }}</div>
            </div>
            <div v-if="editMode" class="profile-headline-edit">
                <VJoyButton
                    variant="ghost"
                    circle
                    class="edit-button"
                    icon="pen"
                    size="small"
                    data-testid="profile-headline-edit"
                    @click="emits('edit')"
                />
            </div>
            <slot name="wishlist" />
        </div>
        <div>
            <slot name="badges" />
        </div>
        <div>
            <slot name="counter" />
        </div>
        <div>
            <slot name="strategyProfileSearchVisibilityTag" />
        </div>
        <div v-if="editMode">
            <slot name="seeMyProfile" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import {useProfileTracking} from '~/composables/useProfileTracking';
    import {VJoyButton} from '@maltjoy/core-vue';

    const props = defineProps<{
        editMode?: boolean;
        displayName: string;
        headline?: string;
    }>();

    const emits = defineEmits<{
        (e: 'edit'): void;
    }>();

    const {compareCopiedTextWithFreelancerName} = useProfileTracking();

    function handleTextSelection() {
        compareCopiedTextWithFreelancerName(props.displayName);
    }
</script>

<style lang="scss" scoped>
    .profile-headline {
        display: flex;
        align-items: flex-start;

        &-read {
            &-fullname {
                font-family: var(--joy-font-family-title);
                font-size: var(--joy-font-size-secondary-400);
                color: var(--joy-color-neutral-50);
            }

            &-headline {
                display: inline-flex;
                font-family: var(--joy-font-family);
                font-size: var(--joy-font-size-primary-600);
                font-weight: var(--joy-font-weight-bold);
                color: var(--joy-color-neutral-50);

                margin-bottom: 10px;
            }
        }

        &-edit {
            margin-left: var(--joy-core-spacing-4);
        }
    }
</style>
