<template>
    <li
        :key="certification.index"
        :value="certification.index"
        class="profile-certifications__list-item"
        :class="{'draggable-certification': editMode, draggable: editMode, sample: isSample}"
    >
        <div class="profile-certifications__list-item-wrapper">
            <VJoyIcon size="large" name="file-check" />
            <div class="profile-certifications__list-item__main-content">
                <div class="profile-certifications__list-item__main-content-title">{{ certification.title }}</div>
                <div class="profile-certifications__list-item__main-content-subtitle">{{ certification.organization }}</div>
                <div v-if="certification.graduationYear" class="profile-certifications__list-item__main-content-subtitle-calendar">
                    <VJoyIcon name="calendar" color="neutral" />
                    <small>{{ certification.graduationYear }}</small>
                </div>
                <div v-if="certification.link" class="profile-certifications__list-item__main-content-text">
                    <VJoyLink :href="certificationLinkSanitized">{{ certification.link }}</VJoyLink>
                </div>

                <VJoyTagsList class="joy-mt-4" :tags="skills" />
            </div>
            <div v-if="!isSample && editMode" class="action-edit">
                <div class="toolbar">
                    <VJoyWrapper column-gap="0" justify="space-between" wrap="nowrap" :item-stretch="true">
                        <VJoyButton circle variant="ghost" size="small" icon="pen" @click="openEdit(certification.index)" />
                        <VJoyDialogTrigger :dialog="removeDialogRef" @click="emitRemoval(certification.id!)">
                            <VJoyButton v-if="editMode" circle variant="ghost" size="small" icon="trash" class="toolbar-remove" />
                        </VJoyDialogTrigger>

                        <VJoyButton circle variant="ghost" size="small" icon="bars" class="draggable" />
                    </VJoyWrapper>
                </div>
            </div>
        </div>
    </li>
</template>

<script setup lang="ts">
    import {isValidUrl} from '@malt/common';
    import {computed, toRef} from 'vue';
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    import type {TJoyTagsList, VJoyDialog} from '@maltjoy/core-vue';
    import {VJoyButton, VJoyDialogTrigger, VJoyIcon, VJoyLink, VJoyTagsList, VJoyWrapper} from '@maltjoy/core-vue';
    import type {CertificationRequest} from '~/types/infra/CertificationRequest';

    type Props = {
        modelValue: CertificationRequest;
        isSample: boolean;
        isEditMode: boolean;
        removeDialogRef?: InstanceType<typeof VJoyDialog> | null;
    };

    const props = defineProps<Props>();
    type Emits = {
        (e: 'remove', certificationToRemoveId: string): void;
        (e: 'edit', index: number): void;
    };
    const emit = defineEmits<Emits>();

    // Refs
    const certification = toRef(props, 'modelValue');
    const editMode = toRef(props, 'isEditMode');

    // Computed
    const certificationLinkSanitized = computed(() => (isValidUrl(certification.value?.link) ? certification.value?.link : ''));
    const skills = computed(
        (): TJoyTagsList => certification.value.skills.map((skill, index) => ({value: `certification-skill--${index}`, label: skill.label})),
    );

    function emitRemoval(certificationToRemoveId: string) {
        emit('remove', certificationToRemoveId);
    }

    function openEdit(index: number) {
        emit('edit', index);
    }
</script>

<style lang="scss" scoped>
    .sample {
        opacity: 0.3;
    }

    .draggable {
        cursor: grab;
        margin-left: auto;
    }

    .toolbar-remove :deep(.joy-icon),
    .toolbar-remove:hover :deep(.joy-icon) {
        color: var(--joy-color-error-50) !important; // I know
    }

    .action-edit {
        position: relative;
        flex: 1 0 115px;

        @media screen and (max-width: 600px) {
            margin-left: unset;
        }
    }

    @media screen and (min-width: 767px) {
        .toolbar {
            display: none;
        }

        .profile-certifications__list-item:hover {
            .toolbar {
                display: block;
            }
        }
    }

    .profile-certifications__list-item {
        position: relative;
        padding: 20px 0;

        &:not(:last-child) {
            border-bottom: 1px solid var(--joy-color-neutral-30);
        }

        &-wrapper {
            display: flex;
            width: 100%;

            @media screen and (max-width: 600px) {
                flex-direction: column-reverse;
            }
        }

        &__main-content {
            margin-left: var(--joy-core-spacing-2);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: var(--joy-core-spacing-4);
            width: 80%;

            &-title {
                margin-bottom: var(--joy-core-spacing-1);
                max-width: 100%;
                margin-right: var(--joy-core-spacing-5);
                font-family: var(--joy-font-family);
                font-weight: var(--joy-font-weight-bold);
                font-size: var(--joy-font-size-primary-600);
                line-height: var(--joy-line-height-large);
            }

            &-subtitle {
                font-size: var(--joy-font-size-primary-300);
                margin-bottom: var(--joy-core-spacing-1);
                color: var(--joy-color-text-body);
                display: flex;

                &-calendar {
                    display: flex;
                    color: var(--joy-color-neutral-40);
                    small {
                        margin-left: var(--joy-core-spacing-2);
                    }
                }
            }

            &-draggable {
                text-align: right;
                position: absolute;
                max-width: calc(40% - 20px);
                top: 0;
                right: 0;
            }

            &-text {
                word-break: break-word;
                margin-top: var(--joy-core-spacing-3);
            }
        }
    }
</style>
