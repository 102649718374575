<template>
    <div class="profile-photo_wrapper">
        <VJoyAvatar
            size="large"
            :class="{editable: editMode, 'profile-photo__avatar': true}"
            :photo-url="photoUrl"
            :full-name="alt"
            @click="emits('edit')"
        />
        <MaltImg v-if="isStrategy" class="profile-photo_overlay" src="/malt-logos/malt_strategy_banner-circle" alt="" width="220" height="220" />
    </div>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {computed} from 'vue';
    import {useStrategyProfile} from '@/composables/useStrategyProfile';
    import {VJoyAvatar} from '@maltjoy/core-vue';

    const isStrategy = computed(() => useStrategyProfile());

    const emits = defineEmits<{
        (e: 'edit'): void;
    }>();

    defineProps<{
        photoUrl?: string;
        editMode?: boolean;
        alt: string;
    }>();
</script>

<style scoped lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .profile-photo {
        &_overlay {
            position: relative;
            top: -220px;
            left: -1px;
        }

        &_wrapper {
            width: 220px;
            height: 220px;
        }

        &__avatar {
            &.joy-avatar__wrapper :deep(.joy-avatar) {
                position: relative;
                top: 0;
                left: 0;
                --avatar-size: 220px;
            }
        }
    }

    @include mq.screen_md {
        joy-avatar {
            --avatar-large-size: 150px;
        }

        .profile-photo {
            &_overlay {
                left: -1px;
            }
        }
    }

    @include mq.screen_xs {
        .profile-photo_wrapper {
            display: none;
        }
    }
</style>
