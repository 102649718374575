<template>
    <div class="profile-wrapper__profile-main-content">
        <ProfileRelationsSection v-if="hasToDisplayProfileRelations" />
        <ClientOnly>
            <TaskCards v-if="profile?.editMode" :firstname="profile.firstname" />
        </ClientOnly>
        <SkillSetSection />
        <IndustryExpertiseSection v-if="hasToDisplayIndustryExpertises" />
        <DescriptionSection v-if="hasToDisplayDescription" />
        <PortfolioSection v-if="hasToDisplayPortfolio" />
        <ExperiencesSection v-if="hasToDisplayExperiences" />
        <AppraisalsSection v-if="hasToDisplayAppraisals" />
        <RecommendationsSection />
        <EducationsSection v-if="hasToDisplayEducations" />
        <CertificationsSection v-if="hasToDisplayCertifications" />
    </div>
</template>

<script setup lang="ts">
    import {computed} from '#imports';
    import TaskCards from '@/components/task-cards/TaskCards.vue';
    import {useSectionsVisibility} from '@/composables/useSectionsVisibility';
    import {storeToRefs} from 'pinia';
    import DescriptionSection from '~/components/main/DescriptionSection.vue';
    import EducationsSection from '~/components/main/EducationsSection.vue';
    import ExperiencesSection from '~/components/main/ExperiencesSection.vue';
    import IndustryExpertiseSection from '~/components/main/IndustryExpertiseSection.vue';
    import PortfolioSection from '~/components/main/PortfolioSection.vue';
    import ProfileRelationsSection from '~/components/main/ProfileRelationsSection.vue';
    import SkillSetSection from '~/components/main/SkillSetSection.vue';
    import AppraisalsSection from '~/components/main/appraisals/AppraisalsSection.vue';
    import CertificationsSection from '~/components/main/certifications/CertificationsSection.vue';
    import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';
    import {useProfileStore} from '~/store/profileStore';
    import RecommendationsSection from '~~/src/components/main/recommendations/RecommendationsSection.vue';

    const {portfolio, profile, appraisals, experiences, educations, certifications, profileExpertises, relations} = storeToRefs(useProfileStore());

    const isEditMode = computed(() => profile.value?.editMode);

    const hasIndustryExpertises = computed(() => !!(profileExpertises.value && profileExpertises.value.industryExpertises.length > 0));
    const isIndustryExpertiseVisible = await useSectionsVisibility(SectionsVisibilityType.INDUSTRY_EXPERTISE);
    const hasToDisplayIndustryExpertises = computed(() => isIndustryExpertiseVisible && (isEditMode.value || hasIndustryExpertises.value));

    const hasToDisplayPortfolio = computed(() => isEditMode.value || (portfolio.value && portfolio.value.items.length > 0));
    const hasToDisplayDescription = computed(() => isEditMode.value || (profile.value?.description && profile.value?.description.length > 0));
    const hasToDisplayExperiences = computed(() => isEditMode.value || (experiences.value && experiences.value.items.length > 0));
    const hasToDisplayEducations = computed(() => isEditMode.value || (educations.value && educations.value.items.length > 0));
    const hasToDisplayCertifications = computed(() => isEditMode.value || (certifications.value && certifications.value.items.length > 0));
    const hasToDisplayAppraisals = computed(() => isEditMode.value || (appraisals.value && appraisals.value.appraisals.length > 0));
    const hasToDisplayProfileRelations = computed(() => relations.value);
</script>
