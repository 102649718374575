import {useUniversalDelete, useUniversalFetch, useUniversalPost} from '#imports';
import {UnauthorizedResponseHelper} from '@/helpers/unauthorized-response/UnauthorizedResponseHelper';
import {urlService} from 'vue3-starter/dist/http/UrlService';
import type {Profile} from '~/types/domain/Profile';
import {extractLink} from '~/helpers/api/ExtractLinkHelper';
import type {ExternalReputationResource} from '~/types/api-raw-types';

async function getReputation(profile: Profile): Promise<ExternalReputationResource[]> {
    const url = extractLink(profile.links, 'reputationWidgets');

    try {
        const result = await useUniversalFetch<ExternalReputationResource[]>(url);

        return result;
    } catch (error: any) {
        if (UnauthorizedResponseHelper.isRejectionCommingFromEdgePlatform(error)) {
            return [];
        }

        throw error;
    }
}

async function getAllWidgetsAndReputation(profile: Profile): Promise<ExternalReputationResource[]> {
    const url = extractLink(profile.links, 'allReputationWidgetsWithAssociatedOnes');
    return await useUniversalFetch<ExternalReputationResource[]>(url);
}

async function saveReputation(reputation: ExternalReputationResource, userId: string): Promise<void> {
    const templatedUrl = extractLink(reputation._links, 'associateExternalReputationWidget');
    const associationUrl = `${urlService.buildUrl(templatedUrl, userId)}`;

    return await useUniversalPost(associationUrl);
}

async function deleteReputation(reputation: ExternalReputationResource): Promise<void> {
    const url = extractLink(reputation._links, 'removeExternalReputationWidgetAssociation');
    return await useUniversalDelete<void>(url);
}

export const reputationRepository = {deleteReputation, getReputation, getAllWidgetsAndReputation, saveReputation};
