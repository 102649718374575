<template>
    <div v-if="isEditMode || !appraisal.hidden" class="profile-appraisal">
        <article class="profile-appraisal__item" :class="{'profile-appraisal__item-sample': isSample}">
            <VJoyAvatar
                v-if="appraisal.clientImageUrl"
                :full-name="appraisal.clientName"
                :photo-url="`${appraisal.clientImageUrl}?gravity=face&amp;func=crop&amp;w=80&amp;h=80&amp;force_format=png`"
                size="medium"
                color="secondary"
            />

            <VJoyAvatar v-else :full-name="appraisal.clientName" size="medium" color="secondary" />

            <div class="profile-appraisal__item-content">
                <div class="profile-appraisal__item-content-wrapper">
                    <div class="profile-appraisal__item-content-part" :class="{invisible: appraisal.hidden}">
                        <h3 class="profile-appraisal__item-content-part-title">
                            <span class="profile-appraisal__item-content-part-title-name">{{ appraisal.clientName }}</span>
                            <span>-</span>
                            <span>{{ appraisal.clientCompanyName }}</span>

                            <VJoyButton
                                v-if="isEditMode"
                                class="profile-appraisal__item-content-part-action"
                                size="xsmall"
                                variant="secondary"
                                @click="toggleHidden(appraisal)"
                            >
                                {{ showOrHideText }}
                            </VJoyButton>
                        </h3>
                        <p>{{ creationDate }}</p>
                    </div>

                    <small v-if="appraisal.hidden && !isEditMode" class="profile-appraisal__item-content-label">
                        {{ t('profile.section.mission.appraisal.hidden.label') }}
                    </small>
                </div>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p class="profile-appraisal__item-content-text" :class="{invisible: appraisal.hidden}" v-html="commentContent" />
            </div>
        </article>
    </div>
</template>

<script setup lang="ts">
    import {computed, toRef, useDateFormatter, useLocale, useMarkdownToHtml, useTranslation} from '#imports';
    import {useProfileStore} from '~/store/profileStore';
    import type {Appraisal} from '~/types/domain/Appraisals';
    import {VJoyAvatar, VJoyButton} from '@maltjoy/core-vue';
    import {nl2br} from '@malt/common';
    import {escapeHtml} from '@malt/sanitize';

    type Props = {
        modelValue: Appraisal;
        isSample: boolean;
        isEditMode: boolean;
    };
    const props = defineProps<Props>();

    // Composables
    const {t} = useTranslation();
    const {locale} = useLocale();

    // Store
    const {updateAppraisalVisibility} = useProfileStore();

    // Refs
    const appraisal = toRef(props, 'modelValue');

    // Computed values
    const creationDate = computed(() =>
        useDateFormatter(appraisal.value.creationDate, locale.value, {year: 'numeric', month: 'numeric', day: 'numeric'}),
    );
    const showOrHideText = computed(() => {
        return appraisal.value.hidden ? t('profile.section.mission.appraisal.show') : t('profile.section.mission.appraisal.hide');
    });
    const commentContent = computed(() => (appraisal.value?.comment ? nl2br(escapeHtml(appraisal.value.comment)) : ''));

    async function toggleHidden(appraisal: Appraisal) {
        if (props.isEditMode && !props.isSample) {
            await updateAppraisalVisibility(appraisal);
        }
    }
</script>

<style lang="scss" scoped>
    .invisible {
        opacity: 0.5;
    }

    .profile-appraisal {
        margin-bottom: var(--joy-core-spacing-4);

        &__item {
            display: flex;
            padding: 20px 15px;

            @media screen and (max-width: 767px) {
                flex-direction: column;
            }

            &-sample {
                background-color: var(--joy-color-secondary-10);
                border-radius: var(--joy-core-radius-2);
            }

            &-content {
                flex: 1;
                display: flex;
                flex-direction: column;
                margin-left: 10px;

                &-wrapper {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: var(--joy-core-spacing-2);
                }

                &-text {
                    line-break: auto;
                    word-break: break-word;
                    text-align: justify;
                }

                &-part {
                    width: 100%;
                    &-title {
                        font-size: var(--joy-font-size-primary-300);
                        margin: 0;
                        display: flex;
                        align-items: baseline;

                        @media screen and (max-width: 767px) {
                            flex-direction: column;
                        }

                        & > * {
                            margin-right: var(--joy-core-spacing-1);
                        }

                        &-name {
                            color: var(--joy-color-secondary-50);
                            font-family: var(--joy-font-family);
                            font-weight: var(--joy-font-weight-bold);
                            font-size: var(--joy-font-size-primary-600);
                            line-height: var(--joy-line-height-large);
                        }
                    }

                    &-action {
                        margin-left: auto;

                        @media screen and (max-width: 767px) {
                            margin-left: unset;
                        }
                    }
                }

                &-label {
                    top: 0px;
                    right: 0px;
                    text-transform: uppercase;
                    font-weight: 700;
                    color: var(--joy-color-secondary-50);
                }
            }
        }
    }
</style>
