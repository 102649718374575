<template>
    <div class="profile-reputation-widgets__widget-logo">
        <MaltImg
            :src="`/hop_${reputation.providerName}_logo`"
            width="32"
            loading="lazy"
            height="32"
            :alt="getImageText(reputation)"
            :title="getImageText(reputation)"
        />
    </div>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import type {ExternalReputationResource} from '~/types/api-raw-types';
    import {useTranslation} from '#imports';

    const {t} = useTranslation();
    type Props = {
        reputation: ExternalReputationResource;
    };
    defineProps<Props>();

    function getImageText(reputation: ExternalReputationResource): string {
        return `${t(`profile.reputation.provider.${reputation.providerName}`)} : ${reputation.externalUsername}`;
    }
</script>
