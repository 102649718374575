<template>
    <LeftSideComponent v-if="profile?.editMode || hasReputation" data-testid="profile-reputation">
        <template #title>{{ t('profile.section.reputation.title') }}</template>
        <template v-if="profile?.editMode" #title-action>
            <VJoyButton icon="pen" circle variant="ghost" size="small" @click="editReputationWidgets" />
        </template>
        <template #content>
            <FetchErrorHighlight v-if="error">
                <template #technical-error>{{ error }}</template>
            </FetchErrorHighlight>
            <template v-else>
                <div v-if="hasReputation" class="profile-reputation-widgets">
                    <div v-for="reputation in reputations" :key="reputation.providerName" class="profile-reputation-widgets__widget">
                        <a :href="reputation.externalUserPageUrl" :target="getTarget(reputation)" rel="nofollow">
                            <div class="profile-reputation-widgets__widget-logo">
                                <ReputationLogo :reputation="reputation" />
                            </div>
                        </a>
                        <ReputationStat :reputation="reputation" />
                    </div>
                </div>
                <div v-else class="profile-reputation-widgets">
                    <div class="profile-reputation-widgets__subtitle">{{ t('profile.reputation.widgets.empty.subtitle') }}</div>
                </div>
            </template>
        </template>
    </LeftSideComponent>
</template>

<script setup lang="ts">
    import {computed, useAsyncData, useTranslation} from '#imports';
    import FetchErrorHighlight from '@/components/_common/FetchErrorHighlight.vue';
    import {VJoyButton} from '@maltjoy/core-vue';
    import {storeToRefs} from 'pinia';
    import LeftSideComponent from '~/components/left-side/common/LeftSideComponent.vue';
    import ReputationLogo from '~/components/left-side/reputation-widgets/ReputationLogo.vue';
    import ReputationStat from '~/components/left-side/reputation-widgets/ReputationStat.vue';
    import {eventBus} from '~/config/events';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';
    import {useProfileStore} from '~/store/profileStore';
    import {useReputationStore} from '~/store/reputation/reputationStore';
    import type {ExternalReputationResource} from '~/types/api-raw-types';

    const {t} = useTranslation();
    const {profile} = storeToRefs(useProfileStore());
    const hasReputation = computed(() => reputations.value.length > 0);
    const {reputations} = storeToRefs(useReputationStore());
    const {getReputations} = useReputationStore();

    function getTarget(reputation: ExternalReputationResource): string {
        return reputation.openLinkInCurrentTab ? '_self' : '_blank';
    }

    function editReputationWidgets() {
        eventBus.emit('reputation:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
    }

    const {error} = await useAsyncData('left_reputation-widget', async () => {
        const reputations = await getReputations();
        return {reputations};
    });
</script>

<style lang="scss" scoped>
    .line-breaker {
        padding: 0 !important;
        flex-basis: 100% !important;
    }

    .profile-reputation-widgets {
        display: flex;
        flex-direction: column;

        &__widget {
            display: flex;
            padding: 20px 0;

            &:is(:first-child) {
                padding-top: 0;
            }

            &:is(:last-child) {
                padding-bottom: 0;
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--joy-color-neutral-30);
            }
        }
    }
</style>
