<template>
    <VJoyWrapper class="profile-relations-indicator" no-margin>
        <MaltImg v-if="relations.logo" :src="companyLogoUrl" :alt="companyName" width="32" height="32" />
        <div>
            {{ displayIndicator(relations.relations[0]) }}
        </div>
    </VJoyWrapper>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {computed} from 'vue';
    import type {ProfileRelationResource, ProfileRelationsResource} from '~/types/infra/repository/ProfileRepositoryAPI';
    import {useTranslation} from '#imports';
    import {VJoyWrapper} from '@maltjoy/core-vue';

    type Props = {
        relations: ProfileRelationsResource;
        freelancerFirstname: string;
    };
    const props = defineProps<Props>();
    const {t} = useTranslation();

    const companyLogoUrl = computed(() => computeCompanyLogoUrl(props.relations.logo.baseUrl, props.relations.logo.name));
    const companyName = computed(() => props.relations.name);
    const experienceRelatedRelation = ['ONGOING_MISSION', 'COMPLETED_MISSION', 'ONGOING_EXPERIENCE', 'PAST_EXPERIENCE'];

    function computeCompanyLogoUrl(baseUrl: string, logoName: string): string {
        return `${baseUrl}/${logoName}`;
    }

    function displayIndicator(relation: ProfileRelationResource): string {
        if (experienceRelatedRelation.includes(relation.type)) {
            return `${t('profile.section.pof.relation.categorized.old_experience', {0: props.freelancerFirstname})}`;
        }

        return `${t('profile.section.pof.relation.categorized.favorite', {0: props.freelancerFirstname})}`;
    }
</script>

<style lang="scss" scoped>
    .profile-relations-indicator {
        flex-wrap: nowrap;
    }
</style>
