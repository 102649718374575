<template>
    <VJoyDrawer v-if="activated" :id="dialogId" ref="drawer" :size="size" :cancel-text="t('general.cancel')" @joy-cancel-drawer="closeDialog">
        <template #drawer-header><slot name="edit-header" /></template>

        <template #drawer-body>
            <slot name="edit-body" />
        </template>

        <template #drawer-confirm>
            <VJoyButton
                variant="main"
                :data-testid="confirmDataTestid"
                :disabled="!confirmButtonEnabled"
                :label="t('general.save')"
                @click="confirm"
            />
        </template>
    </VJoyDrawer>
</template>

<script setup lang="ts">
    import {ref, useTranslation} from '#imports';
    import type {TJoyDrawerSizes} from '@maltjoy/core-vue';
    import {VJoyButton, VJoyDrawer} from '@maltjoy/core-vue';
    import {useRoute} from '@typed-router';
    import {onMounted, onUnmounted, watchEffect} from 'vue';
    import type {Events} from '~/config/events';
    import {eventBus} from '~/config/events';

    type EmitEvents = {
        (e: 'close'): void;
        (e: 'confirm'): void;
    };
    const emit = defineEmits<EmitEvents>();
    type Props = {
        dialogId: string;
        openDialogEvent: string;
        updatedEvent?: string;
        openDialogPostFunction?: Function;
        confirmFunction?: Function;
        confirmEnabled?: boolean;
        confirmDataTestid?: string;
        emitCloseFunction?: Function;
        size?: TJoyDrawerSizes;
    };

    const route = useRoute();
    const {t} = useTranslation();
    const props = withDefaults(defineProps<Props>(), {
        confirmEnabled: true,
        openDialogPostFunction: undefined,
        confirmFunction: undefined,
        confirmDataTestid: undefined,
        emitCloseFunction: undefined,
        size: 'large',
    });

    const drawer = ref<InstanceType<typeof VJoyDrawer> | null>(null);
    const activated = ref(false);
    const confirmButtonEnabled = ref(props.confirmEnabled);

    watchEffect(() => (confirmButtonEnabled.value = props.confirmEnabled));

    function closeDialog() {
        if (props.emitCloseFunction) {
            props.emitCloseFunction();
        }
        emit('close');
        activated.value = false;
    }

    function confirm() {
        if (props.confirmFunction) {
            props.confirmFunction();
        }
        if (props.updatedEvent) {
            eventBus.emit(props.updatedEvent as keyof Events);
        }
        emit('confirm');
        drawer.value?.hide();
        activated.value = false;
    }

    function openDrawer(params: any) {
        activated.value = true;
        setTimeout(() => {
            drawer.value?.show();
        }, 100);
        if (props.openDialogPostFunction) {
            props.openDialogPostFunction(params);
        }
    }

    onMounted(() => {
        if (route.query.drawerOpen === 'true' && props.dialogId === 'externalUserActionRecommendationsDialog') {
            openDrawer(undefined);
        }

        eventBus.on(props.openDialogEvent as keyof Events, openDrawer);
    });

    onUnmounted(() => {
        eventBus.off(props.openDialogEvent as keyof Events, openDrawer);
    });
</script>
