<template>
    <div>
        <MaltStartPushPanel v-if="features['malt-start-links']" :link="link" />
        <MaltDraftPushPanel v-else :link="link" />
    </div>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {useFeatureContext} from '~/store/featureStore';
    import MaltStartPushPanel from '~/components/main/appraisals/MaltStartPushPanel.vue';
    import MaltDraftPushPanel from '~/components/main/appraisals/MaltDraftPushPanel.vue';
    import {useHostSettings} from '#imports';

    const {features} = useFeatureContext();
    const hostSettings = await useHostSettings();

    const link = computed(() => {
        switch (hostSettings?.country.toLowerCase()) {
            case 'de':
                return 'https://help.malt.com/kb/guide/de/wie-lade-ich-meinen-kunden-zu-malt-ein-ehNgV1UFzJ/Steps/2515029';
            case 'fr':
                return 'https://help.malt.com/kb/guide/fr/comment-inviter-mon-client-sur-malt-ehNgV1UFzJ/Steps/2515029';
            case 'nl':
                return 'https://help.malt.com/kb/guide/nl/hoe-nodig-ik-mijn-klant-uit-voor-malt-ehNgV1UFzJ/Steps/2515029';
            case 'es':
                return 'https://help.malt.com/kb/guide/es/como-invito-a-mi-cliente-a-malt-ehNgV1UFzJ/Steps/2515029';
            default:
                return 'https://help.malt.com/kb/guide/en/how-do-i-invite-my-client-to-malt-ehNgV1UFzJ/Steps/2515029';
        }
    });
</script>
