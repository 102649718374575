<template>
    <div v-if="displayExternalUserBanner" class="c-news-panel c-news-panel__header c-news-panel_warning">
        <div class="c-news-panel__inner">
            <div class="c-news-panel__left">
                <MaltImg
                    width="140"
                    height="143"
                    quality="100"
                    loading="lazy"
                    src="https://dam.malt.com/dashboard/dashboard-client/welcome-modal?"
                    alt="Malt welcome"
                />
            </div>
            <div class="c-news-panel__content">
                <p class="c-news-panel__title">
                    {{ $t('profile.banner.external.user.title', {0: profile?.firstname}) }}
                </p>
                <p class="c-news-panel__message">
                    {{ $t('profile.banner.external.user.message') }}
                    {{ $t('profile.banner.external.user.contact', {0: profile?.firstname}) }}
                    <VJoyLink href="/">
                        {{ $t('profile.banner.external.user.link') }}
                    </VJoyLink>
                </p>
            </div>
            <div class="c-news-panel__close">
                <VJoyButton icon="cross" variant="ghost" circle @click="closeBanner"></VJoyButton>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {MaltImg} from '#components';
    import {useProfileStore} from '~/store/profileStore';
    import {storeToRefs} from 'pinia';
    import {useExternalUserBanner} from '~/composables/useExternalUserBanner';
    import {VJoyLink, VJoyButton} from '@maltjoy/core-vue';

    const profileStore = await useProfileStore();
    const {profile} = storeToRefs(profileStore);

    const {clickedOnBanner, displayExternalUserBanner, externalUserBannerCookie} = useExternalUserBanner();

    function closeBanner() {
        externalUserBannerCookie.value = 'true';
        clickedOnBanner.value = true;
    }
</script>
<style lang="scss" scoped>
    .profile-alerts__title {
        font-size: var(--joy-font-size-primary-600);
        font-weight: 700;
    }
</style>
