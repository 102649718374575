<template>
    <div class="profile-counters">
        <VJoyWrapper class="profile-counters__wrapper" :no-margin="true">
            <div v-if="hasAppraisal" class="profile-counters__existing" data-testid="profile-counter-missions">
                <div class="profile-counters__existing-missions" v-html="$t('profile.mission.count', {nbMissions: missionCount})"></div>
            </div>
            <VJoyRatingStars
                v-if="isRatingVisible"
                class="profile-counters__ratings"
                :review-count="numberOfAppraisalsWithRating"
                :rating-value="generalRating"
                @click="emits('edit-appraisals')"
            />
        </VJoyWrapper>
        <div v-if="isProfileHeaderRecommendationVisible" class="profile-counters__askreco" @click="emits('edit-recommendations')">
            <VJoyIcon name="thumbup" class="profile-counters__askreco-icon" />
            <span v-if="hasRecommendations" class="profile-counters__existing-recommendations" data-testid="profile-counter-recommendations">
                {{ $t('profile.section.recommendation.count', {nbRecos: recommendationsCount}) }}
            </span>
            <span v-else-if="editMode" data-testid="ask-for-recommendations" class="profile-counters__askreco-label">
                {{ $t('profile.section.header.recommendations.ask.label') }}
            </span>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {useSectionsVisibility} from '@/composables/useSectionsVisibility';
    import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';
    import {VJoyIcon, VJoyRatingStars, VJoyWrapper} from '@maltjoy/core-vue';

    const props = defineProps<{
        recommendationsCount?: number;
        numberOfAppraisalsWithRating?: number;
        generalRating?: number;
        missionCount?: number;
        editMode?: boolean;
    }>();

    const emits = defineEmits<{
        (e: 'edit-recommendations'): void;
        (e: 'edit-appraisals'): void;
    }>();

    const hasAppraisal = computed((): boolean => (props.missionCount && props.missionCount > 0) || false);
    const isRatingVisible = computed((): boolean => (hasAppraisal.value && props.generalRating && props.generalRating > 2) || false);
    const hasRecommendations = computed((): boolean => (props.recommendationsCount && props.recommendationsCount > 0) || false);

    const hasProfileHeaderRecommendationVisibility = computed(() => useSectionsVisibility(SectionsVisibilityType.RECOMMENDATIONS));

    const isProfileHeaderRecommendationVisible = computed(
        () => (hasRecommendations.value || props.editMode) && hasProfileHeaderRecommendationVisibility.value,
    );
</script>

<style lang="scss" scoped>
    .profile-counters {
        color: var(--joy-color-neutral-50);

        &__existing {
            &-missions {
                font-weight: var(--joy-font-weight-bold);

                margin-right: var(--joy-core-spacing-2);
            }

            &-recommendations {
                font-weight: var(--joy-font-weight-normal);
            }
        }

        &__ratings {
            cursor: pointer;
        }

        &__askreco {
            font-weight: var(--joy-font-weight-bold);
            margin-top: var(--joy-core-spacing-2);
            cursor: pointer;

            display: inline-flex;
            align-items: center;

            &-icon {
                margin-right: 4px;
            }
        }
    }
</style>
