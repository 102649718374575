<template>
    <li
        :key="education.id"
        :value="education.id"
        class="profile-educations__list-item"
        data-testid="profile-education-item"
        :class="{'draggable-education': editMode, draggable: editMode, sample: isSample}"
    >
        <div class="profile-educations__list-item-wrapper">
            <div v-if="!isSample && editMode" class="action-edit">
                <div class="toolbar">
                    <VJoyWrapper column-gap="0" justify="space-between" wrap="nowrap" :item-stretch="true">
                        <VJoyButton circle variant="ghost" size="small" icon="pen" @click="openEdit(education.id)" />
                        <VJoyDialogTrigger :dialog="removeDialogRef" @click="emitRemoval(education.id)">
                            <VJoyButton v-if="editMode" circle variant="ghost" size="small" icon="trash" class="toolbar-remove" />
                        </VJoyDialogTrigger>

                        <VJoyButton circle variant="ghost" size="small" icon="bars" class="draggable" />
                    </VJoyWrapper>
                </div>
            </div>
            <EducationReadOnly :education="educationItem"></EducationReadOnly>
        </div>
    </li>
</template>

<script setup lang="ts">
    import {computed, toRef} from '#imports';
    import type {EducationRequest} from '~/types/infra/EducationRequest';
    import EducationReadOnly from '~/components/_common/education/EducationReadOnly.vue';
    import type {VJoyDialog} from '@maltjoy/core-vue';
    import {VJoyButton, VJoyDialogTrigger, VJoyWrapper} from '@maltjoy/core-vue';

    type Props = {
        modelValue: EducationRequest;
        isSample: boolean;
        isEditMode: boolean;
        removeDialogRef?: InstanceType<typeof VJoyDialog> | null;
    };

    const props = defineProps<Props>();
    type Emits = {
        (e: 'remove', id: number): void;
        (e: 'edit', id: number): void;
    };
    const emit = defineEmits<Emits>();
    const education = toRef(props, 'modelValue');
    const editMode = toRef(props, 'isEditMode');
    const educationItem = computed(() => {
        return {
            description: education.value.description,
            title: education.value.title,
            graduationYear: education.value.graduationYear,
            content: education.value.content,
        };
    });

    function emitRemoval(id: number) {
        emit('remove', id);
    }

    function openEdit(id: number) {
        emit('edit', id);
    }
</script>

<style lang="scss" scoped>
    .sample {
        opacity: 0.3;
    }

    .draggable {
        cursor: grab;
    }

    .toolbar-remove :deep(.joy-icon),
    .toolbar-remove:hover :deep(.joy-icon) {
        color: var(--joy-color-error-50) !important; // I know
    }

    .action-edit {
        position: absolute;
        top: var(--joy-core-spacing-4);
        right: 0;

        @media screen and (max-width: 600px) {
            margin-left: unset;
            position: relative;
            width: 100%;

            .toolbar {
                display: block;
            }

            .joy-wrapper {
                justify-content: space-between;

                > * {
                    flex: 0 0 auto;
                }
            }
        }
    }

    @media screen and (min-width: 767px) {
        .toolbar {
            display: none;
        }

        .profile-educations__list-item:hover {
            .toolbar {
                display: block;
            }
        }
    }

    .profile-educations__list-item {
        position: relative;
        padding: 20px 0;

        &:not(:last-child) {
            border-bottom: 1px solid var(--joy-color-neutral-30);
        }

        &-wrapper {
            display: flex;
            width: 100%;
        }

        @media screen and (max-width: 600px) {
            &-wrapper {
                display: block;
            }
        }
    }
</style>
