<template>
    <li v-if="isVisible" data-testid="answer-rate-indicator" class="profile-indicators-item">
        <span class="profile-indicators-title">{{ $t('profile.numeral.infos.answer.rate.label') }}</span>
        <span class="profile-indicators-content">
            {{ $t('profile.numeral.infos.answer.rate.value', [answerRate]) }}
        </span>
    </li>
</template>

<script setup lang="ts">
    import {computed} from 'vue';

    const props = defineProps<{
        answerRate?: number;
    }>();

    const isVisible = computed(() => props.answerRate);
</script>
