import {computed, ref, inject} from 'vue';
import {useUniversalPost} from '#imports';
import {defineStore} from 'pinia';
import {useProfileStore} from '../profileStore';
import type {TaskCard} from '~/types/domain/TaskCards';
import {TaskCardType} from '~/types/domain/TaskCards';
import {RegisteredRepositories} from '@/config/application.services';

export const useTaskCardsStore = defineStore('task-cards', () => {
    const profileRepository = inject(RegisteredRepositories.injectionKeys.profile)!;

    const {profile, resendAccountValidationEmail} = useProfileStore();

    const connectedUser = ref(null);
    const currentDisplayedPosition = ref(0);
    const taskCards = ref<TaskCard[]>([]);

    const currentTaskCard = computed(() => taskCards.value[currentDisplayedPosition.value]);
    const isExperienceCard = computed((): boolean | undefined => currentTaskCard.value?.type === TaskCardType.EXPERIENCE);
    const taskCardsSize = computed((): number => taskCards.value.length);

    async function getTaskCards() {
        const result = await profileRepository.fetchTaskCards(profile!);
        if (result.tasks) {
            taskCards.value = result.tasks;
        }
    }

    function displayNextTaskCard() {
        if (taskCards.value.length === 1) {
            currentDisplayedPosition.value = 0;
            return;
        }
        if (currentDisplayedPosition.value === taskCards.value.length - 1) {
            currentDisplayedPosition.value = 0;
            return;
        }
        currentDisplayedPosition.value = currentDisplayedPosition.value + 1;
    }

    function displayPreviousTaskCard() {
        if (taskCards.value.length === 1) {
            currentDisplayedPosition.value = 0;
            return;
        }
        if (currentDisplayedPosition.value === 0) {
            currentDisplayedPosition.value = taskCards.value.length - 1;
            return;
        }
        currentDisplayedPosition.value = currentDisplayedPosition.value - 1;
    }

    async function dismiss() {
        // TODO this code should move to a repository (need to create it)
        await useUniversalPost<any>(currentTaskCard.value.links.dismiss(), {
            body: {taskType: currentTaskCard.value.type},
        });
        await getTaskCards();
    }

    return {
        isExperienceCard,
        connectedUser,
        getTaskCards,
        currentDisplayedPosition,
        taskCards,
        displayNextTaskCard,
        displayPreviousTaskCard,
        dismiss,
        currentTaskCard,
        resendAccountValidationEmail,
        taskCardsSize,
    };
});
