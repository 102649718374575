<template>
    <ExternalUserActionComponent dialog-id="wishlist-edition" open-dialog-event="wishlist:edit-mode" size="default">
        <template #edit-header>{{ t('wishlist.modal.title', {0: profile!.firstname}) }}</template>
        <template #edit-body>
            <VJoyDividerCta @click="openAddWishlist">{{ t('wishlist.modal.create.new') }}</VJoyDividerCta>

            <VJoyWrapper
                v-for="wishlist in wishlists.items"
                :key="wishlist.id"
                justify="flex-start"
                item-stretch
                class="wishlist"
                no-margin
                @click="addOrRemoveFromWishlist(wishlist)"
            >
                <div>
                    <div>
                        <strong>{{ wishlist.name }} ({{ wishlist.profiles.length }})</strong>
                    </div>
                    <div class="wishlist__creator">
                        {{ t('wishlist.modal.list.owner') }}
                        <strong>{{ wishlist.ownerFirstname }}</strong>
                    </div>
                </div>
                <VJoyIcon class="wishlist__star" :name="starFillIfProfileInsideWishlist(wishlist)" size="medium"></VJoyIcon>
            </VJoyWrapper>
        </template>
    </ExternalUserActionComponent>
</template>

<script setup lang="ts">
    import ExternalUserActionComponent from '~/components/external-user-action/ExternalUserActionComponent.vue';
    import {useTranslation} from '#imports';
    import {useProfileStore} from '~/store/profileStore';
    import {storeToRefs} from 'pinia';
    import {eventBus} from '~/config/events';
    import {useWishlistStore} from '~/store/wishlist/wishlistStore';
    import type {Wishlist} from '~/types/domain/Wishlist';
    import type {TJoyIconsNames} from '@maltjoy/core-vue';
    import {pushVJoySnackbar, VJoyDividerCta, VJoyIcon, VJoyWrapper} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {profile} = storeToRefs(useProfileStore());
    const {wishlists} = storeToRefs(useWishlistStore());
    const {addToWishlist, removeFromWishlist} = useWishlistStore();

    async function addOrRemoveFromWishlist(wishlist: Wishlist): Promise<void> {
        if (wishlist.profiles.includes(profile.value!.profileId)) {
            await removeFromWishlist(wishlist.id, profile.value!);
            pushVJoySnackbar({
                props: {
                    message: t('wishlist.profile.removal.success.message'),
                    level: 'success',
                },
            });
        } else {
            await addToWishlist(wishlist.id, profile.value!);
            pushVJoySnackbar({
                props: {
                    message: t('wishlist.saved.label'),
                    level: 'success',
                },
            });
        }
    }

    function starFillIfProfileInsideWishlist(wishlist: Wishlist): TJoyIconsNames {
        return wishlist.profiles.includes(profile.value!.profileId) ? 'star-fill' : 'star';
    }

    function openAddWishlist() {
        eventBus.emit('wishlist:add-mode');
    }
</script>

<style lang="scss" scoped>
    .wishlist {
        cursor: pointer;
        border-radius: 6px;
        padding: 20px 20px 20px 20px;

        &:hover {
            background-color: var(--joy-color-secondary-10);
        }

        &__creator {
            font-size: var(--joy-font-size-primary-300);
        }

        &__lock {
            flex: 0;
        }

        &__star {
            justify-content: flex-end;
        }
    }
</style>
