<template>
    <VJoyHighlight level="warning" :accent="true" :display-icon="true" :data-testid="props.dataTestid">
        <template #default>
            {{ t('generic.technical.error') }}
            <div v-if="isVisibleTechnicalError">
                <b>Technical Error:</b>
                <slot name="technical-error" />
            </div>
        </template>
    </VJoyHighlight>
</template>

<script setup lang="ts">
    import {useHasEnoughPriviledge, useTranslation} from '#imports';
    import {computed, useSlots} from 'vue';
    import {VJoyHighlight} from '@maltjoy/core-vue';

    type Props = {
        dataTestid?: string;
    };

    const props = withDefaults(defineProps<Props>(), {
        dataTestid: 'profile-common-fetch-error-highlight',
    });

    const {t} = useTranslation();

    const slots = useSlots();
    const hasSlotTechnicalError = Object.prototype.hasOwnProperty.call(slots, 'technical-error');

    const isAdmin = await useHasEnoughPriviledge(['ROLE_ADMIN']);
    const isAdminOrDevMode = computed(() => process.dev || isAdmin);
    const isVisibleTechnicalError = computed(() => hasSlotTechnicalError === true && isAdminOrDevMode);
</script>
