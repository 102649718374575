<template>
    <VJoyButton v-if="!isTranslationDone" :loading="loading" variant="ghost" icon="translate" size="small" @click="translateProfile">
        {{ t('profile.view.translation.cta.translate') }}
    </VJoyButton>
    <VJoyButton v-else :loading="loading" variant="ghost" icon="translate" size="small" @click="restoreProfile">
        {{ t('profile.view.translation.cta.restore') }}
    </VJoyButton>
</template>

<script setup lang="ts">
    import {useTranslation} from '#imports';
    import {eventBus} from '~/config/events';
    import {useTranslationStore} from '~/store/translation/translationStore';
    import {storeToRefs} from 'pinia';
    import {TranslationAction} from '~/config/TrackingEvents';
    import {useProfileTracking} from '~/composables/useProfileTracking';
    import {useProfileStore} from '~/store/profileStore';
    import {VJoyButton} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {trackProfileTranslated} = useProfileTracking();
    const {profile} = storeToRefs(useProfileStore());
    const {isTranslationDone, loading, currentLang} = storeToRefs(useTranslationStore());

    const props = defineProps<{
        section: string;
    }>();

    function translateProfile() {
        eventBus.emit('translation:request');
        trackProfileTranslated(profile.value!, props.section, currentLang.value, TranslationAction.TRANSLATE);
    }

    function restoreProfile() {
        eventBus.emit('translation:restore');
        trackProfileTranslated(profile.value!, props.section, currentLang.value, TranslationAction.REVERSE);
    }
</script>
