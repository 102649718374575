<template>
    <VJoyPanel>
        <template #panel-title>
            {{ t('profile.section.identity.checklist.title') }}
        </template>
        <template #panel-body>
            <div class="sections">
                <ChecklistVerificationCharter />
                <ChecklistVerificationCompliance
                    v-if="displayCompanyComplianceValid"
                    :up-to-date="isCompanyComplianceValid"
                    :title="t('profile.section.header.verified.compliance')"
                    :message-link="t('profile.section.header.verified.compliance.faq')"
                    :href="t('profile.section.header.verified.compliance.faq.link')"
                    :info-content="t('profile.section.header.verified.compliance.complement')"
                />
                <ChecklistVerificationCompliance
                    v-if="displayCompanyComplianceUpToDate"
                    :up-to-date="isCompanyComplianceUpToDate"
                    :title="complianceTitle"
                    :message-link="t('profile.section.header.verified.uptodate.compliance.faq')"
                    :href="t('profile.section.header.verified.uptodate.compliance.faq.link')"
                    :info-content="t('profile.section.header.verified.uptodate.compliance.complement')"
                />
                <ChecklistVerificationEmail />
            </div>
        </template>
    </VJoyPanel>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {useTranslation} from '#imports';
    import ChecklistVerificationCharter from '~/components/left-side/checklist/ChecklistVerificationCharter.vue';
    import ChecklistVerificationCompliance from '~/components/left-side/checklist/ChecklistVerificationCompliance.vue';
    import ChecklistVerificationEmail from '~/components/left-side/checklist/ChecklistVerificationEmail.vue';
    import {useProfileStore} from '~/store/profileStore';
    import {storeToRefs} from 'pinia';
    import {VJoyPanel} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const profileStore = useProfileStore();
    const {profile, verificationChecklist} = storeToRefs(profileStore);

    const complianceTitle = computed(() => {
        return isCompanyComplianceUpToDate.value
            ? t('profile.section.header.verified.uptodate.compliance')
            : t('profile.section.header.verified.not.uptodate.compliance');
    });

    const isToDisplay = computed(() => verificationChecklist.value?.complianceCheck.display || false);
    const isCompanyComplianceValid = computed(() => verificationChecklist.value?.complianceCheck.companyVerified || false);
    const isCompanyComplianceUpToDate = computed(() => verificationChecklist.value?.complianceCheck.companyComplianceUpToDate || false);
    const displayCompanyComplianceValid = computed(() => {
        if (profile.value?.editMode) {
            return isToDisplay.value;
        }

        return isToDisplay.value && isCompanyComplianceValid.value;
    });
    const displayCompanyComplianceUpToDate = computed(() => {
        if (profile.value?.editMode) {
            return isToDisplay.value;
        }

        return isToDisplay.value && isCompanyComplianceValid.value && isCompanyComplianceUpToDate.value;
    });
</script>

<style lang="scss" scoped>
    .sections {
        & > * {
            margin-bottom: var(--joy-core-spacing-3);
        }
    }
</style>
