<template>
    <VJoyPanel :class="{'edit-mode': isEditModeEnabled}" data-testid="profile-description" @click="openDescriptionDialog">
        <template #panel-title>
            {{ descriptionTitle }}
        </template>

        <template #panel-title-action>
            <TranslateButton v-if="isTranslationActivated && profile!.description.length > 0" section="description" />
            <div v-if="isEditModeEnabled">
                <VJoyButton circle variant="ghost" class="edit-button" icon="pen" size="small" />
            </div>
        </template>

        <template #panel-body>
            <ReadMore id="description-section" :view-more-label="viewMoreLabel" component-name="profile-description-section">
                <!-- eslint-disable vue/no-v-html -->
                <div
                    class="profile-description__content joy-panel__body"
                    :class="{example: !hasDescription}"
                    v-html="profileSanitizedDescription"
                ></div>
                <!-- eslint-enable vue/no-v-html -->
            </ReadMore>
        </template>
    </VJoyPanel>
</template>

<script setup lang="ts">
    import {useAuth, useMarkdownToHtml, useTranslation} from '#imports';
    import {capitalize, computed, onMounted, onUnmounted} from 'vue';
    import {eventBus} from '~/config/events';
    import {useProfileStore} from '~/store/profileStore';
    import ReadMore from '../_common/read-more/ReadMore.vue';
    import {TranslationDirection, useTranslationStore} from '~/store/translation/translationStore';
    import {storeToRefs} from 'pinia';
    import TranslateButton from './TranslateButton.vue';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';
    import {VJoyButton, VJoyPanel} from '@maltjoy/core-vue';

    const {profile} = storeToRefs(useProfileStore());

    const {t} = useTranslation();
    const {markdownToHtml} = useMarkdownToHtml();

    const descriptionTitle = profile.value?.editMode
        ? t('profile.section.description.title', {0: capitalize(t('generic.pronoun.you'))})
        : t('profile.section.description.title', {0: capitalize(profile.value!.firstname)});
    const viewMoreLabel = useAuth().loggedIn
        ? t('jstext.readmore.label.readmore')
        : t('profile.section.description.private', [profile.value?.firstname]);

    const {isTranslationActivated} = storeToRefs(useTranslationStore());
    const {translateAll, replaceTranslationContent} = useTranslationStore();
    const translationSection = 'description';
    const translationField = 'description';

    const isEditModeEnabled = computed(() => profile.value?.editMode || false);
    const hasDescription = computed(() => (profile.value?.description && profile.value?.description?.length > 0) || false);
    const profileSanitizedDescription = computed(() =>
        hasDescription.value ? markdownToHtml(profile?.value?.description) : t('profile.section.description.eg.content'),
    );

    function openDescriptionDialog() {
        if (profile.value!.editMode) {
            eventBus.emit('description:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
        }
    }

    async function translateDescription() {
        const descriptionRequest = {
            section: translationSection,
            field: translationField,
            index: 0,
            originalContent: profile.value!.description,
        };

        await translateAll([descriptionRequest]);

        replaceTranslationContent(translationSection, translationField, TranslationDirection.TRANSLATE, switchTranslation);
    }

    function restoreDescription() {
        replaceTranslationContent(translationSection, translationField, TranslationDirection.RESTORE, switchTranslation);
    }

    function switchTranslation(content: string) {
        profile.value!.description = content;
    }

    onMounted(() => {
        eventBus.on('translation:request', translateDescription);
        eventBus.on('translation:restore', restoreDescription);
    });

    onUnmounted(() => {
        eventBus.off('translation:request', translateDescription);
        eventBus.off('translation:restore', restoreDescription);
    });
</script>

<style lang="scss" scoped>
    .edit-mode {
        cursor: pointer;
    }

    .profile-description {
        padding: var(--joy-core-spacing-8);

        &__title {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: var(--joy-core-spacing-4);
        }

        &__content {
            overflow-wrap: break-word;
            word-break: break-word;

            &.example {
                font-style: italic;
                color: var(--joy-color-neutral-30);
            }
        }
    }
</style>
