import {extractQueryParameters, reactive, useAuth, useNuxtApp, useRoute} from '#imports';
import type {LinkEntry} from 'vue3-starter/src/typescript/registry/registry';
import {ResourceRegistry} from 'vue3-starter/src/typescript/registry/ResourceRegistry';
import type {ProfileStoreRequest} from '../types/domain/ProfileStoreRequest';

export function useInfosRequest(): ProfileStoreRequest {
    const route = useRoute();

    const {$pinia} = useNuxtApp();
    const {loggedIn} = useAuth($pinia);

    const rawRegistry: LinkEntry[] = [{rel: 'profile', href: '/profile/public-api/{profileId}', context: [], parameters: undefined, type: undefined}];
    const registry = new ResourceRegistry(rawRegistry);

    const request = reactive({
        route: {
            profileIdOrAlias: route.params.profileIdOrAlias as string,
            queryParameters: encodeURI(extractQueryParameters(route)),
        },
        loggedIn,
        registry,
    });

    return {
        details: request,
    };
}
