<template>
    <LeftSideComponent v-if="!isProfileCompletionDone" :first-element="true" data-testid="left-side-completion-list">
        <template #title>
            {{ $t('profile.edition.completion.panel.title') }}
            <div class="profile-completion__header-subtitle">
                <VJoyIcon size="small" name="eye-masked" class="profile-completion__visibility-icon" />
                {{ $t('profile.edition.completion.panel.subtitle') }}
            </div>
        </template>
        <template #content>
            <ClientOnly>
                <div v-if="!isLoading && items && !isProfileCompletionDone">
                    <GaugeChart :key="items.completionPercentage" :current-value="items.completionPercentage" />
                    <div class="profile-completion-checklist">
                        <div class="profile-completion-checklist__title" @click="showList = !showList">
                            {{ $t('profile.edition.completion.panel.checklist.title') }}
                            <VJoyIcon :name="showList ? 'chevron-top' : 'chevron-down'" />
                        </div>
                        <div v-show="showList" class="profile-completion-checklist__items">
                            <ul>
                                <li v-if="!features['missing-description-visible']">
                                    <DescriptionCompletion :completed-item="items.completedDescription" />
                                </li>
                                <li>
                                    <PhotoCompletion :completed-item="items.completedPhoto" />
                                </li>
                                <li>
                                    <EmailAddressCompletion :completed-item="items.verifiedEmailAddress" />
                                </li>
                                <li>
                                    <ExperiencesCompletion :completed-item="items.completedExperience" />
                                </li>
                                <li v-if="features['missing-description-visible']">
                                    <DescriptionCompletion :completed-item="items.completedDescription" />
                                </li>
                                <li>
                                    <TagsCompletion :completed-item="items.completedTags" />
                                </li>
                                <li>
                                    <CharterCompletion :completed-item="items.completedCharter" />
                                </li>
                                <li>
                                    <LanguageCompletion :completed-item="items.completedLanguage" />
                                </li>
                            </ul>
                            <ul v-if="items.allAdvancedItemsCompleted" data-testid="advanced-completion">
                                <li>
                                    <WorkplacePreferenceCompletion :completed-item="items.completedWorkplacePreference" />
                                </li>
                                <li>
                                    <FormationCompletion :completed-item="items.completedFormationOrCertification" />
                                </li>
                                <li>
                                    <MissionPreferencesCompletion :completed-item="items.completedMissionPreferences" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <template #fallback>
                    <VJoyScreenLoader class="gauge-chart-loading" />
                </template>
            </ClientOnly>
        </template>
    </LeftSideComponent>
    <FullyCompleteProfileTip v-else data-testid="completed-profile-tip" class="profile-completion-highlight" />
</template>

<script setup lang="ts">
    import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
    import LeftSideComponent from '@/components/left-side/common/LeftSideComponent.vue';
    import FullyCompleteProfileTip from '@/components/left-side/completion-list/ui/FullyCompleteProfileTip.vue';
    import {eventBus, ProfileEdtionDataDeletedEvents, ProfileEdtionDataUpdatedEvents} from '@/config/events';
    import {useProfileStore} from '@/store/profileStore';
    import type {ProfileCompletionCheckListResource} from '@/types/api-raw-types';
    import {storeToRefs} from 'pinia';
    import CharterCompletion from './ui/CharterCompletion.vue';
    import DescriptionCompletion from './ui/DescriptionCompletion.vue';
    import EmailAddressCompletion from './ui/EmailAddressCompletion.vue';
    import ExperiencesCompletion from './ui/ExperiencesCompletion.vue';
    import FormationCompletion from './ui/FormationCompletion.vue';
    import GaugeChart from './ui/GaugeChart.vue';
    import LanguageCompletion from './ui/LanguageCompletion.vue';
    import MissionPreferencesCompletion from './ui/MissionPreferencesCompletion.vue';
    import PhotoCompletion from './ui/PhotoCompletion.vue';
    import TagsCompletion from './ui/TagsCompletion.vue';
    import WorkplacePreferenceCompletion from './ui/WorkplacePreferenceCompletion.vue';
    import {VJoyIcon, VJoyScreenLoader} from '@maltjoy/core-vue';
    import {useFeatureContext} from '~/store/featureStore';

    const showList = ref(false);

    const profileStore = useProfileStore();
    const {completionList} = storeToRefs(profileStore);

    const {features} = useFeatureContext();

    const items = computed((): ProfileCompletionCheckListResource | undefined => completionList.value?.items);
    const isProfileCompletionDone = computed(() => items.value?.allItemsCompleted);
    const isLoading = ref<boolean>(true);

    async function updateCompletionList(): Promise<void> {
        return await profileStore.getCompletionList();
    }

    const updateAndDeleteEvents = {...ProfileEdtionDataUpdatedEvents, ...ProfileEdtionDataDeletedEvents};

    function createEventHandlersForTaskCardsUpdate() {
        Object.entries(updateAndDeleteEvents).forEach(([key, value]) => {
            eventBus.on(value, async () => {
                await updateCompletionList();
            });
        });
    }

    function stopEventHandlersForTaskCardsUpdate() {
        Object.entries(updateAndDeleteEvents).forEach(([key, value]) => {
            eventBus.off(value, async () => {
                await updateCompletionList();
            });
        });
    }

    onMounted(async () => {
        isLoading.value = true;
        createEventHandlersForTaskCardsUpdate();
        await updateCompletionList();
        isLoading.value = false;
    });

    onBeforeUnmount(() => {
        stopEventHandlersForTaskCardsUpdate();
    });
</script>

<style lang="scss">
    .profile-completion__header-subtitle {
        color: var(--joy-color-neutral-40) !important;
        align-items: center;
        display: flex;
        column-gap: 10px;
        font-weight: var(--joy-font-weight-normal) !important;
        font-size: var(--joy-font-size-primary-400) !important;
    }

    .profile-completion__visibility-icon {
        flex: 0 !important;

        i {
            color: var(--joy-color-neutral-40) !important;
        }
    }

    .profile-completion {
        &-highlight.joy-highlight {
            margin-bottom: var(--joy-core-spacing-8);
        }

        .gauge-wrapper {
            margin-bottom: 10px;
        }

        &-checklist {
            padding: 20px;

            &__title {
                align-items: center;
                color: var(--joy-color-secondary-30);
                cursor: pointer;
                display: flex;
                flex-wrap: nowrap;
                font-size: var(--joy-font-size-primary-400);
                font-weight: 700;
                justify-content: space-between;
                margin-bottom: 20px;
            }

            &__items ul {
                list-style-type: none;
                padding-left: 0;
            }

            &__items li:not(:last-child) {
                margin-bottom: 12px;
            }

            &__items li i,
            &__items li joy-icon {
                margin-right: 8px;
            }

            &__items .completed-item {
                fill-opacity: 1;
            }

            &__items .neutral-item {
                color: var(--joy-color-neutral-40);
            }
        }
    }

    .gauge-chart-loading {
        height: 180px;
    }
</style>
