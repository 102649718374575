import type {WishlistRepository} from '~/types/domain/repository/WishlistRepository';
import {Wishlists} from '~/types/domain/Wishlist';
import type {Profile} from '~/types/domain/Profile';
import {extractLink} from '~/helpers/api/ExtractLinkHelper';
import {useUniversalDelete, useUniversalFetch, useUniversalPost} from '#imports';
import {urlService} from 'vue3-starter/dist/http/UrlService';
import type {WishlistCreationRequest, WishlistResource} from '~/types/api-raw-types';

async function fetchWishlists(profile: Profile): Promise<Wishlists> {
    try {
        const url = extractLink(profile.links, 'wishlists');
        const wishlistsResource = await useUniversalFetch<WishlistResource[]>(url);
        return new Wishlists(wishlistsResource);
    } catch (e) {
        return Wishlists.default();
    }
}

async function createWishlist(wishlistName: string, profile: Profile): Promise<void> {
    const url = extractLink(profile.links, 'createWishlist');

    const request: Partial<WishlistCreationRequest> = {
        name: wishlistName,
        profiles: [profile.profileId],
    };

    return await useUniversalPost<void>(url, {body: request});
}

async function addToWishlist(wishlistId: string, profile: Profile): Promise<void> {
    const url = extractLink(profile.links, 'addToWishlist');
    const computedUrl = `${urlService.buildUrl(url, wishlistId, profile.profileId)}?source=profile`;
    return await useUniversalPost<void>(computedUrl);
}

async function removeFromWishlist(wishlistId: string, profile: Profile): Promise<void> {
    const url = extractLink(profile.links, 'removeFromWishlist');
    const computedUrl = `${urlService.buildUrl(url, wishlistId, profile.profileId)}?source=profile`;
    return await useUniversalDelete<void>(computedUrl);
}

export const wishlistRepository: WishlistRepository = {
    addToWishlist,
    removeFromWishlist,
    createWishlist,
    fetchWishlists,
};
