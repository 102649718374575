<template>
    <VJoyPanel data-testid="categories-section">
        <template #panel-title>
            {{ t('profile.section.categories.title') }}
        </template>

        <template v-if="profile?.editMode" #panel-title-action>
            <VJoyButton variant="ghost" circle icon="pen" size="small" @click="goToEditCategories" />
        </template>

        <template v-if="profile" #panel-body>
            <div v-for="family in orderedFamilies(profile.categorization)" :key="family.id" class="categories">
                <CategoriesIcon :family="(family.pictoName as JoyIconsId)" />
                <ul class="categories__list">
                    <li v-for="category in orderedCategories(family.categories)" :key="category.id" class="categories__list-item">
                        <VJoyLink v-if="isCategoryLinkActive && category.seoUrl" :href="category.seoUrl" :rel="getCategoryLinkRel(category)">
                            {{ category.label }}
                        </VJoyLink>
                        <span v-else>{{ category.label }}</span>
                    </li>
                </ul>
            </div>
        </template>
    </VJoyPanel>
</template>

<script setup lang="ts">
    import {computed, useSectionsVisibility, useTranslation} from '#imports';
    import {useProfileStore} from '~/store/profileStore';
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    import type {JoyIconsId} from '@maltjoy/icons';
    import CategoriesIcon from '~/components/left-side/categories/CategoriesIcon.vue';
    import type {CategorizationCategory} from '~/types/domain/Categorization';
    import {orderedCategories, orderedFamilies} from '~/types/domain/Categorization';
    import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';
    import {storeToRefs} from 'pinia';
    import {eventBus} from '~/config/events';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';
    import {VJoyButton, VJoyLink, VJoyPanel} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {profile} = storeToRefs(useProfileStore());

    const isCategoryLinkActive = computed(() => useSectionsVisibility(SectionsVisibilityType.SEARCH_LINKS));

    function getCategoryLinkRel(category: CategorizationCategory) {
        return category.seoUrl?.startsWith('/s?q=') ? 'nofollow' : undefined;
    }

    function goToEditCategories() {
        eventBus.emit('categories:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
    }
</script>

<style lang="scss" scoped>
    .categories {
        display: flex;

        &__list {
            display: flex;
            flex-direction: column;
            justify-content: center;
            list-style-type: none;
            padding: 0;
            margin-top: 0;
            margin-left: var(--joy-core-spacing-2);
            font-size: var(--joy-font-size-primary-400);

            &-item {
                & > span {
                    font-weight: bold;
                }
            }
        }
    }
</style>
