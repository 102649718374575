<template>
    <div v-if="!(profile!.editMode)" class="profile-cta">
        <VJoyButton
            class="profile-cta-project"
            data-testid="project-proposal-cta"
            variant="main"
            :loading="loading"
            @click="redirectToProjectProposal"
        >
            {{ t('show.contactme.button.label') }}
        </VJoyButton>
        <small v-if="displaySubtitle" class="profile-cta-subtitle">{{ t('profile.project.submission.title', {0: profile!.firstname}) }}</small>
    </div>
    <div v-else class="profile-cta">
        <div class="profile-cta-project"></div>
    </div>
</template>

<script setup lang="ts">
    import {guestHost, ref, useTranslation} from '#imports';
    import {useShortlistStore} from '@/store/shortlist/ShortlistStore';
    import {navigateTo} from '@typed-router';
    import {storeToRefs} from 'pinia';
    import {extractLink} from '~/helpers/api/ExtractLinkHelper';
    import {useProfileStore} from '~/store/profileStore';
    import {VJoyButton} from '@maltjoy/core-vue';

    type Props = {
        displaySubtitle: boolean;
    };
    defineProps<Props>();

    const {t} = useTranslation();
    const loading = ref(false);

    const shortlistStore = useShortlistStore();
    const {profile} = storeToRefs(useProfileStore());
    const {shortlist} = storeToRefs(shortlistStore);

    function redirectToProjectProposal() {
        const proposeProjectUrl = extractLink(profile.value!.links, 'proposeProject');
        const host = guestHost();
        const fullProposeProjectUrl = new URL(`${host}${proposeProjectUrl}`);
        loading.value = true;

        if (shortlist.value && shortlist.value.items.length > 0 && fullProposeProjectUrl.searchParams.has('freelanceId')) {
            fullProposeProjectUrl.searchParams.delete('freelanceId');
            return navigateTo(`${fullProposeProjectUrl.href}`, {external: true});
        }

        return navigateTo(`${fullProposeProjectUrl.href}`, {external: true});
    }
</script>

<style lang="scss" scoped>
    .profile-cta {
        width: 200px;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-project {
            margin-bottom: var(--joy-core-spacing-2);
            width: 100%;
        }

        &-subtitle {
            margin-right: auto;
            margin-left: auto;
        }
    }
</style>
