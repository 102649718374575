<template>
    <VJoyPanel data-testid="profile-relations-section">
        <template #panel-title>
            <VJoyTooltip>
                {{ sectionTitle }}
                <template #tooltip-content>
                    {{ t('profile.section.profile.relations.tooltip.content') }}
                </template>
            </VJoyTooltip>
        </template>

        <template #panel-body>
            <div class="profile-relations">
                <div class="profile-relations__relations-info">
                    <div class="profile-relations__relations-info__content">
                        <MaltImg
                            v-if="relations?.logo"
                            :src="companyLogoUrl"
                            :alt="(companyName as string)"
                            width="32"
                            height="32"
                            data-testid="collaborators-company-logo"
                        />
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-if="collaboratorNames.length === 1" data-testid="one-collaborator" v-html="oneCollaborator" />
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-else-if="collaboratorNames.length === 2" data-testid="two-collaborators" v-html="twoCollaborators" />
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-else-if="collaboratorNames.length > 2" data-testid="more-than-two-collaborators" v-html="moreThanTwoCollaborators" />
                        <span v-else-if="isAddedAsFavorite" data-testid="freelancer-added-as-favorite">
                            {{ t('profile.section.pof.relation.categorized.favorite', {0: profile?.firstname}) }}
                        </span>
                    </div>
                    <VJoyLink
                        v-if="collaboratorNames.length > 2"
                        data-testid="collaborators-list-toggle"
                        href=""
                        :text="
                            showCollaboratorsList
                                ? t('profile.section.profile.relations.toggle.hide')
                                : t('profile.section.profile.relations.toggle.view')
                        "
                        @click.prevent="toggleListDisplay(showCollaboratorsList)"
                    />
                </div>
                <div v-if="showCollaboratorsList" class="profile-relations__collaborators-list" data-testid="collaborators-list">
                    <ul>
                        <li v-for="collaboratorName in collaboratorNames" :key="collaboratorName">
                            {{ collaboratorName }}
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </VJoyPanel>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {computed} from 'vue';
    import {ref, useTranslation} from '#imports';
    import {storeToRefs} from 'pinia';
    import {useProfileStore} from '~/store/profileStore';
    import {VJoyLink, VJoyPanel, VJoyTooltip} from '@maltjoy/core-vue';

    const profileStore = useProfileStore();
    const {relations, collaboratorsInfo, profile} = storeToRefs(profileStore);

    const collaboratorNames = computed(() => collaboratorsInfo.value?.experienceRelated || []);
    const isAddedAsFavorite = computed(() => collaboratorsInfo.value?.isAddedAsFavorite);
    const showCollaboratorsList = ref<boolean>(false);

    const {t} = useTranslation();

    const companyLogoUrl = computed(() => computeCompanyLogoUrl(relations.value?.logo.baseUrl, relations.value?.logo.name));
    const companyName = computed(() => relations.value?.name);

    const sectionTitle = computed(() => t('profile.section.profile.relations.title', {0: profile.value?.firstname}));

    const oneCollaborator = computed(() =>
        t('profile.section.profile.relations.one.collaborator', {0: profile.value?.firstname, 1: collaboratorNames.value[0]}),
    );
    const twoCollaborators = computed(() =>
        t('profile.section.profile.relations.two.collaborators', {
            0: profile.value?.firstname,
            1: collaboratorNames.value[0],
            2: collaboratorNames.value[1],
        }),
    );
    const moreThanTwoCollaborators = computed(() =>
        t('profile.section.profile.relations.more.than.two.collaborators', {
            0: profile.value?.firstname,
            1: collaboratorNames.value[0],
            2: collaboratorNames.value.length - 1,
        }),
    );

    function computeCompanyLogoUrl(baseUrl?: string, logoName?: string): string {
        if (baseUrl && logoName) return `${baseUrl}/${logoName}`;
        return '';
    }

    function toggleListDisplay(currentValue: boolean) {
        showCollaboratorsList.value = !currentValue;
    }
</script>

<style lang="scss" scoped>
    .profile-relations {
        border-radius: var(--joy-core-radius-3);
        background-color: var(--joy-color-information-10);
        padding: var(--joy-core-spacing-3);

        &__relations-info {
            display: flex;
            justify-content: space-between;

            &__content {
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: var(--joy-core-spacing-2);
            }

            a {
                padding-left: var(--joy-core-spacing-3);
            }
        }

        &__collaborators-list {
            margin-top: var(--joy-core-spacing-3);
            ul {
                margin: 0;
            }

            li {
                margin-bottom: var(--joy-core-spacing-1);
            }
        }
    }
</style>
