import {useNuxtApp} from '#imports';
import {storeToRefs} from 'pinia';
import {useProfileStore} from '~/store/profileStore';
import {ProfileVertical} from '~/types/domain/Profile';

export function useStrategyProfile(): boolean {
    const {$pinia} = useNuxtApp();
    const profileStore = useProfileStore($pinia);
    const {profile} = storeToRefs(profileStore);
    return profile.value?.vertical === ProfileVertical.STRATEGY;
}
