<template>
    <div>
        <VJoyUserCard
            v-for="(similar, index) in similars"
            :key="`similar-${index}`"
            :class="{'joy-mb-5': similars && index !== similars?.length - 1}"
            :full-name="similar.firstName + ' ' + similar.normalizedLastName"
            :sub-title="similar.headline"
            :link="similarProfileLink(similar)"
            :photo-url="similar.photo?.jpg"
        >
            <template v-if="similar.numberOfStars > 0" #user-card-rating-stars>
                <VJoyRatingStars :review-count="0" :rating-value="similar.numberOfStars" />
            </template>
        </VJoyUserCard>
    </div>
</template>

<script setup lang="ts">
    import type {SimilarProfile} from '~/types/domain/SimilarProfile';
    import {VJoyRatingStars, VJoyUserCard} from '@maltjoy/core-vue';

    defineProps<{similars: SimilarProfile[]}>();

    function similarProfileLink(similar: SimilarProfile): string {
        return similar.canonicalUrl.split('profile/')[1];
    }
</script>
