<template>
    <p class="profile-appraisal-hidden-indicator">
        <VJoyIcon name="eye-masked" color="secondary" />
        {{ t('profile.hidden.appraisals.count', {accountFirstName: accountFirstname, nbrHiddenAppraisals: hiddenCounter}) }}
    </p>
</template>

<script setup lang="ts">
    import {useTranslation} from '#imports';
    import {VJoyIcon} from '@maltjoy/core-vue';

    type Props = {
        accountFirstname: string;
        hiddenCounter: number;
    };
    defineProps<Props>();
    const {t} = useTranslation();
</script>
