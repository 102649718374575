<template>
    <div v-if="siblings.loaded" class="siblings">
        <VJoyIcon v-if="displayLeftIcon" class="siblings-left" :name="leftIcon" role="button" @click="goToPreviousProfile" />
        <VJoyIcon v-if="displayRightIcon" class="siblings-right" :name="rightIcon" role="button" @click="goToNextProfile" />
    </div>
</template>

<script setup lang="ts">
    import {computed, guestHost, toRef} from '#imports';
    import {type TJoyIconsNames, VJoyIcon} from '@maltjoy/core-vue';
    import {navigateTo} from '@typed-router';
    import type {Siblings} from '~/types/domain/Siblings';

    type Props = {
        searchId: string;
        queryParams: string;
        siblings: Siblings;
        isConnected: boolean;
    };
    const props = defineProps<Props>();
    const siblings = toRef(props, 'siblings');
    const isConnected = toRef(props, 'isConnected');
    const hasPreviousProfile = computed<boolean>(() => siblings.value.hasPreviousProfile);
    const hasNextProfile = computed<boolean>(() => siblings.value.hasNextProfile);
    const displayLeftIcon = computed<boolean>(() => siblings.value.hasPreviousProfile || !siblings.value.isFirstPage);
    const displayRightIcon = computed<boolean>(() => siblings.value.hasNextProfile || !siblings.value.isLastPage);
    const leftIcon = computed<TJoyIconsNames>(() => (hasPreviousProfile.value === true ? 'arrow-left' : 'grid'));
    const rightIcon = computed<TJoyIconsNames>(() => (hasNextProfile.value ? 'arrow-right' : 'grid'));

    function goToPreviousProfile() {
        if (hasPreviousProfile.value || (isConnected.value && siblings.value.previousUrl)) {
            return navigateTo(`${guestHost()}${siblings.value.previousUrl}`, {external: true});
        }

        return redirectToSignup();
    }

    function goToNextProfile() {
        if (hasNextProfile.value || (isConnected.value && siblings.value.nextUrl)) {
            return navigateTo(`${guestHost()}${props.siblings.nextUrl}`, {external: true});
        }

        return redirectToSignup();
    }

    function redirectToSignup() {
        return navigateTo(`${guestHost()}/signup`, {external: true});
    }
</script>
