<template>
    <div data-testid="checkilist-verification-email">
        <VJoyHighlight v-if="isEmailVerified" level="success" display-icon data-testid="checkilist-verification-email-highlight-verified">
            {{ t('profile.section.header.verified.email') }}
        </VJoyHighlight>
        <VJoyHighlight v-else level="error" display-icon data-testid="checkilist-verification-email-highlight-not-verified">
            {{ t('profile.section.header.not.verified.email') }}
            <button
                v-if="profile!.editMode"
                id="emailVerificationCTA"
                type="button"
                class="checklist-link joy-color-secondary-90"
                data-testid="profile-left-column-email-verification"
                @click="handlerEmailResendVerification"
            >
                {{ t('profile.section.header.email.verification.cta') }}
            </button>
        </VJoyHighlight>
    </div>
</template>

<script setup lang="ts">
    import {useTranslation} from '#imports';
    import {pushVJoySnackbar, VJoyHighlight} from '@maltjoy/core-vue';
    import {computed} from 'vue';
    import {useProfileStore} from '~/store/profileStore';

    const {t} = useTranslation();
    const {verificationChecklist, resendAccountValidationEmail, profile} = useProfileStore();
    const isEmailVerified = computed(() => verificationChecklist!.emailValidation.valid);

    async function handlerEmailResendVerification() {
        try {
            await resendAccountValidationEmail();
            pushVJoySnackbar({
                props: {
                    message: t('menu.validation.email.resend'),
                    level: 'success',
                },
            });
        } catch (message) {
            pushVJoySnackbar({
                props: {
                    message: t('generic.error.unexpected'),
                    level: 'error',
                },
            });
        }
    }
</script>

<style lang="scss" scoped>
    .checklist-link {
        font-size: var(--joy-font-size-primary-400);
        font-weight: var(--joy-font-weight-bold);
        text-decoration: underline;
        padding: 0;
        text-align: left;
        background: none;
        border: 0;
        cursor: pointer;
    }
</style>
