<template>
    <VJoyButton
        circle
        variant="ghost"
        :icon="wishlistIcon"
        size="small"
        class="edit-button"
        data-testid="profile-view-header-wishlist-cta"
        @click="editWishlist"
    />
</template>

<script setup lang="ts">
    import {eventBus} from '~/config/events';
    import {useWishlistStore} from '~/store/wishlist/wishlistStore';
    import {storeToRefs} from 'pinia';
    import {computed} from '#imports';
    import {useProfileStore} from '~/store/profileStore';
    import {type TJoyIconsNames, VJoyButton} from '@maltjoy/core-vue';

    const {profile} = storeToRefs(useProfileStore());
    const {wishlists} = storeToRefs(useWishlistStore());

    const wishlistIcon = computed<TJoyIconsNames>(() => {
        const hasProfileInWishlist = wishlists.value.items.some((wishlist) => wishlist.profiles.includes(profile.value!.profileId));
        return hasProfileInWishlist ? 'heart-fill' : 'heart';
    });

    function editWishlist() {
        eventBus.emit('wishlist:edit-mode');
    }
</script>

<style lang="scss" scoped>
    .edit-button {
        cursor: pointer;
        margin-left: var(--joy-core-spacing-2);
    }
</style>
