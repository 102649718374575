<template>
    <CompletionItem
        :important="!features['missing-description-visible']"
        :completed="completedItem"
        translation-key="profile.edition.completion.panel.checklist.item.DESCRIPTION"
        @click="handleClick"
    />
</template>

<script setup lang="ts">
    import {eventBus} from '@/config/events';
    import CompletionItem from './CompletionItem.vue';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';
    import {useFeatureContext} from '~/store/featureStore';

    withDefaults(defineProps<{completedItem: boolean}>(), {completedItem: false});

    const {features} = useFeatureContext();

    function handleClick() {
        eventBus.emit('description:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_GAUGE});
    }
</script>
