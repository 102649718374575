<template>
    <div class="profile-view-my-profile">
        <VJoyLink :href="`/profile/${props.seoAlias}?overview`" target="_blank">
            {{ t('editable.profile.action.see.public') }}
        </VJoyLink>
    </div>
</template>

<script setup lang="ts">
    import {useTranslation} from '#imports';
    import {VJoyLink} from '@maltjoy/core-vue';

    const props = defineProps<{
        seoAlias: string;
    }>();

    const {t} = useTranslation();
</script>

<style lang="scss" scoped>
    .profile-view-my-profile {
        margin-top: var(--joy-core-spacing-2);
    }
</style>
