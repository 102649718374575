<template>
    <div class="profile-completion-checklist__item" :class="{'profile-completion-checklist__item-completed': completed}">
        <VJoyIcon :name="getIconName" size="medium" :color="getIconColor" bicolor></VJoyIcon>
        <a v-if="link && !completed" :href="link" target="_blank">
            {{ $t(translationKey) }}
        </a>
        <template v-else>
            {{ $t(translationKey) }}
        </template>
    </div>
</template>

<script setup lang="ts">
    import {computed} from '#imports';
    import {VJoyIcon} from '@maltjoy/core-vue';

    const props = defineProps<{
        translationKey: string;
        link?: string;
        completed?: boolean;
        important?: boolean;
    }>();

    const getIconName = computed(() => {
        if (props.completed) {
            return 'check-circle';
        }
        if (props.important) {
            return 'warning';
        }
        return 'circle';
    });

    const getIconColor = computed(() => {
        if (props.completed) {
            return 'secondary';
        }
        if (props.important) {
            return 'warning';
        }
        return 'secondary';
    });
</script>

<style lang="scss" scoped>
    .profile-completion-checklist {
        &__item {
            align-items: center;
            display: flex;
            background: none;
            column-gap: 8px;
            border: 0;
            padding: 0;
            cursor: pointer;
            a {
                color: var(--joy-color-text-body);
                text-decoration: none;
            }
            a:hover,
            a:focus {
                text-decoration: none;
            }
        }
        &__item-completed {
            pointer-events: none;
        }
    }
</style>
