import {defineStore} from 'pinia';
import {Siblings} from '~/types/domain/Siblings';
import {ref} from '#imports';
import type {Profile} from '~/types/domain/Profile';
import {siblingsRepository} from '~/types/infra/repository/SiblingsRepositoryApi';

export const useSiblingStore = defineStore('siblings', () => {
    const siblings = ref<Siblings>(Siblings.default());

    async function fetchSiblings(searchId: string, query: string, profile: Profile): Promise<void> {
        siblings.value = await siblingsRepository.fetchSiblings(searchId, query, profile);
    }

    return {
        siblings,
        fetchSiblings,
    };
});
