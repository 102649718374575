<template>
    <li
        class="profile-indicators-item"
        :class="{'profile-indicators-item-admin': editMode}"
        data-testid="profile-level-indicator"
        @click="handleEdit"
    >
        <span class="profile-indicators-title">{{ $t('profile.numeral.infos.experience.level.label') }}</span>
        <span class="profile-indicators-content">{{ experienceLevelValue }}</span>
    </li>
</template>

<script setup lang="ts">
    import {computed, useTranslation} from '#imports';
    import type {ExperienceLevel} from '~/types/domain/Profile';

    const props = defineProps<{
        editMode?: boolean;
        experienceLevel?: ExperienceLevel;
    }>();

    const emits = defineEmits<{
        (e: 'edit'): void;
    }>();

    const {t} = useTranslation();

    const experienceLevelValue = computed((): string => {
        if (!props.experienceLevel) {
            return '-';
        }

        return t(`profile.creation.completion.wizard.categorization.experienceLevel.label.v2.${props.experienceLevel}`);
    });

    function handleEdit(): void {
        props.editMode && emits('edit');
    }
</script>
