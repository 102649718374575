<template>
    <div v-if="profile" class="profile-header__grid">
        <ProfileSiblings
            v-if="enableSiblings"
            :siblings="siblings"
            :search-id="(searchId as string)"
            :query-params="queryParams"
            :is-connected="loggedIn"
        />
        <div class="profile-header__grid-wrapper">
            <PhotoSection
                class="avatar"
                :edit-mode="profile?.editMode"
                :photo="profile?.photo"
                :display-name="displayName"
                :profession="profile.headline"
                @edit="openPhotoEditDialog"
            />
            <ProfileHeaderMiddleSection
                class="infos"
                data-testid="profile-view-header-middle-section"
                :display-name="displayName"
                :headline="profile.headline"
                :edit-mode="profile.editMode"
                @edit="openEditHeadlineDialog"
            >
                <template v-if="hasAccessToWishlist" #wishlist>
                    <WishlistIndicator />
                </template>
                <template v-if="isBadgeSectionVisible" #badges>
                    <BadgeReadSection :badge="profile.badge" :firstname="profile.firstname" />
                </template>
                <template #counter>
                    <CounterReadSection
                        :mission-count="appraisals?.missionCount"
                        :general-rating="appraisals?.ratings.general"
                        :number-of-appraisals-with-rating="appraisals?.numberOfAppraisalsWithRating"
                        :recommendations-count="recommendations?.recommendationsCount"
                        :edit-mode="profile.editMode"
                        @edit-recommendations="scrollToAnchor('#recommendationSection')"
                        @edit-appraisals="scrollToAnchor('#appraisalSection')"
                    />
                    <ProfileRelations v-if="relations?.relations?.length" :relations="relations" :freelancer-firstname="profile.firstname" />
                </template>
                <template #seeMyProfile>
                    <ViewMyProfile :seo-alias="profile.seoAlias" />
                </template>
            </ProfileHeaderMiddleSection>
            <div class="more">
                <ProfileIndicators :profile="profile" @edit-price="openPriceDialog" @edit-experience="openExperienceLevelDialog" />
            </div>
            <VJoyWrapper v-if="editMode" class="actions">
                <ShareProfileDialog />
            </VJoyWrapper>
            <ProjectProposalOrShortlistChoice v-if="!editMode" class="actions" />
            <AvailabilitySection v-if="loggedIn && !editMode" class="availability" />
        </div>
        <ExternalUserActionShortlistModeSelection v-if="!editMode" />
        <ExternalUserActionWishlist v-if="!editMode && loggedIn" />
        <ExternalUserActionAddWishlist v-if="!editMode && loggedIn" />
    </div>
</template>

<script setup lang="ts">
    import {computed, extractQueryParameters, onMounted, useAuth} from '#imports';
    import ShareProfileDialog from '~/features/share-profile/ui/ShareProfileDialog.vue';
    import {useSectionsVisibility} from '@/composables/useSectionsVisibility';
    import {eventBus} from '@/config/events';
    import {useProfileStore} from '@/store/profileStore';
    import {useRoute} from '@typed-router';
    import {storeToRefs} from 'pinia';
    import {onUnmounted} from 'vue';
    import ExternalUserActionShortlistModeSelection from '~/components/external-user-action/ExternalUserActionShortlistModeSelection.vue';
    import ExternalUserActionAddWishlist from '~/components/external-user-action/wishlist/ExternalUserActionAddWishlist.vue';
    import ExternalUserActionWishlist from '~/components/external-user-action/wishlist/ExternalUserActionWishlist.vue';
    import AvailabilitySection from '~/components/header/AvailabilitySection.vue';
    import BadgeReadSection from '~/components/header/BadgeReadSection.vue';
    import CounterReadSection from '~/components/header/CounterReadSection.vue';
    import ProfileIndicators from '~/components/header/indicators/ProfileIndicators.vue';
    import WishlistIndicator from '~/components/header/indicators/WishlistIndicator.vue';
    import PhotoSection from '~/components/header/PhotoSection.vue';
    import ProfileHeaderMiddleSection from '~/components/header/ProfileHeaderMiddleSection.vue';
    import ProfileRelations from '~/components/header/ProfileRelations.vue';
    import ProfileSiblings from '~/components/header/ProfileSiblings.vue';
    import ProjectProposalOrShortlistChoice from '~/components/header/ProjectProposalOrShortlistChoice.vue';
    import ViewMyProfile from '~/components/header/ViewMyProfile.vue';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';
    import {useSiblingStore} from '~/store/siblings/siblingStore';
    import {TranslationDirection, useTranslationStore} from '~/store/translation/translationStore';
    import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';
    import {useFeatureContext} from '~/store/featureStore';
    import {VJoyWrapper} from '@maltjoy/core-vue';

    const route = useRoute();

    const {loggedIn, currentIdentityIsClient} = storeToRefs(useAuth());

    const profileStore = useProfileStore();
    const {profile, displayName, appraisals, recommendations, relations} = storeToRefs(profileStore);
    const {fetchSiblings} = useSiblingStore();
    const {features} = useFeatureContext();
    const {siblings} = storeToRefs(useSiblingStore());
    const editMode = computed(() => profile.value?.editMode || false);
    const query = computed(() => route?.query?.q);
    const searchId = computed(() => route?.query?.searchid);
    const comesFromSearch = computed((): boolean => searchId.value !== undefined);
    const enableSiblings = computed((): boolean => editMode.value === false && comesFromSearch.value === true);
    const queryParams = extractQueryParameters(route);
    const hasAccessToWishlist = computed(() => loggedIn.value && currentIdentityIsClient.value);

    const {translateAll, replaceTranslationContent} = useTranslationStore();
    const translationSection = 'headline';
    const translationField = 'headline';

    const isBadgeSectionVisible = computed(() => useSectionsVisibility(SectionsVisibilityType.BADGES));

    function openPhotoEditDialog(): void {
        eventBus.emit('photo:edit-mode');
    }

    function openEditHeadlineDialog(): void {
        eventBus.emit('headline:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
    }

    function openPriceDialog(): void {
        eventBus.emit('price:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
    }

    function openExperienceLevelDialog(): void {
        eventBus.emit('experience-level:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
    }

    function scrollToAnchor(anchor: string, headerOffset = 100): void {
        const domElement = document.querySelector(anchor);
        if (domElement) {
            const element = domElement as HTMLElement;
            window.scrollTo({
                behavior: 'smooth',
                left: 0,
                top: element.offsetTop - headerOffset,
            });
        }
    }

    async function loadSiblings() {
        await fetchSiblings(searchId.value as string, query.value as string, profile.value!);
    }

    async function translateHeadline() {
        const headlineRequest = {
            section: translationSection,
            field: translationField,
            index: 0,
            originalContent: profile.value!.headline,
        };

        await translateAll([headlineRequest]);
        replaceTranslationContent(translationSection, translationField, TranslationDirection.TRANSLATE, switchTranslation);
    }

    function restoreHeadline() {
        replaceTranslationContent(translationSection, translationField, TranslationDirection.RESTORE, switchTranslation);
    }

    function switchTranslation(content: string) {
        profile.value!.headline = content;
    }

    onMounted(async () => {
        eventBus.on('translation:request', translateHeadline);
        eventBus.on('translation:restore', restoreHeadline);
        if (enableSiblings.value === true) {
            await loadSiblings();
        }
    });

    onUnmounted(() => {
        eventBus.off('translation:request', translateHeadline);
        eventBus.off('translation:restore', restoreHeadline);
    });
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    .siblings {
        position: absolute;
        top: 50%;
        width: 100%;

        @include mq.screen_md {
            position: relative;
        }

        &-left {
            left: -20px;
            position: absolute;

            @include mq.screen_md {
                left: 0;
                position: relative;
            }

            @include mq.screen_xs {
                left: 0;
                position: relative;
                border-radius: 50%;
                background-color: #fff;
                padding: 10px;
            }
        }

        &-right {
            position: absolute;
            right: -20px;

            @include mq.screen_md {
                left: 0;
                position: relative;
                float: right;
            }

            @include mq.screen_xs {
                left: 0;
                position: relative;
                float: right;
                border-radius: 50%;
                background-color: #fff;
                padding: 10px;
            }
        }
    }
</style>
<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .profile-header__grid {
        position: relative;
    }

    .profile-header {
        &__grid-wrapper {
            position: relative;
            display: grid;

            grid-template-columns: var(--template-sidebar-width) 1fr auto;
            grid-template-rows: auto;
            gap: var(--joy-core-spacing-8);
            grid-auto-flow: row;
            grid-template-areas:
                'avatar infos actions'
                'avatar infos availability'
                'avatar more more';

            @include mq.screen_md {
                grid-template-columns: auto 1fr auto;
                grid-template-areas:
                    'avatar infos availability'
                    'avatar more more'
                    'avatar actions actions';
            }

            @include mq.screen_xs {
                gap: var(--joy-core-spacing-2);
                grid-template-areas:
                    'avatar avatar avatar'
                    'infos infos infos'
                    'more more more'
                    'availability availability availability';
            }
            .avatar {
                grid-area: avatar;
                align-items: flex-start;
                justify-content: center;
                display: flex;
            }
            .actions {
                grid-area: actions;
            }
            .infos {
                grid-area: infos;
                min-width: 260px;
            }
            .more {
                grid-area: more;
            }

            .availability {
                max-width: 220px;
                grid-area: availability;
            }
        }

        &__tag {
            margin: var(--joy-core-spacing-3) 0;
        }
    }
</style>
