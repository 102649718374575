<template>
    <VJoyButton
        v-if="!props.disabled"
        :data-testid="`share-profile-social-network--${socialNetwork}`"
        circle
        :href="socialNetworkLink"
        :icon="socialNetworkIcon"
        target="_blank"
        variant="secondary"
    ></VJoyButton>
    <VJoyButton
        v-else
        :data-testid="`share-profile-social-network--${socialNetwork}--disabled`"
        circle
        disabled
        :icon="socialNetworkIcon"
        variant="secondary"
    ></VJoyButton>
</template>
<script setup lang="ts">
    import {useTranslation} from '#imports';
    import {computed} from 'vue';
    import {VJoyButton} from '@maltjoy/core-vue';

    type ShareProfileSocialNetworkProps = {
        profileLink: string;
        profileFirstname: string;
        profileLastname: string;
        profileHeadline: string;
        socialNetwork: 'linkedin' | 'facebook';
        disabled?: boolean;
    };
    const props = defineProps<ShareProfileSocialNetworkProps>();

    // Composables
    const {t} = useTranslation();

    // Computed values
    const socialNetworkIcon = computed(() => {
        switch (props.socialNetwork) {
            case 'linkedin':
                return 'linkedin';
            case 'facebook':
                return 'facebook';
            default:
                return 'social-share';
        }
    });
    const socialNetworkLink = computed(() => {
        switch (props.socialNetwork) {
            case 'linkedin':
                return linkedinSharingLink.value;
            case 'facebook':
                return facebookSharingLink.value;
            default:
                return undefined;
        }
    });
    const message = computed(() => t('profile.static.ogtitle', {0: props.profileFirstname + ' ' + props.profileLastname, 1: props.profileHeadline}));
    const facebookSharingLink = computed(() => `https://www.facebook.com/sharer/sharer.php?u=${props.profileLink}`);
    const linkedinSharingLink = computed(() => `https://www.linkedin.com/shareArticle?mini=true&url=${props.profileLink}`);
</script>
