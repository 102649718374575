<template>
    <CompletionItem
        important
        :completed="completedItem"
        translation-key="profile.edition.completion.panel.checklist.item.PHOTO"
        @click="handleClick"
    />
</template>

<script setup lang="ts">
    import {eventBus} from '@/config/events';
    import CompletionItem from './CompletionItem.vue';

    withDefaults(defineProps<{completedItem: boolean}>(), {completedItem: false});

    function handleClick() {
        eventBus.emit('photo:edit-mode');
    }
</script>
