<template>
    <li v-if="isVisible" data-testid="average-answer-time-indicator" class="profile-indicators-item">
        <span class="profile-indicators-title">{{ $t('profile.numeral.infos.answer.time.label') }}</span>
        <span class="profile-indicators-content">
            {{ $t(`profile.stats.response.time.${averageAnswerTimeCode}`) }}
        </span>
    </li>
</template>

<script setup lang="ts">
    import {computed} from 'vue';

    const props = defineProps<{
        averageAnswerTimeCode?: string;
    }>();

    const isVisible = computed(() => props.averageAnswerTimeCode);
</script>
