import {defineStore} from 'pinia';
import {ref} from '#imports';
import {Wishlists} from '~/types/domain/Wishlist';
import {wishlistRepository} from '../../types/infra/repository/WishlistRepositoryApi';
import type {Profile} from '~/types/domain/Profile';

export const useWishlistStore = defineStore('wishlist', () => {
    const wishlists = ref<Wishlists>(Wishlists.default());

    async function getWishlists(profile: Profile) {
        wishlists.value = await wishlistRepository.fetchWishlists(profile);
    }

    async function createWishlist(wishlistName: string, profile: Profile) {
        await wishlistRepository.createWishlist(wishlistName, profile);
        await getWishlists(profile);
    }

    async function addToWishlist(wishlistId: string, profile: Profile) {
        await wishlistRepository.addToWishlist(wishlistId, profile);
        await getWishlists(profile);
    }

    async function removeFromWishlist(wishlistId: string, profile: Profile) {
        await wishlistRepository.removeFromWishlist(wishlistId, profile);
        await getWishlists(profile);
    }

    return {
        wishlists,
        getWishlists,
        createWishlist,
        addToWishlist,
        removeFromWishlist,
    };
});
