<template>
    <div
        ref="readMoreContentElement"
        :class="{'read-more': isReadMoreActivated && isReadMoreLinkVisible}"
        :data-testid="`${props.dataTestid}-content`"
    >
        <div v-if="isReadMoreActivated && isReadMoreLinkVisible" class="read-more__link">
            <VJoyButton
                :id="`${props.id}`"
                class="read-more__link-button"
                size="small"
                variant="ghost"
                :icon="icon"
                :data-testid="`${props.dataTestid}-read-more-link`"
                @click="readMoreHandleClick"
            >
                {{ viewMoreLabel }}
            </VJoyButton>
        </div>
        <slot class="read-more__content" />
    </div>
</template>
<script setup lang="ts">
    import {useSectionsVisibility} from '#imports';
    import {computed, ref, watch} from 'vue';
    import {useProfileStore} from '~/store/profileStore';
    import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';
    import {VJoyButton} from '@maltjoy/core-vue';

    const {profile} = useProfileStore();

    const props = defineProps({
        maxHeight: {type: Number, default: 180},
        dataTestid: {type: String, default: 'read-more-component'},
        viewMoreLabel: {type: String, required: true},
        id: {type: String, required: false, default: ''},
        componentName: {type: String, required: true},
    });

    const isConsultingHisOwnProfile = ref(profile?.editMode);

    const isReadMoreLinkVisible = ref(true);
    const readMoreContentElement = ref<HTMLElement>();
    const readMoreContentHeight = computed(() => readMoreContentElement.value?.scrollHeight || 0);
    const isHigherThanDefinedMaxHeight = computed(() => readMoreContentHeight.value > props.maxHeight);
    const isReadMoreActivated = computed(() => isConsultingHisOwnProfile.value === false);
    const maxHeightCssVariable = `${props.maxHeight}px`;
    const viewMoreLock = useSectionsVisibility(SectionsVisibilityType.VIEW_MORE_LOCK);

    const emits = defineEmits<{
        (e: 'update:showMore', value: boolean): void;
    }>();

    const icon = computed(() => (!viewMoreLock ? 'add' : 'lock'));

    watch(readMoreContentHeight, () => {
        isReadMoreLinkVisible.value = isHigherThanDefinedMaxHeight.value === true && isConsultingHisOwnProfile.value === false;
    });

    function readMoreHandleClick(event: Event) {
        if (!viewMoreLock) {
            readMoreHandleClickConnectedUser();
        } else {
            readMoreHandleClickNotConnectedUser();
        }
        // Preserve from opening a module in edition mode
        event.stopPropagation();
    }

    function readMoreHandleClickConnectedUser() {
        isReadMoreLinkVisible.value = false;
        emits('update:showMore', true);
    }

    function readMoreHandleClickNotConnectedUser() {
        const path = window.location.pathname;
        const hashToUse = `#${props.id}`;
        const redirectParam = encodeURIComponent(path + hashToUse);

        window.location.assign(`/who-are-you?redirect=${redirectParam}&pageSource=profile_page&componentSource=${props.componentName}`);
    }
</script>
<style scoped lang="scss">
    .read-more {
        max-height: v-bind(maxHeightCssVariable);
        overflow: hidden;

        &__link {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 33px 0 0 0;
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 100%);
            z-index: calc(var(--header-z-index) - 2);

            &-button {
                flex-wrap: wrap;
                white-space: pre-wrap;
            }
        }
        &.show-all-content {
            max-height: none;
            overflow: visible;
        }
    }
</style>
