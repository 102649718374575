import type {Certification} from '~/types/domain/Certifications';
import type {CertificationRequest} from './CertificationRequest';

class CertificationMapper {
    toRequest(certification: Certification, index: number): CertificationRequest {
        return {
            id: certification.id,
            index,
            organization: certification.organization,
            link: certification.link,
            graduationYear: certification.graduationYear,
            title: certification.title,
            current: certification.graduationYear == null,
            skills: certification.skills.map((skill) => {
                return {label: skill};
            }),
        };
    }
}

export const certificationMapper = new CertificationMapper();
