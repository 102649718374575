<template>
    <VJoyDialogTrigger :dialog="dialogRef">
        <VJoyButton data-testid="freelancer-share-profile-cta-open-dialog" icon="social-share" size="medium" variant="secondary">
            {{ t('profile.features.share-profile.title') }}
        </VJoyButton>
    </VJoyDialogTrigger>
    <VJoyDialog ref="dialogRef" data-testid="freelancer-share-profile-dialog" @dialog:hide="handleSeoDialogHide">
        <template #dialog-header>{{ t('profile.features.share-profile.title') }}</template>
        <template #dialog-body>
            <VJoyWrapper v-bind="{direction: 'column', align: 'stretch', rowGap: '6'}" class="freelancer-share-profile__wrapper">
                <div>
                    <h2>{{ t('profile.features.share-profile.link.title') }}</h2>
                    <div>
                        <VJoyWrapper :align="'flex-end'" class="freelancer-share-profile__seo-alias" no-margin>
                            <VJoyInput
                                data-testid="freelancer-seo-alias-input"
                                name="freelancer-seo-alias"
                                :model-value="state.seoAlias"
                                :invalid="isEditingSeoAlias && (!!errorSeoAlias || v$.$invalid)"
                                @focus="handleSeoAliasInputFocus"
                                @update:model-value="handleSeoAliasInputUpdate"
                                @keypress.enter="handleClickOnSave"
                            ></VJoyInput>
                            <VJoyButton
                                v-if="isEditingSeoAlias"
                                data-testid="freelancer-seo-alias-input-cta-save"
                                :loading="isSavingSeoAlias"
                                @click="handleClickOnSave"
                            >
                                {{ t('generic.form.save.label') }}
                            </VJoyButton>
                            <VJoyButton v-else data-testid="freelancer-seo-alias-input-cta-copy" @click="handleClickOnCopy">
                                {{ t('generic.action.copy') }}
                            </VJoyButton>
                        </VJoyWrapper>
                        <VJoyFormError v-if="isEditingSeoAlias && errorSeoAlias" :no-html-error-text="errorSeoAlias" />
                        <VJoyFormError
                            v-if="isEditingSeoAlias && v$.$errors && v$.$errors.length > 0"
                            :no-html-error-text="`${v$.$errors[0].$message}`"
                        />
                    </div>
                </div>
                <div>
                    <h2>{{ t('profile.features.share-profile.social-networks.title') }}</h2>
                    <VJoyWrapper no-margin>
                        <ShareProfileSocialNetwork
                            :disabled="isEditingSeoAlias"
                            :profile-link="profileLink"
                            :profile-firstname="profile?.firstname || ''"
                            :profile-lastname="profile?.lastname || ''"
                            :profile-headline="profile?.headline || ''"
                            social-network="linkedin"
                        />
                        <ShareProfileSocialNetwork
                            :disabled="isEditingSeoAlias"
                            :profile-link="profileLink"
                            :profile-firstname="profile?.firstname || ''"
                            :profile-lastname="profile?.lastname || ''"
                            :profile-headline="profile?.headline || ''"
                            social-network="facebook"
                        />
                    </VJoyWrapper>
                </div>
            </VJoyWrapper>
        </template>
    </VJoyDialog>
</template>
<script setup lang="ts">
    import {useRequestURL, useTranslation} from '#imports';
    import {copyToClipboard} from '@malt/dom';
    import {pushVJoySnackbar, VJoyButton, VJoyDialog, VJoyDialogTrigger, VJoyFormError, VJoyInput, VJoyWrapper} from '@maltjoy/core-vue';
    import {useRouter} from '@typed-router';
    import useVuelidate from '@vuelidate/core';
    import {helpers, required} from '@vuelidate/validators';
    import {computed, reactive, ref, watch} from 'vue';
    import ShareProfileSocialNetwork from '~/components/share-profile/ShareProfileSocialNetwork.vue';
    import {useProfileStore} from '~/store/profileStore';
    // Composables
    const {t} = useTranslation();
    const {profile, updateSeoUrl} = useProfileStore();
    const router = useRouter();
    const requestUrl = useRequestURL();

    // Refs
    const dialogRef = ref<InstanceType<typeof VJoyDialog>>();
    const errorSeoAlias = ref<string>();
    const hasBeenUpdated = ref<boolean>(false);
    const isEditingSeoAlias = ref<boolean>(false);
    const isSavingSeoAlias = ref<boolean>(false);
    const currentProfileSeoAlias = ref<string>(profile?.seoAlias || '');
    const state = reactive({
        seoAlias: getProfileUrl(profile?.seoAlias || ''),
    });

    // Computed
    const profileLink = computed((): string => getProfileUrl(currentProfileSeoAlias.value || ''));

    // TODO validation
    const customSeoAliasValidator = (value: string): boolean => /^[A-Za-z0-9]+-?[A-Za-z0-9]+$/.test(value);
    const rules = {
        seoAlias: {
            required,
            customValidation: helpers.withMessage(t('profile.resource.bad.parameter.seo.url.pattern'), customSeoAliasValidator),
        },
    };
    const v$ = useVuelidate(rules, state, {$lazy: true});

    watch(isEditingSeoAlias, (newValueOfIsEditingSeoAlias) => {
        if (newValueOfIsEditingSeoAlias === true) {
            state.seoAlias = currentProfileSeoAlias.value;
        } else {
            state.seoAlias = getProfileUrl(state.seoAlias);
        }
    });

    // Handlers
    function handleClickOnCopy() {
        if (profileLink.value) {
            copyToClipboard(profileLink.value);
            pushVJoySnackbar({
                props: {
                    message: t('copy.to.clipboard.message'),
                },
            });
        }
    }

    async function handleClickOnSave(event: Event) {
        await v$.value.$validate();
        if (v$.value.$invalid) {
            return;
        }

        isSavingSeoAlias.value = true;
        errorSeoAlias.value = undefined;
        hasBeenUpdated.value = false;

        try {
            await updateSeoUrl({
                url: state.seoAlias,
                urlWithoutInvariant: '',
            });
            currentProfileSeoAlias.value = state.seoAlias;
            isEditingSeoAlias.value = false;

            pushVJoySnackbar({
                props: {
                    message: t('account.update.success'),
                },
            });

            // Unfocus the field when pressing key Enter
            (event.target as HTMLElement)?.blur();
        } catch (error: any) {
            const {details} = error.response._data;

            if (details && details.length) {
                errorSeoAlias.value = details[0].error;
            } else {
                pushVJoySnackbar({
                    props: {
                        message: t('profile.creation.common.notification.error.technical'),
                        level: 'error',
                    },
                });
            }
        }

        isSavingSeoAlias.value = false;
    }

    function handleSeoAliasInputFocus() {
        isEditingSeoAlias.value = true;
        errorSeoAlias.value = undefined;
    }

    function handleSeoAliasInputUpdate(newSeoAlias: string) {
        state.seoAlias = newSeoAlias;
    }

    function handleSeoDialogHide() {
        router.push(currentProfileSeoAlias.value);
    }

    function getProfileUrl(seoAlias: string): string {
        return `${requestUrl.origin}/profile/${seoAlias}`;
    }
</script>
<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/text' as text;

    .freelancer-share-profile {
        &__wrapper {
            h2 {
                @include text.getFontBySize(primary-450);
                margin-bottom: var(--joy-core-spacing-2);
            }
        }
        &__seo-alias {
            .joy-input--medium {
                flex-grow: 1;
            }
        }
    }
</style>
