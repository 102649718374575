<template>
    <div class="task-cards-section">
        <Transition :name="taskCardRotationIsGoingToNext ? 'slide-left' : 'slide-right'" mode="out-in">
            <Component
                :is="CurrentTaskCardComponent"
                v-if="CurrentTaskCardComponent && currentTaskCard"
                :card-type="currentTaskCard.type"
                :user-name="firstname"
                :data-testid="`task-cards-${specificComponentName.toLowerCase()}-${profileVertical.toLowerCase()}`"
            />
        </Transition>
    </div>
</template>

<script setup lang="ts">
    import {ProfileEdtionDataDeletedEvents, ProfileEdtionDataUpdatedEvents, eventBus} from '@/config/events';
    import {useProfileStore} from '@/store/profileStore';
    import {useTaskCardsStore} from '@/store/task-cards/taskCardsStore';
    import {storeToRefs} from 'pinia';
    import {computed, defineAsyncComponent, onBeforeUnmount, onMounted, ref, shallowRef, watch} from 'vue';
    import {ProfileVertical} from '~/types/domain/Profile';

    defineProps<{
        firstname: string;
    }>();

    const taskCardsStore = useTaskCardsStore();
    const {currentTaskCard, currentDisplayedPosition, taskCardsSize} = storeToRefs(taskCardsStore);
    const profileStore = useProfileStore();
    const {profile} = storeToRefs(profileStore);
    const taskCardRotationIsGoingToNext = ref(true);

    await taskCardsStore.getTaskCards();

    const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
    const kebabToCamel = (str: string) => str.replace(/_./g, (x: string) => x[1].toUpperCase());

    watch(currentDisplayedPosition, (newValue, oldValue) => {
        const diff = newValue - oldValue;
        taskCardRotationIsGoingToNext.value = taskCardsSize.value === 2 ? newValue === 1 : diff === 1 || diff === -(taskCardsSize.value - 1);
    });

    watch(currentTaskCard, () => {
        CurrentTaskCardComponent.value = _dynamicallyLoadComponentOnDemand();
    });

    const specificComponentName = computed(() => {
        const type = currentTaskCard.value?.type.toLowerCase() || '';
        return capitalizeFirstLetter(kebabToCamel(type));
    });
    const profileVertical = computed(() => capitalizeFirstLetter(kebabToCamel(profile.value?.vertical?.toLowerCase() || ProfileVertical.FREELANCER)));

    const CurrentTaskCardComponent = shallowRef(_dynamicallyLoadComponentOnDemand());

    const updateAndDeleteEvents = {...ProfileEdtionDataUpdatedEvents, ...ProfileEdtionDataDeletedEvents};

    function createEventHandlersForTaskCardsUpdate() {
        Object.entries(updateAndDeleteEvents).forEach(([, value]) => {
            eventBus.on(value, async () => {
                await taskCardsStore.getTaskCards();
                taskCardsStore.displayNextTaskCard();
            });
        });
    }

    function stopEventHandlersForTaskCardsUpdate() {
        Object.entries(updateAndDeleteEvents).forEach(([, value]) => {
            eventBus.off(value, async () => {
                await taskCardsStore.getTaskCards();
                taskCardsStore.displayNextTaskCard();
            });
        });
    }

    function _dynamicallyLoadComponentOnDemand() {
        if (!currentTaskCard.value || !specificComponentName.value || !profileVertical.value) {
            return undefined;
        }

        // /!\ The folder containing the component MUST BE equal to specificComponentName /!\
        const componentName = `TaskCardsTypes${specificComponentName.value}${profileVertical.value}`;
        const componentFolder = specificComponentName.value.toLowerCase();
        const componentPath = `./types/${componentFolder}/${componentName}.vue`;

        return defineAsyncComponent({
            // the loader function
            loader: () => import(`./types/${componentFolder}/${componentName}.vue`),
            timeout: 3000,
            // As it's not a "Mandatory component": handle all errors except in dev mode
            onError: (error: Error) => {
                if (process.dev) {
                    console.error(`Can't load dynamic component ${componentName}.\nEnsure this components exists: ${componentPath}`);
                    throw error;
                }
            },
        });
    }

    onMounted(() => {
        createEventHandlersForTaskCardsUpdate();
    });

    onBeforeUnmount(() => {
        stopEventHandlersForTaskCardsUpdate();
    });
</script>

<style scoped lang="scss">
    $profile-edition-task-card-transition-duration: 500ms;

    .task-cards-section {
        overflow: hidden;
    }
    .skills-list {
        margin-top: 20px;

        joy-tag {
            cursor: pointer;
        }
    }

    .slide-right-enter-active {
        animation: slideRightEnter $profile-edition-task-card-transition-duration ease-in forwards;
    }

    .slide-right-leave-active {
        animation: slideRightLeave $profile-edition-task-card-transition-duration ease-in forwards;
    }

    @keyframes slideRightEnter {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0);
        }
    }

    @keyframes slideRightLeave {
        0% {
            transform: translateX(0);
            opacity: 1;
        }
        100% {
            transform: translateX(100%);
            opacity: 0;
        }
    }

    .slide-left-enter-active {
        animation: slideLeftEnter $profile-edition-task-card-transition-duration ease-out forwards;
    }

    .slide-left-leave-active {
        animation: slideLeftLeave $profile-edition-task-card-transition-duration ease-out forwards;
    }

    @keyframes slideLeftEnter {
        0% {
            transform: translateX(100%);
            // opacity: 0;
        }
        100% {
            transform: translateX(0);
            // opacity: 1;
        }
    }

    @keyframes slideLeftLeave {
        0% {
            transform: translateX(0);
            // opacity: 1;
        }
        100% {
            transform: translateX(-100%);
            // opacity: 0;
        }
    }
    .slide-item {
        transform: translateX(-100%);
    }
</style>
