import {computed, ref} from '#imports';
import {useRoute} from '@typed-router';
import {defineStore, storeToRefs} from 'pinia';
import type {Shortlist, ShortlistItem} from '~/types/domain/Shortlist';
import {shortlistRepository} from '~/types/infra/repository/ShortlistRepositoryAPI';
import {useProfileStore} from '../profileStore';

export const useShortlistStore = defineStore('shortlist', () => {
    const route = useRoute();
    const profileStore = useProfileStore();

    const {profile} = storeToRefs(profileStore);

    const shortlist = ref<Shortlist | null>(null);

    const findItemByAccountId = computed((): ShortlistItem | undefined =>
        shortlist.value?.items.find((item) => item.accountId === profile.value!.accountId),
    );

    const isContained = computed((): boolean => {
        if (!shortlist.value?.items) {
            return false;
        }
        return shortlist.value.items.some((item: ShortlistItem) => item.accountId === profile.value?.accountId);
    });

    const comesFromSearch = computed((): boolean => route?.query?.searchid !== undefined);
    const hasShortlistItems = computed((): boolean => (shortlist.value?.items && shortlist.value.items.length > 0) || false);
    const profileInShortlist = computed((): boolean => hasShortlistItems.value && isContained.value);

    const accountIds = computed(() =>
        shortlist.value && shortlist.value.items.length > 0
            ? shortlist.value.items.map((item: any) => item.accountId).concat(profile.value!.accountId)
            : [profile.value!.accountId],
    );

    async function reload(): Promise<Shortlist | null | undefined> {
        shortlist.value = await shortlistRepository.reloadShortlist(profile.value!);

        return shortlist.value;
    }

    async function add(): Promise<string> {
        return await shortlistRepository.upsertShortlist(profile.value!, {
            searchId: route?.query?.searchid?.toString() ?? null,
            title: route?.query?.q as string,
            profileViewId: profile.value!.profileViewId,
            accountIds: accountIds.value,
        });
    }

    async function remove(profileId?: string): Promise<void> {
        const id = profileId || findItemByAccountId.value?.profileId;
        if (id) {
            await shortlistRepository.removeFromShortlist(profile.value!, id);
            await reload();
        }
    }

    return {
        comesFromSearch,
        hasShortlistItems,
        profileInShortlist,
        shortlist,
        add,
        reload,
        remove,
    };
});
