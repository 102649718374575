<template>
    <VJoyDialog id="removalExperienceConfirmDialog" ref="removeDialog" pattern-confirm :cancel-text="t('general.cancel')">
        <template #dialog-header>{{ t('generic.form.confirm.text') }}</template>
        <template #dialog-confirm>
            <VJoyButton variant="main" data-testid="remove-experience-confirm-button" @click="removeExperience">
                {{ $t('general.continue') }}
            </VJoyButton>
        </template>
    </VJoyDialog>

    <VJoyPanel :id="anchorsPageProfile.experienceSection" data-testid="profile-experience-section">
        <template #panel-title>
            {{ t('profile.section.experiences.title') }}
        </template>
        <template #panel-title-action>
            <VJoyWrapper>
                <TranslateButton v-if="isTranslationActivated && experienceRequests.state.length > 0" section="experience" />
                <VJoyButton
                    v-if="editMode"
                    circle
                    size="small"
                    variant="ghost"
                    icon="sort"
                    :title="t('profile.section.experiences.sort.title')"
                    @click="sortExperiencesByDate"
                />
                <VJoyButton
                    v-if="editMode"
                    data-testid="profile-experience-add"
                    circle
                    size="small"
                    variant="ghost"
                    icon="add"
                    @click="openExperiencesAddDialog"
                />
                <VJoyTooltip v-if="editMode" :text="t('profile.experience.import.selection.section.any_resume.subtitle')">
                    <VJoyTag id="profile-experience-edition-tag-new" :label="t('general.new')" variant="special" />
                </VJoyTooltip>
            </VJoyWrapper>
        </template>

        <template #panel-body>
            <ul class="profile-experiences__list">
                <div v-if="experienceRequests.state.length > 0">
                    <Draggable
                        v-model="experienceRequests.state"
                        class="profile-experiences__list"
                        :group="{name: 'experiences', put: true, pull: true}"
                        draggable=".draggable-experience"
                        @start="dragging = true"
                        @end="dragging = false"
                        @update="dragged"
                    >
                        <li
                            v-for="element in experienceRequests.state"
                            :id="anchorsPageProfile.experienceById(element.id!)"
                            :key="element.index"
                            :value="element.index"
                            :class="{'draggable-experience': editMode, draggable: editMode}"
                            :data-testid="`profile-experience-item--${element.index}`"
                            class="profile-experiences__list-item"
                        >
                            <div class="profile-experiences__list-item-wrapper">
                                <div class="action-edit">
                                    <div class="toolbar">
                                        <VJoyWrapper column-gap="0" justify="space-between" wrap="nowrap" :item-stretch="true">
                                            <VJoyButton
                                                v-if="editMode"
                                                :data-testid="`profile-experience-edit-${element.index}`"
                                                circle
                                                variant="ghost"
                                                size="small"
                                                icon="pen"
                                                :disabled="loading"
                                                @click="openExperiencesEditDialog(element.index)"
                                            />
                                            <VJoyDialogTrigger v-if="profile!.editMode" :dialog="removeDialog" @click="trackEditionOpened">
                                                <VJoyButton
                                                    v-if="editMode"
                                                    :data-testid="`profile-experience-delete--${element.index}`"
                                                    :disabled="loading || hasOnlyOneExperience"
                                                    circle
                                                    variant="ghost"
                                                    size="small"
                                                    icon="trash"
                                                    class="toolbar-remove"
                                                    @click="idx = element.index"
                                                />
                                            </VJoyDialogTrigger>

                                            <VJoyButton v-if="editMode" circle variant="ghost" size="small" icon="bars" class="draggable" />
                                        </VJoyWrapper>
                                    </div>
                                </div>
                                <ExperienceReadOnly
                                    data-testid="experience-read-only-item"
                                    :experience="mapToExperienceItem(element)"
                                ></ExperienceReadOnly>
                            </div>
                        </li>
                    </Draggable>

                    <ShowMore
                        :total="experiencesCount"
                        :limit="EXPERIENCES_TO_DISPLAY_BY_DEFAULT"
                        test-id="toggle-show-all-experiences"
                        :label="t('profile.section.experiences.show.all.label', [experiencesCount])"
                        :locked-label="t('profile.section.experiences.private', [profile?.firstname])"
                        component-name="profile-experiences-section"
                        @expand="showExperiences(true)"
                        @collapse="showExperiences(false)"
                    />
                </div>
                <div v-else>
                    <!-- Sample Experience -->
                    <li class="profile-experiences__list-item draggable-experience sample">
                        <div data-testid="experience-read-only-sample-item" class="profile-experiences__list-item-wrapper">
                            <ExperienceReadOnly :experience="mapToExperienceItem(sampleExperience)"></ExperienceReadOnly>
                        </div>
                    </li>
                </div>
            </ul>
        </template>
    </VJoyPanel>
</template>

<script setup lang="ts">
    import {computed, onMounted, ref, useProfileTracking, useTranslation} from '#imports';
    import {pushVJoySnackbar, VJoyButton, VJoyDialog, VJoyDialogTrigger, VJoyPanel, VJoyTag, VJoyTooltip, VJoyWrapper} from '@maltjoy/core-vue';
    import {storeToRefs} from 'pinia';
    import {onUnmounted} from 'vue';
    import type {ExperienceItem} from '~/components/_common/experience/ExperienceItem';
    import ExperienceReadOnly from '~/components/_common/experience/ExperienceReadOnly.vue';
    import ShowMore from '~/components/_common/read-more/ShowMore.vue';
    import TranslateButton from '~/components/main/TranslateButton.vue';
    import {EditionOpenedEntryPoint, EditionOpenedSection, EditionOpenedWorkflow} from '~/config/TrackingEvents';
    import {anchorsPageProfile} from '~/config/anchors';
    import {eventBus} from '~/config/events';
    import {useProfileStore} from '~/store/profileStore';
    import {TranslationDirection, useTranslationStore} from '~/store/translation/translationStore';
    import type {AddressResource, LogoResource} from '~/types/api-raw-types';
    import {Experience} from '~/types/domain/Experiences';
    import {experienceMapper} from '~/types/infra/ExperienceMapper';
    import type {ExperienceRequest} from '~/types/infra/ExperienceRequest';
    import {VueDraggable as Draggable} from 'vue-draggable-plus';

    const dragging = ref<boolean>(false);
    const {t} = useTranslation();
    const {trackProfileEdition} = useProfileTracking();
    const {reorderExperiences, deleteExperience, reloadExperiences} = useProfileStore();
    const {profile, experiences} = storeToRefs(useProfileStore());

    const {isTranslationActivated} = storeToRefs(useTranslationStore());
    const {translateAll, replaceTranslationContent} = useTranslationStore();
    const translationSection = 'experiences';
    const translationTitleField = 'title';
    const translationDescriptionField = 'description';

    const experienceRequests = ref<{state: ExperienceRequest[]}>({state: []});
    const allExperiences = ref<ExperienceRequest[]>([]);

    const removeDialog = ref<InstanceType<typeof VJoyDialog>>();
    const idx = ref(0);
    const editMode = computed(() => profile.value?.editMode || false);
    const loading = ref(false);
    const hasOnlyOneExperience = computed(() => experiencesCount.value === 1);

    const EXPERIENCES_TO_DISPLAY_BY_DEFAULT = 3;
    const isShowingAllExperiences = ref<boolean>(false);
    const experiencesCount = computed(() => experiences.value?.items.length || 0);

    initDraggableList();

    function showExperiences(showAll: boolean): void {
        isShowingAllExperiences.value = showAll;
        initDraggableList();
    }

    const sampleExperience = experienceMapper.toRequest(
        new Experience({
            area: 'SOFTWARE',
            asFreelance: false,
            commentCount: 0,
            company: t('profile.section.experiences.eg.company'),
            companyId: '',
            currentJob: true,
            description: t('profile.section.experiences.eg.description'),
            endDate: '',
            id: '',
            logo: {} as LogoResource,
            organizationName: '',
            recommendationCount: 0,
            recos: [],
            startDate: '1998-09-04',
            location: {formattedAddress: 'Menlo Park, CA, USA'} as AddressResource,
            tags: ['HTML', 'CSS', 'JS', 'Photoshop'],
            title: t('profile.section.experiences.eg.job'),
        }),
        0,
    );

    function mapToExperienceItem(xpRequest: ExperienceRequest): ExperienceItem {
        return {
            company: xpRequest.company,
            title: xpRequest.title,
            activityArea: xpRequest.area,
            location: xpRequest.location?.formattedAddress,
            startDate: xpRequest.startDate,
            endDate: xpRequest.endDate,
            currentJob: xpRequest.currentJob,
            description: xpRequest.description,
            tags: xpRequest.tags,
            logoSrc: xpRequest.logo?.baseUrl ? `${xpRequest.logo.baseUrl}/${xpRequest.logo.name}` : undefined,
        };
    }

    onMounted(() => {
        eventBus.on('experiences:updated', initDraggableList);
        eventBus.on('resume-import:updated', updateExperienceList);
        eventBus.on('translation:request', translateExperiences);
        eventBus.on('translation:restore', restoreExperiences);
    });

    onUnmounted(() => {
        eventBus.off('experiences:updated', initDraggableList);
        eventBus.off('resume-import:updated', updateExperienceList);
        eventBus.off('translation:request', translateExperiences);
        eventBus.off('translation:restore', restoreExperiences);
    });

    async function translateExperiences() {
        const experienceTitleRequests = experienceRequests.value.state.map((exp) => {
            return {
                section: translationSection,
                field: translationTitleField,
                index: exp.index,
                originalContent: exp.title,
            };
        });
        const experienceDescriptionRequests = experienceRequests.value.state.map((exp) => {
            return {
                section: translationSection,
                field: translationDescriptionField,
                index: exp.index,
                originalContent: exp.description,
            };
        });

        await Promise.all([translateAll(experienceTitleRequests), translateAll(experienceDescriptionRequests)]);

        replaceTranslationContent(translationSection, translationTitleField, TranslationDirection.TRANSLATE, switchTranslation);
        replaceTranslationContent(translationSection, translationDescriptionField, TranslationDirection.TRANSLATE, switchTranslation);
    }

    async function updateExperienceList() {
        await reloadExperiences();
        initDraggableList();
    }

    function restoreExperiences() {
        replaceTranslationContent(translationSection, translationTitleField, TranslationDirection.RESTORE, switchTranslation);
        replaceTranslationContent(translationSection, translationDescriptionField, TranslationDirection.RESTORE, switchTranslation);
    }

    function switchTranslation(content: string, index: number, field: string) {
        experienceRequests.value.state[index][field] = content;
    }

    function initDraggableList() {
        allExperiences.value = experiences.value?.items.map((experience, index) => experienceMapper.toRequest(experience, index)) || [];
        const firstFewExperiences = allExperiences.value.slice(0, EXPERIENCES_TO_DISPLAY_BY_DEFAULT);
        experienceRequests.value.state = isShowingAllExperiences.value ? allExperiences.value : firstFewExperiences;
    }

    function removeExperience() {
        const experienceToDelete = experiences.value?.items.at(idx.value);
        if (experienceToDelete) {
            deleteExperience(experienceToDelete.id).then(() => {
                initDraggableList();
                removeDialog.value?.hide();
            });
        }
    }

    function trackEditionOpened() {
        trackProfileEdition(EditionOpenedSection.EXPERIENCES, EditionOpenedEntryPoint.PROFILE_PAGE, EditionOpenedWorkflow.MANUAL);
    }

    function preparePayloadAfterDrag(): string[] {
        const experienceRequestIds = experienceRequests.value.state.map((exp) => exp.id).filter((id): id is string => id !== undefined);
        if (!isShowingAllExperiences.value) {
            return [
                ...experienceRequestIds,
                ...allExperiences.value
                    .slice(EXPERIENCES_TO_DISPLAY_BY_DEFAULT)
                    .map((exp) => exp.id)
                    .filter((id): id is string => id !== undefined),
            ];
        }
        return experienceRequestIds;
    }

    async function dragged() {
        loading.value = true;
        if (experienceRequests.value.state.length > 0) {
            const payload = preparePayloadAfterDrag();
            await reorderExperiences(payload);
        }
        loading.value = false;
    }

    function openExperiencesEditDialog(idx: number) {
        eventBus.emit('experiences:edit-mode', {experienceId: idx, entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
    }

    function openExperiencesAddDialog() {
        eventBus.emit('experiences:selection-mode');
    }

    async function sortExperiencesByDate() {
        try {
            allExperiences.value.sort(experienceMapper.sortByDate);
            const experienceIds = allExperiences.value.map((experience) => experience.id as string);
            await reorderExperiences(experienceIds);
            initDraggableList();
            pushVJoySnackbar({
                props: {
                    message: t('profile.section.experiences.sort.success'),
                    level: 'success',
                },
            });
        } catch (e) {
            pushVJoySnackbar({
                props: {
                    message: t('profile.section.experiences.sort.error'),
                    level: 'error',
                },
            });
        }
    }
</script>

<style lang="scss" scoped>
    .sample {
        opacity: 0.3;
    }

    .draggable {
        cursor: grab;
    }

    .toolbar-remove :deep(.joy-icon),
    .toolbar-remove:hover :deep(.joy-icon) {
        color: var(--joy-color-error-50) !important; // I know
    }

    .action-edit {
        position: absolute;
        top: var(--joy-core-spacing-4);
        right: 0;

        @media screen and (max-width: 600px) {
            margin-left: unset;
            position: relative;
            width: 100%;

            .toolbar {
                display: block;
            }

            .joy-wrapper {
                justify-content: space-between;

                > * {
                    flex: 0 0 auto;
                }
            }
        }
    }

    @media screen and (min-width: 767px) {
        .toolbar {
            display: none;
        }

        .profile-experiences__list-item:hover {
            .toolbar {
                display: block;
            }
        }
    }

    .profile-experiences__list {
        list-style-type: none;

        &-item {
            position: relative;
            padding: 20px 0;

            &:not(:last-child) {
                border-bottom: 1px solid var(--joy-color-neutral-30);
            }

            &-wrapper {
                display: flex;
                width: 100%;
            }

            @media screen and (max-width: 600px) {
                &-wrapper {
                    display: block;
                }
            }
        }

        &-show-all {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: var(--joy-core-spacing-3);
        }
    }
</style>
