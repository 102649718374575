<template>
    <div v-if="shortlist" class="shortlist-header">
        <VJoyWrapper align="center" justify="space-between" wrap="nowrap">
            <div class="shortlist-header__title">{{ t('profile.shortlist.title', [shortlist.title]) }}</div>
            <div class="shortlist-header__items">
                <VJoyAvatarsList data-testid="profile-shortlist-list">
                    <VJoyWrapper justify="center" align="center" no-margin>
                        <div v-for="(item, index) in shortlist!.items" :key="item.accountId" class="shortlist-header__items-profile-selected">
                            <VJoyAvatar
                                :photo-url="photoUrl(item.photo)"
                                :data-testid="`profile-shortlist-redirect-profile-${index}`"
                                @click="redirectToProfile(item)"
                            ></VJoyAvatar>
                            <VJoyIcon
                                :data-testid="`profile-shortlist-remove-${index}`"
                                name="cancel"
                                @click="shortlistStore.remove(item.profileId)"
                            />
                        </div>
                        <VJoyAvatar v-for="index in remainingEmptyItems" :key="index" :photo-url="''"></VJoyAvatar>
                    </VJoyWrapper>
                </VJoyAvatarsList>
            </div>
            <ProjectProposal :display-subtitle="false" />
        </VJoyWrapper>
    </div>
</template>

<script setup lang="ts">
    import {computed, guestHost, onMounted, onUnmounted, useTranslation} from '#imports';
    import {useShortlistStore} from '@/store/shortlist/ShortlistStore';
    import {Photo} from '@malt/asset-utils';
    import {navigateTo} from '@typed-router';
    import {storeToRefs} from 'pinia';
    import ProjectProposal from '~/components/header/ProjectProposal.vue';
    import type {Picture} from '~/types/domain/Profile';
    import type {ShortlistItem} from '~/types/domain/Shortlist';
    import {VJoyWrapper, VJoyAvatarsList, VJoyIcon, VJoyAvatar} from '@maltjoy/core-vue';

    const props = defineProps<{
        accountId?: string;
    }>();

    const {t} = useTranslation();

    const shortlistStore = useShortlistStore();
    const {reload} = shortlistStore;
    const {shortlist} = storeToRefs(shortlistStore);

    const maxItemsInShortlist = 6;

    const remainingEmptyItems = computed(() => maxItemsInShortlist - shortlist.value!.items.length);

    function photoUrl(picture: Picture): string {
        if (picture) {
            return new Photo(picture).getFaceThumbnailUrl({width: 125, height: 125, forcedFormat: 'webp'});
        }
        return '';
    }

    function redirectToProfile(shortlistItem: ShortlistItem) {
        if (props.accountId !== shortlistItem.accountId) {
            return navigateTo(`${guestHost()}${shortlistItem.seoUrl}`, {external: true});
        }
    }

    function reloadShortlist() {
        reload();
    }

    onMounted(() => {
        window.addEventListener('focus', reloadShortlist);
    });

    onUnmounted(() => {
        window.removeEventListener('focus', reloadShortlist);
    });
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .shortlist-header {
        margin: auto;
        max-width: 1200px;
        padding: var(--joy-core-spacing-4) var(--joy-core-spacing-8);

        @include mq.screen_xs {
            margin-top: var(--joy-core-spacing-8);
        }

        &__title {
            font-family: var(--joy-font-family-title);
            font-weight: 500;
            font-size: var(--joy-font-size-secondary-200);
            line-height: var(--joy-line-height-small);
            @include mq.screen_sm {
                display: none;
            }
        }

        &__items {
            &-profile-selected {
                position: relative;
                cursor: pointer;

                joy-icon {
                    position: absolute;
                    opacity: 0;
                    right: 0;
                    height: 16px;
                    cursor: pointer;
                    border-radius: 100%;
                    background-color: #fff;
                }
                &:hover {
                    joy-icon {
                        opacity: 1;
                    }
                }
            }
        }
    }

    joy-avatar {
        --avatar-placeholder-background: #ffffff !important;
        border: 1px solid var(--joy-color-secondary-50) !important;
        border-radius: 50%;
    }
</style>
