
/* tslint:disable */
/* eslint-disable */
/**
 * Profile API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';

/**
 * 
 */
export class ClientOnboardingApi extends runtime.BaseAPI {

    /**
     * Acknowledge propose project onboarding modal
     */
    async acknowledgeProposeProjectOnboardingRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<void>({
            path: `/profile/api/client/acknowledge/propose-project-onboarding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return response;
    }

    /**
     * Acknowledge propose project onboarding modal
     */
    async acknowledgeProposeProjectOnboarding(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<void> {
        await this.acknowledgeProposeProjectOnboardingRaw(initOverrides);
    }

    /**
     * Tells if should display client propose project onboarding
     */
    async getProposeProjectOnboardingRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<boolean>({
            path: `/profile/api/client/propose-project-onboarding`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return response;
    }

    /**
     * Tells if should display client propose project onboarding
     */
    async getProposeProjectOnboarding(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.getProposeProjectOnboardingRaw(initOverrides);
        return response._data;
    }

}
