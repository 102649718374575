<template>
    <VJoyPanel data-testid="profile-section-industry-expertises">
        <template #panel-title>
            <div>{{ t('profile.section.industry.expertise.title') }}</div>
        </template>
        <template #panel-title-action>
            <VJoyButton
                v-if="isEditModeEnabled"
                circle
                variant="ghost"
                icon="pen"
                size="small"
                data-testid="profile-section-industry-expertises-cta-edit"
                @click="openIndustryDialog"
            />
        </template>

        <template #panel-body>
            <FetchErrorHighlight v-if="hasFetchError" />
            <!-- TODO: Check "Read more" component and "See all" component -->
            <ReadMore
                v-else
                id="industry-expertise-section"
                :view-more-label="viewMoreLabel"
                :max-height="viewMoreMaxHeight"
                component-name="profile-industry-expertise-section"
            >
                <SkillsReadOnly
                    v-if="hasIndustryExpertises"
                    data-testid="profile-section-industry-expertises"
                    :skills="profileExpertises!.industryExpertises.map((skill) => ({label: skill.label, seoUrl: skill.seoUrl, id: skill.id}))"
                    :is-visible-seo-link="isVisibleSeoLink"
                />
                <div v-else data-testid="profile-section-industry-expertises-empty-state">
                    {{ t('profile.section.industry.expertise.empty.state.placeholder') }}
                </div>
            </ReadMore>
        </template>
    </VJoyPanel>
</template>

<script setup lang="ts">
    import {useAuth, useTranslation} from '#imports';
    import {useProfileStore} from '~/store/profileStore';
    import ReadMore from '~/components/_common/read-more/ReadMore.vue';
    import {eventBus} from '~/config/events';
    import {computed} from 'vue';
    import {storeToRefs} from 'pinia';
    import {ProfileVertical} from '~/types/domain/Profile';
    import SkillsReadOnly from '@/components/_common/skills/SkillsReadOnly.vue';
    import FetchErrorHighlight from '~/components/_common/FetchErrorHighlight.vue';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';
    import {VJoyButton, VJoyPanel} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {profile, profileExpertises, profileFetchErrors} = storeToRefs(useProfileStore());
    const {loggedIn} = useAuth();

    const isEditModeEnabled = computed(() => profile.value?.editMode || false);

    const hasIndustryExpertises = computed(() => profileExpertises.value!.industryExpertises.length > 0);
    const hasFetchError = computed(() => profileFetchErrors.value.expertises);
    const isVisibleSeoLink = computed(() => profile.value?.vertical === ProfileVertical.FREELANCER);

    const viewMoreLabel: string = loggedIn
        ? t('jstext.readmore.label.readmore')
        : t('profile.section.expertises.private', [profile.value!.firstname]);
    const viewMoreMaxHeight = loggedIn ? 400 : 180;

    function openIndustryDialog() {
        if (profile.value!.editMode) {
            eventBus.emit('industry-expertise:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
        }
    }
</script>
