<template>
    <VJoyIcon size="small" :name="associatedIcon || 'company-placeholder'" />
</template>

<script setup lang="ts">
    import {computed, toRefs} from 'vue';
    import type {TJoyIconsNames} from '@maltjoy/core-vue';
    import {VJoyIcon} from '@maltjoy/core-vue';

    type Props = {
        family: TJoyIconsNames;
    };
    const props = defineProps<Props>();
    const {family} = toRefs(props);
    const associations: Record<string, any> = {
        business_consulting: 'fee',
        communication: 'communication',
        data: 'dataviz',
        'industrial-engineering': 'r2d2',
        marketing: 'marketing',
        other: 'ico-list',
        'picture-sound': 'picture-sound',
        'projectmanagers-agilecoaches': 'projectmanager-agile',
        'support-functions': 'users',
        developers_datascientists: 'xml',
        'video-games': 'videogame',
        graphicdesigners_photographers: 'web-design',
    };

    const associatedIcon = computed(() => {
        return associations[family.value];
    });
</script>
