import type {MissionPreference} from '~/types/domain/MissionPreferences';

type MissionPreferenceConfiguration = {
    i18n: {
        title: string;
        prefix: string;
        negative: string;
    };
};
const conf: Record<string, MissionPreferenceConfiguration> = {
    activityArea: {
        i18n: {
            title: 'profile.section.mission.preferences.ACTIVITY_AREA',
            prefix: 'company.activity.area',
            negative: 'profile.mission.preference.activity_area.negative',
        },
    },
    companySize: {
        i18n: {
            title: 'profile.section.mission.preferences.COMPANY_SIZE',
            prefix: 'mission.preferences.company.size',
            negative: 'profile.mission.preference.company_size.negative',
        },
    },
    projectDuration: {
        i18n: {
            title: 'profile.section.mission.preferences.DURATION',
            prefix: 'mission.preferences.duration',
            negative: 'profile.mission.preference.duration.negative',
        },
    },
};

function _getPreferencesForICU(configuration: MissionPreferenceConfiguration, companySizePositivePreferences: MissionPreference[], t: Function) {
    const labels: string[] = [];
    const shortLabels: string[] = [];
    const maxLabelLengths = 5;

    companySizePositivePreferences.forEach((preference: MissionPreference) => {
        const i18nKey = `${configuration.i18n.prefix}.${preference.value}`;
        const label = t(i18nKey);
        if (labels.length < maxLabelLengths) {
            shortLabels.push(`<li>${label}</li>`);
        }
        labels.push(label);
    });

    const hiddenLabels = labels.length > maxLabelLengths ? labels.slice(maxLabelLengths, labels.length).join(', ') : '';

    return {
        hiddenLabels,
        nbLabels: labels.length > maxLabelLengths ? maxLabelLengths : labels.length,
        nbHiddenLabels: labels.length > maxLabelLengths ? labels.length - maxLabelLengths : 0,
        showMoreButtonOpen: `<joy-tooltip-trigger><joy-text slot="tooltip-trigger">`,
        showMoreButtonClose: `</joy-text><p slot="tooltip-content">${hiddenLabels}</p></joy-tooltip-trigger>`,
        labels: labels.join(''),
        shortLabels: shortLabels.join(''),
        withIntro: false,
    };
}

abstract class MPHHH {
    negativeMissionPreferences: MissionPreference[];

    constructor(readonly conf: MissionPreferenceConfiguration, readonly missionPreferences: MissionPreference[], readonly t: Function) {
        this.negativeMissionPreferences = missionPreferences.filter((missionPreference) => missionPreference.operator === 'NEGATIVE');
    }

    getConfig(): MissionPreferenceConfiguration {
        return this.conf;
    }

    getNegativeMissionPreferences() {
        return _getPreferencesForICU(this.conf, this.negativeMissionPreferences, this.t);
    }

    hasMissionPreferences(): boolean {
        return this.missionPreferences.length > 0;
    }

    hasNegativeMissionPreferences(): boolean {
        return this.negativeMissionPreferences.length > 0;
    }
}

export class ActivityAreaPreferences extends MPHHH {
    constructor(override readonly missionPreferences: MissionPreference[], override readonly t: Function) {
        super(conf.activityArea, missionPreferences, t);
    }
}
export class CompanySizePreferences extends MPHHH {
    constructor(override readonly missionPreferences: MissionPreference[], override readonly t: Function) {
        super(conf.companySize, missionPreferences, t);
    }
}
export class ProjectDurationPreferences extends MPHHH {
    constructor(override readonly missionPreferences: MissionPreference[], override readonly t: Function) {
        super(conf.projectDuration, missionPreferences, t);
    }
}

export default {
    ActivityAreaPreferences,
    CompanySizePreferences,
    ProjectDurationPreferences,
};
