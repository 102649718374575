<template>
    <VJoyProductTour
        ref="productTourRef"
        spotlight-selector="[data-client-onboarding-propose-a-project]"
        position="left"
        data-testid="client-onboarding-propose-a-project-product-tour"
        :max-width="400"
        :close-button="false"
    >
        <template #product-tour-content>
            {{ t('profile.project.proposal.onboarding.content') }}
        </template>
        <template #product-tour-next>
            <VJoyButton
                :label="t('profile.project.proposal.onboarding.cta')"
                size="small"
                variant="main"
                data-testid="client-onboarding-propose-a-project-product-tour-cta"
                @click="close"
            />
        </template>
    </VJoyProductTour>
</template>

<script setup lang="ts">
    import {VJoyButton, VJoyProductTour} from '@maltjoy/core-vue';
    import {ref, useLazyAsyncData, useTranslation, watch} from '#imports';
    import {useClientOnboarding} from '@/composables/useClientOnboarding';

    const {t} = useTranslation();
    const {getProposeProjectOnboardingTour, acknowledgeProposeProjectOnboarding} = useClientOnboarding();

    const productTourRef = ref<InstanceType<typeof VJoyProductTour>>();

    const {data: shouldDisplayOnboarding} = await useLazyAsyncData('propose-project-onboarding', async () => {
        const proposeProjectOB = await getProposeProjectOnboardingTour();
        return {proposeProjectOB};
    });

    watch(shouldDisplayOnboarding, async () => {
        if (shouldDisplayOnboarding.value?.proposeProjectOB) {
            productTourRef.value?.show();

            await acknowledgeProposeProjectOnboarding();
        }
    });

    function close() {
        productTourRef.value?.hide();
    }
</script>
