<template>
    <div>
        <VJoyHighlight
            v-if="profile?.editMode && visibilityAlertsHandledArray.length > 0"
            level="warning"
            display-icon
            data-testid="profile-alert-section"
        >
            <div>
                <p class="profile-alerts__title">{{ $t('profile.alert.title') }}</p>
                <p class="profile-alerts__subtitle">{{ $t('profile.alert.title.subtitle') }}</p>
                <ul class="profile-alerts__list">
                    <li v-for="(alert, i) in visibilityAlertsHandledArray" :key="i">
                        <p v-if="alert.field">
                            <span v-if="alert.field === 'TOO_MANY_REFUSAL'">
                                <VJoyLink href="/inbox" :text="alertMessage(alert)" />
                            </span>
                            <span v-else-if="alert.field === 'PHOTO'">
                                <VJoyLink href="#" :text="alertMessage(alert)" @click="openPhotoEdition" />
                            </span>
                            <span v-else-if="alert.field === 'DESCRIPTION'">
                                <VJoyLink href="#" :text="alertMessage(alert)" @click="openDescriptionEdition" />
                            </span>
                            <span v-else-if="alert.field === 'PRICE'">
                                <VJoyLink href="#" :text="alertMessage(alert)" @click="openPriceEdition" />
                            </span>
                            <span v-else-if="alert.field === 'HEADLINE'">
                                <VJoyLink href="#" :text="alertMessage(alert)" @click="openHeadlineEdition" />
                            </span>
                            <span v-else-if="alert.field === 'HIDDEN_PRICE'">
                                <VJoyLink href="#" :text="alertMessage(alert)" @click="openPriceEdition" />
                            </span>
                            <span v-else-if="alert.field === 'LOCATION'">
                                <VJoyLink href="#" :text="alertMessage(alert)" @click="openLocationEdition" />
                            </span>
                            <span v-else-if="alert.field === 'AVAILABILITY'" @click="openAvailabilityEdition">
                                <VJoyLink href="#" :text="alertMessage(alert)" />
                            </span>
                            <span v-else>
                                {{ alertMessage(alert) }}
                            </span>
                        </p>
                        <span v-else-if="alert.rawMessage.indexOf('no.experience') > -1">
                            <VJoyLink href="#" :text="alertMessage(alert)" @click="openExperienceEdition" />
                        </span>
                        <span v-else-if="alert.rawMessage.indexOf('no.education') > -1">
                            <VJoyLink href="#educationSection" :text="alertMessage(alert)" />
                        </span>
                        <span v-else-if="alert.rawMessage.indexOf('ask.recommandations') > -1">
                            <VJoyLink href="#" :text="alertMessage(alert)" @click="openRecommendationRequestEdition" />
                        </span>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-else v-html="t(alert.rawMessage)"></span>
                    </li>
                </ul>
            </div>
        </VJoyHighlight>
    </div>

    <div v-if="profile?.editMode && freelanceCharter?.invalidated">
        <VJoyHighlight level="error" display-icon>
            <div>
                <p class="profile-moderation-alert__title" v-html="$t('profile.alert.moderation.banner', {0: charterInvalidUntilFormatted})"></p>
            </div>
        </VJoyHighlight>
    </div>
</template>
<script setup lang="ts">
    import {VJoyHighlight, VJoyLink} from '@maltjoy/core-vue';
    import {useProfileStore} from '~/store/profileStore';
    import {storeToRefs} from 'pinia';
    import {computed, navbarEventBus, onMounted, onUnmounted, useTranslation} from '#imports';
    import type {VisibilityAlertResource} from '~/types/api-raw-types';
    import {formatISO, parseISO} from 'date-fns';
    import {eventBus} from '~/config/events';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';
    import {useFeatureContext} from '~/store/featureStore';

    const {t} = useTranslation();
    const profileStore = useProfileStore();
    const {features} = useFeatureContext();
    const {getVisibility, getFreelanceCharter} = await useProfileStore();
    const {profile} = storeToRefs(profileStore);

    if (profile.value?.editMode) {
        await refreshVisibility();
        await getFreelanceCharter();
    }
    const {visibility, freelanceCharter} = storeToRefs(profileStore);

    const visibilityAlertsFieldHandled = [
        'AVAILABILITY',
        'PHOTO',
        ...(features['missing-description-visible'] ? [] : ['DESCRIPTION']),
        'HIDDEN_PRICE',
        'MESSAGE',
    ];

    const visibilityAlertsHandledArray = computed(() => {
        return visibility.value?.alerts.filter((alert) => visibilityAlertsFieldHandled.includes(alert.field)) || [];
    });

    async function refreshVisibility() {
        await getVisibility();
    }

    // FIXME after the profile migration, I strongly recommend to avoid using v-html
    // TO do that, we should change all messages
    // our resource bundle to remove html // or just use the key as a message (in raw message)
    function alertMessage(alert: VisibilityAlertResource) {
        const message = t(`${alert.rawMessage}`);
        return message.replace(/(<([^>]+)>)/gi, '');
    }

    const charterInvalidUntilFormatted = computed(() => {
        if (freelanceCharter.value?.invalidated) {
            return formatISO(parseISO(freelanceCharter.value?.invalidUntil), {representation: 'date'});
        }
        return '';
    });

    function openHeadlineEdition() {
        eventBus.emit('headline:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_POPUP});
    }

    function openDescriptionEdition() {
        eventBus.emit('description:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_POPUP});
    }

    function openLocationEdition() {
        eventBus.emit('location-preferences:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_POPUP});
    }

    function openRecommendationRequestEdition() {
        eventBus.emit('recommendations:edit-mode');
    }

    function openPriceEdition() {
        eventBus.emit('price:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_POPUP});
    }

    function openExperienceEdition() {
        eventBus.emit('experiences:selection-mode');
    }

    function openPhotoEdition() {
        eventBus.emit('photo:edit-mode');
    }

    function openAvailabilityEdition() {
        navbarEventBus.emit('open-availibility-dialog', {source: 'CLICK_ON_PROFILE_ALERT'});
    }

    onMounted(() => {
        eventBus.on('profile:updated', refreshVisibility);
    });

    onUnmounted(() => {
        eventBus.off('profile:updated', refreshVisibility);
    });
</script>
<style lang="scss" scoped>
    .profile-alerts__title {
        font-size: var(--joy-font-size-primary-600);
        font-weight: 700;
    }
</style>
