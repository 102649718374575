<template>
    <div class="profile-photo-responsive_wrapper">
        <VJoyAvatar :photo-url="photoUrl" size="large" :alt="alt" />
        <MaltImg v-if="isStrategy" class="profile-photo-responsive_overlay" src="/malt-logos/malt_strategy_banner" alt="" />
    </div>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {useStrategyProfile} from '@/composables/useStrategyProfile';
    import {VJoyAvatar} from '@maltjoy/core-vue';

    const emits = defineEmits<{
        (e: 'edit'): void;
    }>();

    const isStrategy = useStrategyProfile();

    const props = defineProps<{
        photoUrl?: string;
        alt: string;
    }>();
</script>

<style scoped lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .profile-photo-responsive {
        &_overlay {
            position: relative;
            top: 100%;
            left: 0;
            transform: translateY(-99%);
            width: 100%;
        }

        &_wrapper {
            display: none;
            margin: auto;

            @include mq.screen_xs {
                display: block;
            }
        }

        &_photo {
            position: absolute;
            width: 100%;
            max-width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 0;
            overflow: hidden;
        }
    }
</style>
