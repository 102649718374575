<template>
    <MainComponent id="appraisalSection">
        <template #title>
            <!-- eslint-disable vue/no-v-html -->
            <span v-if="isEditMode" v-html="editModeTitle"></span>
            <span v-else-if="appraisals && appraisals.missionCount > 0" v-html="classicTitle"></span>
            <!-- eslint-enable vue/no-v-html -->
        </template>
        <template #title-action>
            <TranslateButton v-if="isTranslationActivated && hasAppraisals" section="appraisal" />
        </template>
        <template v-if="appraisals" #content>
            <ApprisalGeneralRatings :rating="appraisals.ratings.general || 0" :appraisal-counter="appraisals.numberOfAppraisalsWithRating || 0" />
            <AppraisalRatings v-if="isEditMode && !hasAppraisals" :model-value="sampleRatings()" :is-sample="true" />
            <AppraisalRatings v-if="hasAppraisals" :model-value="appraisals.ratings" :is-sample="false" />
            <AppraisalComponent v-if="isEditMode && !hasAppraisals" :model-value="sampleAppraisal()" :is-sample="true" :is-edit-mode="isEditMode" />
            <ReadMore v-if="hasAppraisalsWithComment" :view-more-label="viewMoreLabel" :max-height="150" component-name="profile-appraisals-section">
                <AppraisalComponent
                    v-for="(appraisal, index) in appraisalsWithComment"
                    :key="`appraisal-with-comment-${index}`"
                    :model-value="appraisal"
                    :is-sample="false"
                    :is-edit-mode="isEditMode"
                />
            </ReadMore>
            <HiddenAppraisalIndicator
                v-if="profile && !isEditMode && hasHiddenAppraisals"
                :account-firstname="profile.firstname"
                :hidden-counter="appraisals.hiddenAppraisals"
            />
            <PushPanel v-if="isEditMode && !hasAppraisals" />
        </template>
    </MainComponent>
</template>

<script setup lang="ts">
    import MainComponent from '~/components/main/common/MainComponent.vue';
    import {useProfileStore} from '~/store/profileStore';
    import {computed, onMounted, onUnmounted, useAuth, useTranslation} from '#imports';
    import PushPanel from '~/components/main/appraisals/PushPanel.vue';
    import AppraisalComponent from '~/components/main/appraisals/Appraisal.vue';
    import HiddenAppraisalIndicator from '~/components/main/appraisals/HiddenAppraisalIndicator.vue';
    import AppraisalRatings from '~/components/main/appraisals/AppraisalRatings.vue';
    import type {Appraisal, AppraisalAverageRating} from '~/types/domain/Appraisals';
    import ApprisalGeneralRatings from '~/components/main/appraisals/ApprisalGeneralRatings.vue';
    import ReadMore from '~/components/_common/read-more/ReadMore.vue';
    import {TranslationDirection, useTranslationStore} from '~/store/translation/translationStore';
    import {storeToRefs} from 'pinia';
    import TranslateButton from '~/components/main/TranslateButton.vue';
    import {eventBus} from '~/config/events';

    const {t} = useTranslation();
    const {appraisals, profile} = storeToRefs(useProfileStore());
    const {loggedIn} = useAuth();

    const {isTranslationActivated} = storeToRefs(useTranslationStore());
    const {translateAll, replaceTranslationContent} = useTranslationStore();
    const translationSection = 'appraisals';
    const translationField = 'comment';

    const viewMoreLabel = loggedIn ? t('jstext.readmore.label.readmore') : t('profile.section.appraisals.private', [profile.value?.firstname]);

    const appraisalsWithComment = computed(() =>
        hasAppraisals.value ? appraisals.value!.appraisals.filter((appraisal: Appraisal) => appraisal.comment) : [],
    );
    const hasAppraisals = computed<boolean>(() => (appraisals && appraisals.value!.appraisals.length > 0) || false);
    const hasAppraisalsWithComment = computed<boolean>(() => appraisalsWithComment.value.length > 0);
    const hasHiddenAppraisals = computed<boolean>(() => (appraisals && appraisals.value!.hiddenAppraisals > 0) || false);
    const isEditMode = computed<boolean>(() => profile.value?.editMode || false);

    function sampleRatings(): AppraisalAverageRating {
        return {
            communication: 4.5,
            quality: 5,
            delay: 4,
            general: 4.5,
        } as AppraisalAverageRating;
    }

    function sampleAppraisal(): Appraisal {
        return {
            clientName: 'Valerie',
            clientCompanyName: 'Miller Inc.',
            comment:
                'Vestibulum malesuada auctor lectus a convallis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus\nmus.',
            hidden: true,
            creationDate: '2022-09-16',
            clientImageUrl: '',
        } as Appraisal;
    }

    const editModeTitle = computed(() => {
        if (hasAppraisals.value) {
            return t('profile.section.mission.title', {nbOfMissions: appraisals.value!.missionCount});
        }

        return t('profile.mission.block.title.placeholder');
    });

    const classicTitle = computed(() => t('profile.section.mission.title', {nbOfMissions: appraisals.value!.missionCount}));

    async function translateAppraisals() {
        const appraisalCommentRequests = appraisalsWithComment.value.map((appraisal: Appraisal, index: number) => {
            return {
                section: translationSection,
                field: translationField,
                index,
                originalContent: appraisal.comment ?? ' ',
            };
        });

        await translateAll(appraisalCommentRequests);
        replaceTranslationContent(translationSection, translationField, TranslationDirection.TRANSLATE, switchTranslation);
    }

    function restoreAppraisals() {
        replaceTranslationContent(translationSection, translationField, TranslationDirection.RESTORE, switchTranslation);
    }

    function switchTranslation(content: string, index: number) {
        appraisalsWithComment.value[index].comment = content;
    }

    onMounted(() => {
        eventBus.on('translation:request', translateAppraisals);
        eventBus.on('translation:restore', restoreAppraisals);
    });

    onUnmounted(() => {
        eventBus.off('translation:request', translateAppraisals);
        eventBus.off('translation:restore', restoreAppraisals);
    });
</script>
