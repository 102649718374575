import type {Education} from '~/types/domain/Educations';
import type {EducationRequest} from '~/types/infra/EducationRequest';

class EducationMapper {
    toRequest(education: Education, index: number): EducationRequest {
        return {
            id: index,
            description: education.description ?? '',
            content: education.content ?? '',
            graduationYear: education.graduationYear,
            title: education.title ?? '',
            current: education.graduationYear == null,
        };
    }
}

export const educationMapper = new EducationMapper();
