<template>
    <div>
        <SimilarProfilesList class="fake-similar-profiles-blur-overlay__hidden_content" :similars="similars" />
        <div class="fake-similar-profiles-blur-overlay__overlay-content">
            <VJoyButton data-testid="similar-profiles-signup-cta" :href="signupUrl" rel="nofollow">
                {{ $t('profile.section.suggestion.signup.cta') }}
            </VJoyButton>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {useRoute} from '@typed-router';
    import {computed} from 'vue';
    import {SimilarProfile, SimilarProfilePhoto} from '~/types/domain/SimilarProfile';
    import SimilarProfilesList from './SimilarProfilesList.vue';
    import {VJoyButton} from '@maltjoy/core-vue';

    const route = useRoute();
    const signupUrl = computed(() => {
        const currentPageUrl = `/profile${route.fullPath}`;
        return `/who-are-you?redirect=${encodeURIComponent(currentPageUrl)}&pageSource=profile_page&componentSource=profile-similar-profiles-section`;
    });

    const similars = [
        generateProfile('Agata_FRYDRYCH_1', 'Agatha', 'Frydrych', 'Backend Java Software Engineer', 0, 'ProfilePictureSample003'),
        generateProfile('baptiste_duhen_1', 'Baptiste', 'Duhen', 'Fullstack developer', 0, 'ProfilePictureSample001'),
        generateProfile('Admin_Amed_Atchamou_1', 'Amed', 'Hamou', 'Senior Lead Developer', 4, 'ProfilePictureSample002'),
        generateProfile('Admin_Cecile_Pelletier_2', 'Audrey', 'Champion', 'Web developer', 0, 'portrait3'),
    ];

    function generateProfile(
        profileId: string,
        firstName: string,
        normalizedLastName: string,
        headline: string,
        numberOfStars: number,
        photo: string,
    ): SimilarProfile {
        return new SimilarProfile({
            profileId,
            canonicalUrl: '/profile',
            firstName,
            headline,
            normalizedLastName,
            numberOfStars,
            photo: new SimilarProfilePhoto({
                jpg: `https://dam.malt.com/profile/${photo}?w=60`,
                webp: '',
            }),
        });
    }
</script>
<style lang="scss">
    .fake-similar-profiles-blur-overlay {
        &__hidden_content {
            position: relative;
        }

        &__overlay-content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 95px var(--joy-core-spacing-12);
            background: hsla(0, 0%, 100%, 0.9);
            backdrop-filter: blur(3px);
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }
    }
</style>
