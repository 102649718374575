<template>
    <li
        :class="'profile-indicators-item ' + (isAdmin ? 'profile-indicators-main profile-indicators-admin' : 'profile-indicators-main')"
        @click="handleEdit"
    >
        <div class="profile-indicators-title-container">
            <span class="profile-indicators-title">{{ $t('profile.numeral.infos.price.indicative.label') }}</span>
            <VJoyTooltip v-if="isAdmin" text="Display admin info" class="profile-admin-indicators-title" placement="right" variant="secondary">
                <VJoyIcon name="eye" color="quaternary" size="medium" />
                <template #tooltip-content>
                    <ul>
                        <li>hidden by choice : {{ props.priceHidden }}</li>
                        <li>hidden by vertical : {{ props.isStrategyProfile }}</li>
                    </ul>
                </template>
            </VJoyTooltip>
        </div>
        <span class="profile-indicators-content">
            <strong v-if="priceVisibleOnDemand && isAdmin" v-html="displayedPrice"></strong>
            <strong v-else-if="priceVisibleOnDemand" data-testid="profile-price-not-visible">
                {{ $t('profile.numeral.infos.price.non.available') }}
            </strong>
            <div v-else data-testid="profile-price" v-html="displayedPrice"></div>
        </span>
        <span v-if="priceNotVisible" class="profile-indicators-hidden-indication" data-testid="profile-edit-price-not-visible">
            {{ $t('profile.numeral.infos.price.mine.non.visible') }}
        </span>
    </li>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {useHasEnoughPriviledge, useLocale, usePriceFormatterNoFraction, useTranslation} from '#imports';
    import {VJoyIcon, VJoyTooltip} from '@maltjoy/core-vue';

    export type PriceIndicatorProps = {
        editMode?: boolean;
        priceHidden?: boolean;
        currencyCode: string;
        price: number;
        isStrategyProfile: boolean;
    };

    const props = defineProps<PriceIndicatorProps>();

    const emits = defineEmits<{
        (e: 'edit'): void;
    }>();

    const {t} = useTranslation();
    const isAdmin = await useHasEnoughPriviledge(['ROLE_ADMIN']);
    const {locale} = useLocale();
    const priceFormatter = usePriceFormatterNoFraction(props.currencyCode, locale.value!);

    const priceVisibleOnDemand = computed(() => !props.editMode && (props.priceHidden || props.isStrategyProfile));
    const priceNotVisible = computed(() => props.editMode && props.priceHidden);

    const displayedPrice = computed(() =>
        t('profile.numeral.infos.price.amount.DAILY', {
            0: priceFormatter.format(props.price),
        }),
    );

    function handleEdit() {
        props.editMode && emits('edit');
    }
</script>
<style scoped lang="scss">
    .profile-indicators-main.profile-indicators-admin {
        border-color: var(--joy-color-quaternary-70);
        border-width: 4px;
        border-style: solid;
    }
    .profile-indicators-title-container {
        display: flex;
        width: 100%;
    }
    .profile-admin-indicators-title {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
    }
</style>
