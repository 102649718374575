<template>
    <CompletionItem
        :completed="completedItem"
        translation-key="profile.edition.completion.panel.checklist.item.VERIFIED_EMAIL_ADDRESS"
        @click="handleClick"
    />
</template>

<script setup lang="ts">
    import {pushVJoySnackbar} from '@maltjoy/core-vue';
    import CompletionItem from './CompletionItem.vue';
    import {useProfileStore} from '@/store/profileStore';
    import {useTranslation} from '#imports';

    withDefaults(defineProps<{completedItem: boolean}>(), {completedItem: false});

    const {t} = useTranslation();
    const {resendAccountValidationEmail} = useProfileStore();

    async function handleClick() {
        try {
            await resendAccountValidationEmail();
            pushVJoySnackbar({
                props: {
                    message: t('menu.validation.email.resend'),
                    level: 'error',
                },
            });
        } catch (message) {
            pushVJoySnackbar({
                props: {
                    message: t('generic.error.unexpected'),
                    level: 'error',
                },
            });
        }
    }
</script>
