<template>
    <LeftSideComponent>
        <template #title>{{ t('profile.section.location.short.title') }}</template>
        <template #content>
            <LocationPreferencesInfos />
        </template>
        <template v-if="profile?.editMode" #title-action>
            <VJoyButton
                circle
                size="small"
                variant="ghost"
                icon="pen"
                data-testid="profile-location-preference-edit-cta"
                @click="handleEditLocationsPreferences"
            />
        </template>
    </LeftSideComponent>
</template>

<script setup lang="ts">
    import {useProfileStore} from '~/store/profileStore';
    import {eventBus} from '~/config/events';
    import LeftSideComponent from '~/components/left-side/common/LeftSideComponent.vue';
    import LocationPreferencesInfos from './LocationPreferencesInfos.vue';
    import {useTranslation} from '#imports';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';
    import {VJoyButton} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {profile} = useProfileStore();

    function handleEditLocationsPreferences() {
        eventBus.emit('location-preferences:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
    }
</script>
