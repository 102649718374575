export function isValidUrl(url) {
    try {
        const typedUrl = new URL(url);
        return typedUrl.protocol === 'http:' || typedUrl.protocol === 'https:';
    } catch (err) {
        return false;
    }
}

export function isValidRelativeUrl(url, base) {
    try {
        const typedUrl = new URL(url, base);
        return typedUrl.protocol === 'http:' || typedUrl.protocol === 'https:';
    } catch (err) {
        return false;
    }
}
