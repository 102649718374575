<template>
    <aside class="push-panel">
        <VJoyWrapper justify="space-between" wrap="nowrap">
            <div class="push-panel-picture">
                <MaltImg alt="malt start logo" src="/rebranding2020/various-logos/malt-start_full" width="100" />
            </div>
            <div class="push-panel__main-content">
                <div class="push-panel__text-wrapper">
                    <p class="push-panel__title">{{ t('profile.section.mission.empty.malt.start.panel.title') }}</p>
                    <p class="push-panel__text">{{ t('profile.section.mission.empty.malt.start.text') }}</p>
                </div>
            </div>
            <VJoyButton size="small" variant="secondary" @click="redirect">
                {{ t('profile.section.mission.empty.malt.start.cta.label') }}
            </VJoyButton>
        </VJoyWrapper>
    </aside>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {useTranslation} from '#imports';
    import {navigateTo} from '@typed-router';
    import {VJoyButton, VJoyWrapper} from '@maltjoy/core-vue';

    const {t} = useTranslation();

    const props = defineProps<{
        link: string;
    }>();

    async function redirect() {
        await navigateTo(props.link, {external: true});
    }
</script>

<style lang="scss" scoped>
    .push-panel {
        color: #fff;
        background-color: var(--joy-color-tertiary-30);
        border-radius: var(--joy-core-radius-3);
        padding: var(--joy-core-spacing-5);

        .joy-wrapper {
            margin-bottom: 0;
        }

        &-picture {
            text-align: center;
        }

        &__title {
            font-family: var(--joy-font-family-title);
            font-weight: 500;
            font-size: var(--joy-font-size-secondary-200);
            line-height: var(--joy-line-height-small);
            margin-bottom: 0;
        }
    }
</style>
