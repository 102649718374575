<template>
    <VJoyPanel id="languages-section" data-testid="languages-section">
        <template #panel-title>
            {{ t('profile.section.languages.title') }}
        </template>

        <template v-if="profile?.editMode" #panel-title-action>
            <VJoyButton variant="ghost" circle icon="pen" size="small" @click="openLanguageEditDialog" />
        </template>

        <template #panel-body>
            <VJoyLink
                v-if="!loggedIn"
                href="#"
                class="read-more__cta"
                icon="lock"
                :data-testid="`languages-read-more-link`"
                @click="readMoreHandleClick"
            >
                {{ t('profile.section.languages.private', [profile?.firstname]) }}
            </VJoyLink>
            <template v-else>
                <VJoyHighlight v-if="displayNativeWarning" display-icon level="warning">
                    <div class="no-native-lang">{{ t('profile.language.native.is.mandatory') }}</div>
                </VJoyHighlight>
                <LanguagesReadOnly :languages="languageItems" />
            </template>
        </template>
    </VJoyPanel>
</template>

<script setup lang="ts">
    import {useAuth, useTranslation} from '#imports';
    import {computed} from 'vue';
    import {useProfileStore} from '~/store/profileStore';
    import {eventBus} from '~/config/events';
    import {hasNativeLanguage} from '~/types/domain/Languages';
    import LanguagesReadOnly from '~/components/_common/language/LanguagesReadOnly.vue';
    import {toLanguageItems} from '~/components/_common/language/LanguageItem';
    import {useFeatureContext} from '@/store/featureStore';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';
    import {VJoyButton, VJoyHighlight, VJoyLink, VJoyPanel} from '@maltjoy/core-vue';

    const {features} = useFeatureContext();
    const {profile} = useProfileStore();

    const {t} = useTranslation();
    const {loggedIn} = useAuth();

    const displayNativeWarning = computed(() => profile?.editMode && !hasNativeLanguage(profile.languages));
    const languageItems = computed(() => toLanguageItems(profile!.languages));

    function openLanguageEditDialog() {
        eventBus.emit('languages:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
    }

    function readMoreHandleClick(e: Event) {
        e.preventDefault();

        const path = window.location.pathname;
        const redirectParam = encodeURIComponent(path + '#languages-section');

        window.location.assign(`/who-are-you?redirect=${redirectParam}&pageSource=profile_page&componentSource=profile-languages-section`);
    }
</script>

<style lang="scss" scoped>
    joy-link.read-more__cta {
        font-size: var(--joy-font-size-primary-400);
    }

    .languages__list {
        list-style-type: none;
        padding: 0;
        margin-top: var(--joy-core-spacing-2);

        &-item {
            padding: var(--joy-core-spacing-4);
            margin-bottom: var(--joy-core-spacing-2);

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }

            &-title {
                font-family: var(--joy-font-family);
                font-weight: var(--joy-font-weight-bold);
                font-size: var(--joy-font-size-primary-600);
                line-height: var(--joy-line-height-large);
                margin-bottom: 0;
            }

            &-subtitle {
                font-size: var(--joy-font-size-primary-300);
                margin-bottom: 5px;
                color: var(--joy-color-text-body);
            }
        }
    }

    .no-native-lang {
        font-size: var(--highlight-content-font-size);
    }
</style>
