<template>
    <div v-if="profileLocationPreferences">
        <Location :model-value="profileLocationPreferences.address.formattedAddress" />

        <WorkplacePreferenceReadOnly :model-value="locationPreferenceItem" />
    </div>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {useProfileStore} from '@/store/profileStore';
    import {storeToRefs} from 'pinia';
    import Location from '@/components/_common/location-and-workplace-preferences/Location.vue';
    import WorkplacePreferenceReadOnly from '@/components/_common/location-and-workplace-preferences/WorkplacePreferenceReadOnly.vue';
    import {toLocationPreferenceItem} from '~/components/_common/location-and-workplace-preferences/LocationPreferencesItem';

    const store = useProfileStore();
    const {profileLocationPreferences} = storeToRefs(store);
    const locationPreferenceItem = computed(() => toLocationPreferenceItem(profileLocationPreferences.value!));
</script>
