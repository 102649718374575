<template>
    <VJoyPanel v-if="isVisibleSkillSetSection" data-testid="profile-main-skill-set-section">
        <template #panel-title>
            <div class="profile-skill-set__title">
                {{ t('profile.section.skill.set.title') }}
                <span v-if="showSkillCountInSectionTitle" data-testid="profile-main-skill-set-title-counter">({{ countSelectedAndTopSkills }})</span>
            </div>
        </template>
        <template #panel-title-action>
            <TranslateButton v-if="showTranslationButton" section="skill_set" />
            <VJoyButton
                v-if="editMode"
                circle
                variant="ghost"
                icon="pen"
                size="small"
                data-testid="profile-main-skill-set-cta-edit"
                @click="openSkillSetEditDialog"
            />
        </template>

        <template #panel-body>
            <FetchErrorHighlight v-if="error">
                <template #technical-error>{{ error }}</template>
            </FetchErrorHighlight>
            <VJoyWrapper v-bind="{direction: 'column', align: 'flex-start'}" no-margin data-testid="profile-main-skill-set">
                <div v-if="showTopSkills" data-testid="top-skills-section">
                    <div class="profile-skill-set__content__header">
                        {{ t('profile.section.skill.set.sub.section.top.skills.title') }}
                    </div>
                    <SkillsReadOnly
                        v-if="hasTopSkills"
                        data-testid="profile-main-skill-set-top-skills"
                        :skills="topSkillsToSkills!"
                        :is-visible-seo-link="isVisibleSeoLink"
                        :is-visible-translated-label="isVisibleTranslatedLabel"
                    />
                    <div v-else class="profile-skill-set__content__top-skills-empty" data-testid="profile-main-skill-set-top-skills-empty-state">
                        {{ t('profile.section.skill.set.sub.section.top.skills.empty.state.placeholder') }}
                    </div>
                </div>
                <div>
                    <div
                        v-if="showSelectedSkillsTitle"
                        class="profile-skill-set__content__header"
                        data-testid="profile-main-skill-set-selected-skills-section-title"
                    >
                        {{ t('profile.section.skill.set.sub.section.additional.skills.title') }}
                        <span data-testid="skill-count-in-additional-skills-title">({{ profileSelectedSkills?.length }})</span>
                    </div>
                    <SkillsReadOnly
                        v-if="hasSelectedSkills"
                        data-testid="profile-main-skill-set-selected-skills"
                        :skills="selectSkillsToSkills!"
                        :is-visible-seo-link="isVisibleSeoLink"
                        :is-visible-translated-label="isVisibleTranslatedLabel"
                    />
                </div>
            </VJoyWrapper>
            <ShowMore
                :total="profileSelectedSkills.length"
                :locked-label="t('profile.section.expertises.private', [profile?.firstname])"
                :limit="selectedSkillDisplayed"
                test-id="toggle-show-all-skill-set"
                :label="t('jstext.readmore.label.readmore')"
                component-name="profile-skill-set-section"
                @expand="showAllSelectedSkills(true)"
                @collapse="showAllSelectedSkills(false)"
            />
        </template>
    </VJoyPanel>
</template>

<script setup lang="ts">
    import {onMounted, onUnmounted, useAsyncData, useTranslation} from '#imports';
    import SkillsReadOnly, {type Skill} from '@/components/_common/skills/SkillsReadOnly.vue';
    import {useSkillSetStore} from '@/store/skill-set/skillSetStore';
    import {VJoyButton, VJoyPanel, VJoyWrapper} from '@maltjoy/core-vue';
    import {storeToRefs} from 'pinia';
    import {computed, ref} from 'vue';
    import FetchErrorHighlight from '~/components/_common/FetchErrorHighlight.vue';
    import ShowMore from '~/components/_common/read-more/ShowMore.vue';
    import TranslateButton from '~/components/main/TranslateButton.vue';
    import {EditionOpenedEntryPoint} from '~/config/TrackingEvents';
    import {eventBus} from '~/config/events';
    import {useProfileStore} from '~/store/profileStore';
    import {useTranslationStore} from '~/store/translation/translationStore';
    import {ProfileVertical} from '~/types/domain/Profile';

    const {t} = useTranslation();
    const {fetchSkillSet} = useSkillSetStore();
    const {profileSelectedSkills, profileTopSkills} = storeToRefs(useSkillSetStore());
    const {isTranslationActivated, isTranslationDone} = storeToRefs(useTranslationStore());

    const {profile} = storeToRefs(useProfileStore());
    const editMode = computed(() => profile.value?.editMode);

    const {error, refresh} = await useAsyncData('main_skill-set', async () => {
        const skillSet = await fetchSkillSet();
        return {
            skillSet,
        };
    });

    const isVisibleSkillSetSection = computed(() => editMode.value || hasTopSkills.value || hasSelectedSkills.value);
    const showTranslationButton = computed(() => isTranslationActivated.value);

    // Top Skills
    const hasTopSkills = computed(() => profileTopSkills.value && profileTopSkills.value.length > 0);
    const showTopSkills = computed(() => editMode.value || hasTopSkills.value);
    const topSkillsToSkills = computed((): Skill[] => profileTopSkills.value);

    // Selected Skills
    const hasSelectedSkills = computed(() => profileSelectedSkills.value && profileSelectedSkills.value.length > 0);
    const selectSkillsToSkills = computed((): Skill[] =>
        displayAllSelectedSkills.value ? profileSelectedSkills.value : [...profileSelectedSkills.value].slice(0, selectedSkillDisplayed),
    );
    const countSelectedAndTopSkills = computed(() => profileTopSkills.value.length + profileSelectedSkills.value.length);
    const showSelectedSkillsTitle = computed(() => showTopSkills.value);
    const showSkillCountInSectionTitle = computed(() => !editMode.value && !hasTopSkills.value && hasSelectedSkills.value);
    const isVisibleSeoLink = computed(() => profile.value?.vertical === ProfileVertical.FREELANCER);
    const isVisibleTranslatedLabel = computed((): boolean => isTranslationActivated.value && isTranslationDone.value);
    const selectedSkillDisplayed = 5;
    const displayAllSelectedSkills = ref(false);

    onMounted(() => {
        eventBus.on('task-card-tags-suggestion:added', refreshSkills);
        eventBus.on('expertises-skills:updated', refreshSkills);
        eventBus.on('skill-set:updated', refreshSkills);
    });

    onUnmounted(() => {
        eventBus.off('task-card-tags-suggestion:added', refreshSkills);
        eventBus.off('expertises-skills:updated', refreshSkills);
        eventBus.off('skill-set:updated', refreshSkills);
    });

    function showAllSelectedSkills(showAll: boolean) {
        displayAllSelectedSkills.value = showAll;
    }

    async function refreshSkills(): Promise<void> {
        await refresh();
    }

    function openSkillSetEditDialog() {
        eventBus.emit('skill-set:edit-mode', {entryPoint: EditionOpenedEntryPoint.PROFILE_PAGE});
    }
</script>

<style lang="scss" scoped>
    .profile-skill-set {
        &__title {
            display: flex;
            align-items: center;
            gap: var(--joy-core-spacing-2);

            span {
                flex-grow: 0;
            }
        }

        &__content {
            &__header {
                padding-bottom: var(--joy-core-spacing-5);
                font-weight: var(--joy-font-weight-bold);
            }
        }
    }
</style>
