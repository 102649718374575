<template>
    <div v-if="isSuperMalter" data-testid="badge-super-malter">
        <VJoyTooltip event="click">
            <VJoyBadgeLevel :data-supermalter-level.attr="superMalterLevel" type="supermalter" :super-malter-level="superMalterLevel" />
            <template #tooltip-content>{{ supermalterTooltip }}</template>
        </VJoyTooltip>
    </div>
    <div v-else-if="isHighPotential" data-testid="badge-high-potential">
        <VJoyTooltip event="click">
            <VJoyBadgeLevel type="program_high-potential" />
            <template #tooltip-content>{{ highPotentialTooltip }}</template>
        </VJoyTooltip>
    </div>
    <div v-else-if="isMaltLinker" data-testid="badge-malt-linker">
        <VJoyTooltip event="click">
            <VJoyBadgeLevel type="maltlinker" />
            <template #tooltip-content>{{ maltLinkerTooltip }}</template>
        </VJoyTooltip>
    </div>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {useTranslation} from '#imports';
    import type {BadgeVisibilityValueResource} from '@/types/api-raw-types';
    import {VJoyBadgeLevel, VJoyTooltip} from '@maltjoy/core-vue';

    const props = defineProps<{
        badge: BadgeVisibilityValueResource;
        firstname: string;
    }>();

    const {t} = useTranslation();

    const supermalterTooltip = computed<string>(() => t('profile.card.badge.level.supermalter.tooltip', [props.firstname]));
    const highPotentialTooltip = computed<string>(() => t('profile.card.badge.level.high.potential.tooltip', [props.firstname]));
    const maltLinkerTooltip = computed<string>(() => t('profile.card.badge.level.maltlinker.tooltip'));
    const isSuperMalter = computed<boolean>(() => props.badge.includes('SUPER_MALTER'));
    const isHighPotential = computed<boolean>(() => props.badge === 'HIGH_POTENTIAL');
    const isMaltLinker = computed<boolean>(() => props.badge === 'MALT_LINKER');
    const superMalterLevel = computed<number>(() => {
        switch (props.badge) {
            case 'SUPER_MALTER_L1':
                return 1;
            case 'SUPER_MALTER_L2':
                return 2;
            case 'SUPER_MALTER_L3':
                return 3;
            default:
                return 0;
        }
    });
</script>
