<template>
    <div :class="`profile-wrapper__profile-breadcrumb ${verticalBackground}`">
        <div class="profile-wrapper__profile-breadcrumb-read">
            <div :class="`shapes ${verticalBackground}`">
                <div :class="`shape ${verticalBackground}`"></div>
            </div>
            <div v-if="areBreadcrumbLinksVisible" class="breadcrumb-wrapper">
                <ReadBreadcrumbSection />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import ReadBreadcrumbSection from '~/components/breadcrumb/ReadBreadcrumbSection.vue';
    import {useStrategyProfile} from '@/composables/useStrategyProfile';
    import {useSectionsVisibility} from '@/composables/useSectionsVisibility';
    import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';
    import {useProfileStore} from '~/store/profileStore';

    const {profile} = useProfileStore();

    const verticalBackground = computed(() => (useStrategyProfile() ? 'strategy' : 'freelancer'));

    const hideBreadcrumb = computed(() => profile?.editMode);
    const areBreadcrumbLinksVisible = computed(() => !hideBreadcrumb.value && useSectionsVisibility(SectionsVisibilityType.BREADCRUMBS));
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .shapes {
        position: absolute;
        overflow: hidden;
        height: inherit;
        width: calc(100% - var(--layout-padding-left, 0px));

        &::before {
            content: '';
            position: absolute;
            display: block;
            transform-origin: left top;
            z-index: 2;
            top: 1156px;
            right: -320px;
            width: 2000px;
            height: 600px;
            border-radius: 170px/100px;
            transform: rotate(-42deg);
        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            transform-origin: left top;
            z-index: 3;
            top: 1612px;
            right: -900px;
            width: 2000px;
            height: 600px;
            border-radius: 1000px;
            transform: rotate(-63deg);
        }

        &.strategy {
            &::before {
                background-color: var(--joy-color-tertiary-50);
            }
            &::after {
                background-color: var(--joy-color-secondary-30);
            }
        }

        &.freelancer {
            &::before {
                background-color: var(--joy-color-quaternary-30);
            }
            &::after {
                background-color: var(--joy-color-secondary-30);
            }
        }
    }

    .draft-application {
        opacity: 0.4;
    }

    .shape {
        &::after {
            content: '';
            position: absolute;
            display: block;
            transform-origin: left top;
            z-index: 0;
            top: 165px;
            right: 350px;
            width: 1000px;
            height: 300px;
            border-radius: 650px/500px;
            transform: rotate(-10deg);
            background-color: var(--joy-color-quaternary-50);
        }

        &.strategy {
            &::after {
                background-color: var(--joy-color-tertiary-30);
            }
        }

        &.freelancer {
            &::after {
                background-color: var(--joy-color-quaternary-50);
            }
        }
    }

    .breadcrumb-wrapper {
        padding: var(--joy-core-spacing-5);
        z-index: 3;
        position: relative;
    }

    .profile-wrapper__profile-breadcrumb {
        display: block;
        width: 100%;
        height: 95px;
        font-family: var(--joy-font-family);
        font-weight: var(--joy-font-weight-normal);
        font-size: var(--joy-font-size-primary-400);
        line-height: var(--joy-line-height-large);

        @include mq.screen_xs {
            display: none;
        }

        &-read {
            height: inherit;
        }

        &.strategy {
            background-color: var(--joy-color-neutral-white);
        }

        &.freelancer {
            background-color: var(--joy-color-quaternary-70);
        }
    }
</style>
