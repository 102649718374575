<template>
    <VJoyDialog
        id="removalRecommendationConfirmDialog"
        ref="removeDialog"
        pattern-confirm
        :confirm-text="t('general.continue')"
        :cancel-text="t('general.cancel')"
        @dialog:confirm="removeRecommendation"
    >
        <template #dialog-header>
            <p>{{ t('generic.form.confirm.text') }}</p>
        </template>
    </VJoyDialog>

    <VJoyDialog
        id="removalOwnRecommendationConfirmDialog"
        ref="removeOwnDialog"
        pattern-confirm
        :confirm-text="t('general.continue')"
        :cancel-text="t('general.cancel')"
    >
        <template #dialog-header>
            <p>{{ t('generic.form.confirm.text') }}</p>
        </template>

        <template #dialog-confirm>
            <VJoyButton variant="main" size="small" data-testid="remove-own-recommendation-confirm" @click="deleteOwnRecommendationAction">
                {{ t('general.continue') }}
            </VJoyButton>
        </template>
    </VJoyDialog>

    <MainComponent
        v-if="isRecommendationSectionVisible"
        id="recommendationSection"
        ref="recommendationSection"
        :class="{'edit-mode': profile?.editMode}"
        data-testid="recommendation-sections"
    >
        <template #title>
            <VJoyWrapper>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-html="title"></span>
            </VJoyWrapper>
        </template>

        <template v-if="profile?.editMode" #title-action>
            <VJoyButton
                class="edit-button"
                variant="ghost"
                circle
                icon="pen"
                size="small"
                data-testid="open-recommendation-request"
                @click="openEditRecommendation"
            />
        </template>
        <template v-else-if="isAddRecommendationModeEnable && !hasAlreadyRecommendedThisProfile" #title-action>
            <VJoyWrapper direction="column" align="flex-end" no-margin>
                <VJoyButton icon="add" size="small" variant="ghost" data-testid="add-recommendation" @click="openAddRecommendation">
                    {{ t('profile.section.recommendation.cta.label', [profile?.firstname]) }}
                </VJoyButton>
                <TranslateButton v-if="isTranslationActivated" section="recommendation" />
            </VJoyWrapper>
        </template>
        <template v-else-if="hasAlreadyRecommendedThisProfile" #title-action>
            <VJoyDialogTrigger :dialog="removeOwnDialog">
                <VJoyButton
                    :icon="deletionIcon"
                    size="xsmall"
                    variant="ghost"
                    data-testid="recommendation-own-delete"
                    @mouseover="showDeleteRecommendationOption"
                    @mouseout="hideDeleteRecommendationOption"
                >
                    {{ t('profile.section.recommendation.cta.done.label', {0: profile?.firstname}) }}
                </VJoyButton>
            </VJoyDialogTrigger>
        </template>

        <template #content>
            <ReadMore
                id="recommendation-section"
                :view-more-label="viewMoreLabel"
                :max-height="100"
                class="recommendations__read-more"
                data-testid="read-more-recommendations"
                component-name="profile-recommendations-section"
            >
                <RecommendationsCount
                    v-if="profile && hasFilteredRecommendations && recommendations?.recommendationsCount"
                    :recommendations-count="recommendations?.recommendationsCount"
                    :accepted-recommendations="filteredRecommendations"
                    :profile-firstname="profile?.firstname"
                />

                <template v-if="hasFilteredRecommendationsWithTextContent">
                    <div v-for="recommendation in filteredRecommendationsWithTextContent" :key="recommendation.id" class="recommendations-list">
                        <RecommendationItem
                            v-if="hasTextContent(recommendation)"
                            class="recommendations-list__item"
                            :model-value="recommendation"
                            :is-edit-mode="profile!.editMode"
                            :is-sample="false"
                            :remove-dialog-ref="removeDialog"
                            @remove="(id) => prepareRemoveRecommendation(id)"
                        />
                    </div>
                </template>
                <template v-else-if="profile!.editMode">
                    <div class="recommendations-list">
                        <RecommendationItem
                            class="recommendations-list__item"
                            :is-edit-mode="false"
                            :model-value="sampleRecommendation"
                            :is-sample="true"
                        />
                    </div>
                </template>
            </ReadMore>
        </template>
    </MainComponent>
</template>
<script setup lang="ts">
    import {extractQueryParameters, guestHost, useAuth, useSectionsVisibility, useTranslation} from '#imports';
    import {navigateTo, useRoute} from '@typed-router';
    import {storeToRefs} from 'pinia';
    import {computed, nextTick, onMounted, onUnmounted, ref} from 'vue';
    import ReadMore from '~/components/_common/read-more/ReadMore.vue';
    import MainComponent from '~/components/main/common/MainComponent.vue';
    import TranslateButton from '~/components/main/TranslateButton.vue';
    import {eventBus} from '~/config/events';
    import {useProfileStore} from '~/store/profileStore';
    import {TranslationDirection, useTranslationStore} from '~/store/translation/translationStore';
    import {Recommendation} from '~/types/domain/Recommendations';
    import {SectionsVisibilityType} from '~/types/domain/SectionsVisibility';
    import RecommendationItem from './ui/RecommendationItem.vue';
    import RecommendationsCount from './ui/RecommendationsCount.vue';
    import {VJoyButton, VJoyDialog, VJoyDialogTrigger, VJoyWrapper} from '@maltjoy/core-vue';

    const {loggedIn} = useAuth();
    const {t} = useTranslation();
    const {deleteRecommendation} = useProfileStore();
    const {profile, recommendations} = storeToRefs(useProfileStore());
    const recommendationIdToDelete = ref('');
    const removeDialog = ref<InstanceType<typeof VJoyDialog> | null>(null);
    const removeOwnDialog = ref<InstanceType<typeof VJoyDialog> | null>(null);
    const recommendationSection = ref();

    const deletionOption = ref(false);
    const deletionIcon = computed(() => (deletionOption.value ? 'trash' : 'check'));
    const viewMoreLabel = t('profile.section.recommendation.private', {0: profile.value?.firstname});
    const isEditModeEnabled = computed(() => profile.value?.editMode || false);
    const hasAlreadyRecommendedThisProfile = computed(() =>
        recommendations.value?.recommendations.some((reco) => reco.links.some((hateoasLink: any) => hateoasLink.rel === 'deleteRecommendation')),
    );
    const isAddRecommendationModeEnable = computed(() => !isEditModeEnabled.value);
    const title = computed(() => t('profile.section.recommendation.title', {nbComment: recommendations.value?.recommendationsCount}));

    const {isTranslationActivated} = storeToRefs(useTranslationStore());
    const {translateAll, replaceTranslationContent} = useTranslationStore();
    const translationSection = 'recommendations';
    const translationField = 'textContent';

    const isRecommendationSectionVisible = computed(() => useSectionsVisibility(SectionsVisibilityType.RECOMMENDATIONS));

    const sampleRecommendation = new Recommendation({
        accepted: true,
        accountId: '',
        authorCompanyName: t('profile.section.recommendation.eg.company'),
        authorFirstName: 'Georges',
        authorLastName: 'Abitbol',
        authorImgUrl: '//dam.malt.com/hop_profile_reco_eg_pp?w=50&h=50',
        recoText: t('profile.section.recommendation.eg.text', {0: profile.value!.firstname}),
        authorProfileUrl: '',
        experienceId: '',
        recommenderJob: '',
        id: '',
        recoTextUpdateDate: '1993-01-01',
        _links: [],
    });

    function hasTextContent(recommendation: Recommendation) {
        return recommendation.textContent && recommendation.textContent.trim().length > 0;
    }

    function prepareRemoveRecommendation(id: string) {
        recommendationIdToDelete.value = id;
    }

    async function removeRecommendation() {
        const recoToDelete = recommendations.value!.recommendations.find((reco) => reco.id === recommendationIdToDelete.value)!;
        await deleteRecommendation(recoToDelete);
        removeDialog.value?.hide();
    }

    const filteredRecommendations = computed((): Recommendation[] =>
        recommendations.value?.recommendations ? recommendations.value?.recommendations.filter((item) => item.accepted) : [],
    );
    const hasFilteredRecommendations = computed(() => filteredRecommendations.value.length > 0);

    const filteredRecommendationsWithTextContent = computed((): Recommendation[] =>
        filteredRecommendations.value.filter((item) => hasTextContent(item)),
    );

    const hasFilteredRecommendationsWithTextContent = computed(() => filteredRecommendationsWithTextContent.value.length > 0);

    function showDeleteRecommendationOption() {
        deletionOption.value = true;
    }

    function hideDeleteRecommendationOption() {
        deletionOption.value = false;
    }

    function deleteOwnRecommendationAction() {
        const recommendationToDelete = recommendations.value?.recommendations.find((reco) =>
            reco.links.some((hateoasLink: any) => hateoasLink.rel === 'deleteRecommendation'),
        )!;
        deleteRecommendation(recommendationToDelete);
        removeOwnDialog.value?.hide();
    }

    function openEditRecommendation() {
        if (profile.value!.editMode === true) {
            eventBus.emit('recommendations:edit-mode');
        }
    }

    function openAddRecommendation() {
        if (!loggedIn) {
            const route = useRoute();
            const host = guestHost();

            const queryParams = new URLSearchParams(extractQueryParameters(route));
            queryParams.set('drawerOpen', 'true');

            const redirectUriParams = encodeURIComponent(`${location.pathname}?${queryParams}#recommendationSection`);
            const redirectUri = `${host}/signin?redirect=${redirectUriParams}`;
            navigateTo(redirectUri, {external: true});
        }
        eventBus.emit('external-action:recommendations:add');
    }

    async function translateRecommendations() {
        const recommendationTextContentRequests =
            recommendations.value?.recommendations.map((recommendation, index) => {
                return {
                    section: translationSection,
                    field: translationField,
                    index,
                    originalContent: recommendation.textContent ?? ' ',
                };
            }) || [];

        await translateAll(recommendationTextContentRequests);
        replaceTranslationContent(translationSection, translationField, TranslationDirection.TRANSLATE, switchTranslation);
    }

    function restoreRecommendations() {
        replaceTranslationContent(translationSection, translationField, TranslationDirection.RESTORE, switchTranslation);
    }

    function switchTranslation(content: string, index: number) {
        recommendations.value!.recommendations[index].textContent = content;
    }

    function scrollToRecommendationsSection() {
        nextTick(() => {
            setTimeout(() => {
                recommendationSection.value.$el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }, 600);
        });
    }

    onMounted(() => {
        eventBus.on('translation:request', translateRecommendations);
        eventBus.on('translation:restore', restoreRecommendations);
        eventBus.on('main:recommendations:scroll-to', scrollToRecommendationsSection);

        // Component is ready
        eventBus.emit('main:recommendations:ready');
    });

    onUnmounted(() => {
        eventBus.off('translation:request', translateRecommendations);
        eventBus.off('translation:restore', restoreRecommendations);
        eventBus.off('main:recommendations:scroll-to', scrollToRecommendationsSection);
    });
</script>
<style scoped lang="scss">
    .hidden {
        display: none;
    }

    .recommendations {
        &__read-more {
            min-height: 15px;
        }
    }
    .recommendations-lock__message {
        flex-wrap: wrap;
        white-space: pre-wrap;
    }

    .add-reco {
        text-align: right;
        text-decoration: none;
        font-size: var(--joy-font-size-primary-400) !important;
        font-weight: var(--joy-font-weight-normal);
        color: var(--joy-color-secondary-50);
    }

    .edit-mode {
        cursor: pointer;
    }

    .recommendations-lock {
        padding: 12px;
        .opacity {
            opacity: 0.4;
            pointer-events: none;
        }
    }
    .recommendations-list {
        &:not(:last-child):after {
            height: 1px;
            width: 100%;
            background-color: var(--joy-color-neutral-30);
            content: '';
            display: block;
            margin: var(--joy-core-spacing-8) 0;
            &:last-child {
                display: none;
            }
        }
    }
</style>
