<template>
    <div class="profile-wrapper__photo">
        <div v-if="photo" class="profile-wrapper__photo-with-avatar" data-testid="profile-photo-with-photo">
            <ProfilePhoto :photo-url="photoUrl" :alt="`${displayName} ${profession}`" @edit="edit" />
            <ProfilePhotoResponsive :photo-url="photoUrl" :alt="`${displayName} ${profession}`" />
        </div>
        <div v-else class="profile-wrapper__photo-without-avatar" data-testid="profile-photo-no-photo">
            <MaltImg src="/rebranding2020/illustrations/no-photo-placeholder" alt="Profile picture" width="40" height="35" />
        </div>
        <div v-if="editMode" class="profile-wrapper__photo-cta" data-testid="profile-photo-edit-mode" @click="edit">
            <VJoyIcon name="camera" color="white" />
            {{ $t(`profile.picture.cta.${photo ? 'modify' : 'add'}`) }}
        </div>
    </div>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {computed} from 'vue';
    import {Photo} from '@malt/asset-utils';
    import type {Picture} from '~/types/domain/Profile';
    import ProfilePhoto from '~/components/_common/profile-photo/ProfilePhoto.vue';
    import ProfilePhotoResponsive from '~/components/_common/profile-photo/ProfilePhotoResponsive.vue';
    import {VJoyIcon} from '@maltjoy/core-vue';

    const props = defineProps<{
        editMode?: Boolean;
        photo?: Picture | null;
        displayName: string;
        profession: string;
    }>();

    const emits = defineEmits<{
        (e: 'edit'): void;
    }>();

    const photoUrl = computed(() =>
        props.photo ? new Photo(props.photo).getFaceThumbnailUrl({width: 440, height: 440, forcedFormat: 'webp', zoomOut: 0.3}) : '',
    );

    function edit() {
        props.editMode && emits('edit');
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .profile-wrapper__photo {
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        width: 100%;

        &-cta {
            position: absolute;
            text-align: center;
            width: 220px;
            height: 220px;
            cursor: pointer;
            display: flex;
            color: white;
            flex-direction: column;
            background-color: transparent;
            padding: 15px;
            border: none;
            justify-content: center;
            align-items: center;
            transition: opacity 0.3s ease;
            opacity: 0;
            border-radius: 50%;

            @include mq.screen_md {
                width: 150px;
                height: 150px;
            }

            @include mq.screen_xs {
                width: 100%;
                height: 100%;
            }

            &:hover {
                background-color: rgba(3, 82, 102, 0.5);
                opacity: 1;
                @include mq.screen_xs {
                    border-radius: 0;
                }
            }
        }
        &-with-avatar {
            @include mq.screen_xs {
                width: 100%;
            }
        }

        &-without-avatar {
            position: relative;
            display: flex;
            width: 220px;
            height: 220px;

            @include mq.screen_md {
                width: 150px;
                height: 150px;
            }

            @include mq.screen_xs {
                width: 100%;
                height: 300px;
            }

            img {
                display: block;
                margin: auto;
            }

            &::after {
                content: '';
                border-radius: 100%;
                width: 100%;
                position: absolute;
                height: 100%;
                pointer-events: none;
                opacity: 0.1;
                background-color: rgba(3, 82, 102, 0.5);
                @include mq.screen_xs {
                    border-radius: 0;
                }
            }

            &-edit {
                position: absolute;
                height: 220px;
                width: 220px;
                display: flex;
                color: white;
                flex-direction: column;
                background-color: transparent;
                top: 0;
                left: 0;
                padding: 15px;
                border: none;
                justify-content: center;
                align-items: center;
                transition: all 0.3s ease;
                opacity: 0;
                border-radius: 50%;

                @include mq.screen_xs {
                    height: 100%;
                    width: 100%;
                }

                &:hover {
                    background-color: rgba(3, 82, 102, 0.5);
                    opacity: 1;
                    @include mq.screen_xs {
                        border-radius: 0;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
</style>
