<template>
    <div>
        <nav class="breadcrumb-wrapper-content" :aria-label="t('generic.breadcrumb.aria.label')" data-testid="profile-breadcrumb-wrapper">
            <ol v-if="breadcrumb" class="breadcrumb-wrapper-content-breadcrumb">
                <li class="breadcrumb-wrapper-content-breadcrumb__item">
                    <a :href="guestHost()">{{ t('navigation.breadcrumb.home') }}</a>
                </li>
                <li
                    v-if="hasFamily"
                    class="breadcrumb-wrapper-content-breadcrumb__item m-link m-link_clear"
                    :class="{'no-cursor': !hasFamilySeoUrl}"
                    data-testid="profile-breadcrumb-family"
                >
                    <a v-if="hasFamilySeoUrl" :href="currentFamilySeoUrl" class="m-link m-link_clear">
                        {{ familyLabel }}
                    </a>
                    <span v-else>{{ familyLabel }}</span>
                </li>
                <li
                    v-if="hasCategory"
                    class="breadcrumb-wrapper-content-breadcrumb__item"
                    :class="{'no-cursor': !hasCategorySeoUrl}"
                    data-testid="profile-breadcrumb-category"
                >
                    <a v-if="hasCategorySeoUrl" :href="currentCategorySeoUrl">
                        {{ categoryLabel }}
                    </a>
                    <span v-else>{{ categoryLabel }}</span>
                </li>
            </ol>

            <div v-if="isEditableBreadcrumb" class="breadcrumb-wrapper-content-edit" @click="openBreadcrumbEditDialog">
                <VJoyButton circle variant="white" icon="pen" size="small" />
            </div>
        </nav>
    </div>
</template>

<script setup lang="ts">
    import {guestHost, useTranslation} from '#imports';
    import {storeToRefs} from 'pinia';
    import {computed} from 'vue';
    import {getCategorySeoUrl, getFamilySeoUrl} from '~/types/domain/Breadcrumb';
    import {eventBus} from '~/config/events';
    import {useProfileStore} from '~/store/profileStore';
    import {VJoyButton} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {profile} = useProfileStore();
    const {breadcrumb} = storeToRefs(await useProfileStore());

    const currentFamilySeoUrl = computed<string | undefined>(() => getFamilySeoUrl(breadcrumb.value!));
    const currentCategorySeoUrl = computed<string | undefined>(() => getCategorySeoUrl(breadcrumb.value!));
    const hasFamily = computed<boolean>(() => breadcrumb.value!.levels.family !== undefined);
    const hasCategory = computed<boolean>(() => breadcrumb.value!.levels.category !== undefined);
    const hasFamilySeoUrl = computed<boolean>(() => !!currentFamilySeoUrl.value);
    const hasCategorySeoUrl = computed<boolean>(() => !!currentCategorySeoUrl.value);
    const familyLabel = computed<string | undefined>(() => breadcrumb.value!.levels.family?.label);
    const categoryLabel = computed<string | undefined>(() => breadcrumb.value!.levels.category?.label);

    const isEditableBreadcrumb = computed<boolean>(() => profile!.editMode && hasFamily.value && hasCategory.value);

    function openBreadcrumbEditDialog() {
        if (!profile!.editMode) {
            return;
        }
        eventBus.emit('breadcrumb:edit-mode');
    }
</script>

<style lang="scss" scoped>
    .breadcrumb-wrapper-content {
        display: flex;
        height: 100%;
        max-width: 100%;

        &-breadcrumb {
            display: inline-block;
            padding: 0;
            list-style-type: none;
            margin: auto 0;

            &__item {
                color: var(--joy-color-neutral-10);
                font-size: var(--joy-font-size-primary-600);
                vertical-align: middle;
                position: relative;
                display: inline-block;
                padding: 10px 20px;
                height: 100%;

                &:not(:last-child):before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-45%);
                    right: 0;
                    content: '>';
                    font-size: var(--joy-font-size-primary-200);
                }

                a {
                    color: white;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &-edit {
            z-index: 1;
            display: flex;
            align-items: center;
            margin-left: var(--joy-core-spacing-4);
            cursor: pointer;
        }
    }

    .no-cursor {
        cursor: default;
    }
</style>
