<template>
    <div class="checklist-verification-charter">
        <VJoyHighlight v-if="(isCharterValid && !profile?.editMode) || (isCharterValid && isCharterSigned)" level="success" display-icon>
            <p>{{ t('profile.section.header.signed.charter') }}</p>
            <VJoyLink :href="freelancerCharterUrl" target="_blank">{{ t('profile.section.header.charter.link') }}</VJoyLink>
        </VJoyHighlight>
        <VJoyHighlight v-else-if="(!isCharterSigned || !isCharterValid) && profile?.editMode" level="error" display-icon>
            <p>{{ t('profile.section.header.unsigned.charter') }}</p>
            <VJoyLink :href="freelancerCharterUrl" target="_blank">{{ charterCTAMessage }}</VJoyLink>
        </VJoyHighlight>
    </div>
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {useProfileStore} from '~/store/profileStore';
    import {guestHost, useTranslation} from '#imports';
    import {VJoyHighlight, VJoyLink} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {verificationChecklist, profile} = useProfileStore();
    const freelancerCharterUrl = computed(() => `${guestHost()}/profile/freelancer-charter`);
    const isCharterValid = computed(() => verificationChecklist?.charter.valid);
    const isCharterSigned = computed(() => verificationChecklist?.charter.signed);
    const charterCTAMessage = computed(() => {
        if (!isCharterSigned.value && isCharterValid.value) {
            return t('profile.section.header.unsigned.charter.link');
        }

        if (!isCharterSigned.value) {
            return t('profile.section.header.invalidated.charter.link');
        }

        return '';
    });
</script>

<style lang="scss" scoped>
    .checklist-verification-charter {
        p {
            margin-bottom: 0;
        }
    }
</style>
