<template>
    <ExternalUserActionComponent
        dialog-id="wishlist-add"
        open-dialog-event="wishlist:add-mode"
        updated-event="wishlist:added"
        :confirm-function="saveWishlist"
        size="default"
    >
        <template #edit-header>{{ t('wishlist.modal.creation.title', {0: profile!.firstname}) }}</template>
        <template #edit-body>
            <VJoyWrapper item-stretch>
                <VJoyInput v-model="newWishlistName" name="wishlist-name">{{ t('wishlist.modal.creation.list.input.name') }}</VJoyInput>
            </VJoyWrapper>
        </template>
    </ExternalUserActionComponent>
</template>

<script setup lang="ts">
    import {ref, useTranslation} from '#imports';
    import ExternalUserActionComponent from '@/components/external-user-action/ExternalUserActionComponent.vue';
    import {storeToRefs} from 'pinia';
    import {useProfileStore} from '~/store/profileStore';
    import {useWishlistStore} from '~/store/wishlist/wishlistStore';
    import {VJoyInput, VJoyWrapper} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const {profile} = storeToRefs(useProfileStore());
    const {createWishlist} = useWishlistStore();
    const newWishlistName = ref('');

    async function saveWishlist() {
        await createWishlist(newWishlistName.value, profile.value!);
    }
</script>
